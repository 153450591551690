import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  ClientListResponse,
  ClientPreview,
  GetClientsApi10OrganizationClientGetParams,
  UpdateClientBody,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { createAsyncDataUpdateSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncDataUpdateSlice";
import { getSelectedOrgId } from "../../Security/selectors";
import {
  deleteApiClientsApi,
  fetchApiClientsApi,
  postApiClientsApi,
  updateApiClientsApi,
} from "./api/api";

const apiClientsSlice = createAsyncArrayFetchSlice<ClientPreview, undefined>(
  "apiClients"
);

export const apiClientsReducer = apiClientsSlice.reducer;

export const {
  fetchData: fetchApiClients,
  setIsPending: setIsApiClientsFetchPending,
  setFetchError: setApiClientsFetchError,
  setFetchSuccess: setFetchApiClientsSuccess,
} = apiClientsSlice.actions;

export type PostPayload = {
  data: UpdateClientBody;
  action: "create";
};

export type UpdatePayload = {
  id: string;
  data: UpdateClientBody;
  action: "update";
};

export type DeletePayload = {
  id: string;
  action: "delete";
};

const apiClientsUpdateSlice = createAsyncDataUpdateSlice<
  PostPayload | UpdatePayload | DeletePayload
>("apiClientsUpdate");

export const apiClientsUpdateReducer = apiClientsUpdateSlice.reducer;

export const {
  updateData: modifyApiClients,
  setIsUpdatePending,
  setIsUpdateError,
  setIsUpdateSuccess,
  resetState,
} = apiClientsUpdateSlice.actions;

// slice just to keep the state of the client secret after a successful post

export type ClientSecretInfo = {
  clientSecret: string | null;
  clientName: string | null;
  clientId: string | null;
} | null;

const clientSecretSlice = createSlice({
  name: "clientSecret",
  initialState: {
    info: null as ClientSecretInfo,
  },
  reducers: {
    setClientSecret(state, action: PayloadAction<ClientSecretInfo>) {
      state.info = action.payload;
    },
  },
});

export const clientSecretSliceReducer = clientSecretSlice.reducer;

export const { setClientSecret } = clientSecretSlice.actions;

// Async stuff - sagas

function* fetchApiClientsSaga(_action: PayloadAction<{}>) {
  yield put(setIsApiClientsFetchPending());

  try {
    const selectedOrgId: string | undefined = yield select(getSelectedOrgId);

    const params: GetClientsApi10OrganizationClientGetParams = {
      organization_id: selectedOrgId,
    };

    const response: AxiosResponse<ClientListResponse> = yield call(
      fetchApiClientsApi,
      params
    );

    yield put(setFetchApiClientsSuccess(response.data.payload));
  } catch (e) {
    yield put(setApiClientsFetchError());
  }
}

function* modifyApiClientsSaga(
  action: PayloadAction<PostPayload | UpdatePayload | DeletePayload>
) {
  yield put(setIsUpdatePending());
  const selectedOrgId: string | undefined = yield select(getSelectedOrgId);
  if (!selectedOrgId) {
    yield put(setIsUpdateError());
    return;
  }

  const query = {
    organization_id: selectedOrgId,
  };

  try {
    if (action.payload.action === "create") {
      const response: AxiosResponse<any> = yield call(
        postApiClientsApi,
        query,
        action.payload.data
      );

      const clientSecret = response.data?.payload?.client_secret;
      if (clientSecret) {
        yield put(
          setClientSecret({
            clientSecret,
            clientName: action.payload.data.display_name,
            clientId: response.data.payload.client_id,
          })
        );
      }
    } else if (action.payload.action === "update") {
      const params = {
        externalId: action.payload.id,
        organization_id: selectedOrgId,
      };
      yield call(updateApiClientsApi, params, action.payload.data);
    } else {
      const params = {
        externalId: action.payload.id,
        organization_id: selectedOrgId,
      };
      yield call(deleteApiClientsApi, params);
    }
    yield put(setIsUpdateSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setIsUpdateError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchApiClients.toString(), fetchApiClientsSaga);
  yield takeLatest(modifyApiClients.toString(), modifyApiClientsSaga);
}

export function* apiClientsSagas() {
  yield all([actionWatcher()]);
}
