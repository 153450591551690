import classNames from "classnames";
import React, { useState } from "react";
import Stack from "../../layout/Stack/Stack";
import IconButton from "../IconButton/IconButton";

export interface HideableInterface {
  tooltipText?: string;
  showXFirstCharacters?: number;
  showXLastCharacters?: number;
}
function renderChildren(
  children: React.ReactNode | string,
  contentClasses: string,
  showXFirstCharacters?: number,
  showXLastCharacters?: number
) {
  if (typeof children !== "string") {
    return <div className={contentClasses}>{children}</div>;
  }

  let firstPart = "";
  let middlePart = children;
  let lastPart = "";

  if (showXFirstCharacters) {
    firstPart = children.slice(0, showXFirstCharacters);
    middlePart = children.slice(showXFirstCharacters);
  }

  if (showXLastCharacters) {
    lastPart = middlePart.slice(-showXLastCharacters);
    middlePart = middlePart.slice(0, -showXLastCharacters);
  }

  return (
    <Stack gutterSize="none">
      {firstPart && <div>{firstPart}</div>}
      <div className={contentClasses}>{middlePart}</div>
      {lastPart && <div>{lastPart}</div>}
    </Stack>
  );
}

const Hideable: React.FC<HideableInterface> = ({
  tooltipText,
  showXFirstCharacters,
  showXLastCharacters,
  children,
}) => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const classes = classNames("hideable", {});
  const contentClasses = classNames("hideable__content", {
    "hideable__content--hidden": !isContentVisible,
  });

  return (
    <div className={classes}>
      <Stack align="center">
        {renderChildren(
          children,
          contentClasses,
          showXFirstCharacters,
          showXLastCharacters
        )}

        <IconButton
          type="text"
          isCircle
          tooltipText={tooltipText}
          icon={isContentVisible ? "visibility_off" : "visibility"}
          onButtonClick={() => setIsContentVisible(!isContentVisible)}
        />
      </Stack>
    </div>
  );
};

export default Hideable;
