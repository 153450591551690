import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { predefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";

export const ticketsKeys = {
  TICKET_ID: "id",
  DISPLAY_ID: "display_id",
  STATUS: "status",
  SEVERITY: "severity",
  TYPE: "type",
  TITLE: "title",
  CREATED: "created",
  CLOSED: "closed",
  HAS_ALERTS: "has_alerts",
  UPDATED: "updated",
  CREATOR: "creator",
  TAGS: "tags",
  PARTICIPANTS: "participants",
  FLAGS: "flags",
  SHARE_WITH: "share_with",
  DESCRIPTION: "description",
  CUSTOMER_REF: "customer_reference",
  CREATED_AFTER: "created_after",
  CREATED_BEFORE: "created_before",
  TICKET_ATTACHMENTS: "attachments",
  ATTACHED_ALERT: "alert",
  ATTACHED_VICTIMS_REPORT: "victim_report",
  ATTACHED_PAYMENT_CARD_REPORT: "payment_card_report",
  // keys used in all search pages:
  ...searchKeys,
} as const;

export const filterKeys = [
  ticketsKeys.SEVERITY,
  ticketsKeys.STATUS,
  ticketsKeys.TYPE,
  ticketsKeys.PARTICIPANTS,
  ticketsKeys.FLAGS,
] as const;

export const DEFAULT_RESULTS_PER_PAGE = predefinedResultsPerPage.TEN;
