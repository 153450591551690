import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import createStore from "./configureStore";
import "./tip-styles/styles.scss";

Sentry.init({
  dsn:
    process.env.REACT_APP_CSIS_ENV === "development"
      ? ""
      : "https://71efb11b847b978eb560fe432cc035cf@o151870.ingest.us.sentry.io/4507134896898048",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.REACT_APP_CSIS_ENV,
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 10% of the transactions
  tracePropagationTargets: [
    /^https:\/\/.*\.csis\.com\/api\//,
    /^https:\/\/.*\.csis\.ninja\/api\//,
  ],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const store = createStore();
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("app")
);
