import React, { useState } from "react";
import Stack from "../../layout/Stack/Stack";
import { isNullOrUndefined } from "../../utils/utils";
import DropArea from "../DropArea/DropArea";
import DraggableListItem from "./DraggableListItem";
import { getGhostElement } from "./GhostElement/GhostElement";
import { getPositionOfMouseAsIndex } from "./utils";

const DraggableList: React.FC<{
  onDrop?: (originalIndex: number, newIndex: number) => void;
}> = ({ onDrop, children }) => {
  const [elementBeingDragged, setElementBeingDragged] = useState<{
    index: number;
    height: number;
  } | null>(null);

  // this holds the position of the mouse as index when the user is dragging
  const [positionOfDraggedElement, setPositionOfDraggedElement] = useState<
    number | null
  >(null);

  const handleDragStart = (value: { index: number; height: number }) => {
    setElementBeingDragged(value);
  };

  const handleDragOver = (value: { posY: number; containerHeight: number }) => {
    const posY = value.posY;
    const containerHeight = value.containerHeight;

    if (React.Children.count(children)) {
      const totalItems = React.Children.count(children);

      setPositionOfDraggedElement(
        getPositionOfMouseAsIndex(containerHeight, totalItems, posY)
      );
    }
  };

  const handleDrop = () => {
    const originalIndex = elementBeingDragged?.index;
    if (
      onDrop &&
      !isNullOrUndefined(originalIndex) &&
      !isNullOrUndefined(positionOfDraggedElement)
    ) {
      setTimeout(function () {
        // use this timeout so this function executes at the end, so
        // the component doesnt re-render before handleDragEnd runs
        onDrop(originalIndex, positionOfDraggedElement);
      }, 1);
    }
  };

  const handleDragEnd = () => {
    // this dragend is fired always after handleDrop
    setElementBeingDragged(null);
    setPositionOfDraggedElement(null);
  };

  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (child === null) {
      return null;
    }
    const showGhost = index === positionOfDraggedElement;

    return (
      <>
        {showGhost &&
          getGhostElement(
            elementBeingDragged,
            positionOfDraggedElement,
            "before"
          )}
        <DraggableListItem
          key={"draggable-list-item-" + index}
          index={index}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          {child}
        </DraggableListItem>
        {showGhost &&
          getGhostElement(
            elementBeingDragged,
            positionOfDraggedElement,
            "after"
          )}
      </>
    );
  });

  const isDragging = elementBeingDragged !== null;

  return (
    <DropArea
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      isDragging={isDragging}
    >
      <Stack isVertical align="stretch">
        {childrenWithProps}
      </Stack>
    </DropArea>
  );
};

export default DraggableList;
