import { FC } from "react";
import { Stack, Tag } from "@csis.com/components";
import { QueryParamDataType } from "./types";

export const QueryParamList: FC = ({ children }) => {
  return (
    <Stack isVertical>
      <span className="f_big">Query Params</span>
      <dl>{children}</dl>
    </Stack>
  );
};

export const QueryParamListTerm: FC<{
  term: string;
  types: QueryParamDataType[];
  isOptional?: Boolean;
}> = ({ term, types, isOptional = false }) => {
  const typesStr = types.join(" | ");

  return (
    <dt>
      <Stack>
        <span className="f_bold">{term}</span>{" "}
        <span className="f_csis">{typesStr}</span>
        {isOptional && (
          <Tag color="grey" size="small" isCircle>
            Optional
          </Tag>
        )}
      </Stack>
    </dt>
  );
};

export const QueryParamListTermDetails: FC = ({ children }) => {
  return <dd>{children}</dd>;
};
