import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";
import { profileKeys } from "./constants";

export const getProfile = (state: RootState) => state.profileReducer.profile;

export const getIsProfilePending = (state: RootState) =>
  state.profileReducer.isFetchProfilePending;

export const getProfileFetchError = (state: RootState) =>
  state.profileReducer.profileFetchError;

export const getProfileFetchErrorStatus = (state: RootState) =>
  state.profileReducer.profileFetchErrorStatus;

export const getProfileResult = createSelector(
  [
    getProfile,
    getIsProfilePending,
    getProfileFetchError,
    getProfileFetchErrorStatus,
  ],
  (
    profile,
    isFetchProfilePending,
    profileFetchError,
    getProfileFetchErrorStatus
  ) => {
    return {
      profile,
      isFetchProfilePending,
      profileFetchError,
      getProfileFetchErrorStatus,
    };
  }
);

export const getProfilePatchSuccess = (state: RootState) =>
  state.profileReducer.profilePatchSuccess;

export const getIsPatchProfilePending = (state: RootState) =>
  state.profileReducer.isPatchProfilePending;

export const getProfilePatchError = (state: RootState) =>
  state.profileReducer.profilePatchError;

export const getProfilePatchResult = createSelector(
  [getProfilePatchSuccess, getIsPatchProfilePending, getProfilePatchError],
  (profilePatchSuccess, isPatchProfilePending, profilePatchError) => {
    return { profilePatchSuccess, isPatchProfilePending, profilePatchError };
  }
);

export const getPasswordPatchSuccess = (state: RootState) =>
  state.profileReducer.passwordPatchSuccess;

export const getIsPatchPasswordPending = (state: RootState) =>
  state.profileReducer.isPatchPasswordPending;

export const getPasswordPatchError = (state: RootState) =>
  state.profileReducer.passwordPatchError;

export const getPasswordPatchResult = createSelector(
  [getPasswordPatchSuccess, getIsPatchPasswordPending, getPasswordPatchError],
  (passwordPatchSuccess, isPatchPasswordPending, passwordPatchError) => {
    return { passwordPatchSuccess, isPatchPasswordPending, passwordPatchError };
  }
);

export const getCreateTokenResult = (state: RootState) => {
  return {
    createTokenSuccess: state.profileReducer.createTokenSuccess,
    isCreateTokenPending: state.profileReducer.isCreateTokenPending,
    createTokenError: state.profileReducer.createTokenError,
  };
};

export const getDisableTokenResult = (state: RootState) => {
  return {
    disableTokenSuccess: state.profileReducer.disableTokenSuccess,
    isDisableTokenPending: state.profileReducer.isDisableTokenPending,
    disableTokenError: state.profileReducer.disableTokenError,
  };
};

export const getOtpInfoResult = (state: RootState) => {
  return {
    otpInfo: state.profileReducer.otpInfo,
    isDisableTokenPending: state.profileReducer.isFetchOtpInfoPending,
    disableTokenError: state.profileReducer.otpInfoFetchError,
  };
};

export const getGenerateOtpResult = (state: RootState) => {
  return {
    generateOtpSuccess: state.profileReducer.generateOtpSuccess,
    isGenerateOtpPending: state.profileReducer.isGenerateOtpPending,
    generateOtpError: state.profileReducer.generateOtpError,
  };
};

export const getHasUserRequestedOrgSwitch = (state: RootState) =>
  state.profileReducer.hasUserRequestedOrgSwitch;

export const getLegacyOrgs = (state: RootState) =>
  state.profileReducer.profile?.[profileKeys.LEGACY_ORGS];

export const getSelectedOrgId = (state: RootState) => {
  const defautlSelectedOrgId =
    state.profileReducer.profile?.[profileKeys.LEGACY_ORGS]?.[0]?.external_id;
  return state.profileReducer.selectedOrgId || defautlSelectedOrgId;
};

export const getUserProfileProducts = (state: RootState) =>
  state.profileReducer.profile?.[profileKeys.PRODUCTS];

export const getUserSubscribedProducts = createSelector(
  [getSelectedOrgId, getLegacyOrgs, getUserProfileProducts],
  (selectedOrgId, legacyOrgs, profileProducts) => {
    if (legacyOrgs && legacyOrgs.length > 0) {
      if (selectedOrgId) {
        return legacyOrgs?.find((org) => org.external_id === selectedOrgId)
          ?.services;
      } else {
        return legacyOrgs[0]?.services;
      }
    } else {
      // this is to be deprecated and probably never reach here since legacy orgs is always present
      return profileProducts;
    }
  }
);
