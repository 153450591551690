import { reportsKeys } from "../constants";
import { QueryParams } from "../types";

// mapper for api requests to transform start_date and end_date to appropriate query params
export function mapReportsStartAndEndDateToCorrectType(
  queryParams: Partial<QueryParams>
) {
  return Object.assign({}, queryParams, {
    [reportsKeys.CREATED_AFTER]: queryParams[reportsKeys.START_DATE],
    [reportsKeys.CREATED_BEFORE]: queryParams[reportsKeys.END_DATE],
  });
}
