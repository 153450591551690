import { FC, useEffect } from "react";
import { useHistory } from "react-router";
import {
  CollapsablePanel,
  CollapsablePanelContent,
  CollapsablePanelSidebar,
  Stack,
} from "@csis.com/components";
import Page from "@csis.com/tip/src/components/shared/Page/Page";
import { HelpSideBar } from "./Sidebar/HelpSideBar";
import { AlertsSubject } from "./Subjects/Ecrime/AlertsSubject";
import { CompDataSubject } from "./Subjects/Ecrime/CompDataSubject";
import { PhishingSubject } from "./Subjects/Ecrime/PhishingSubject";
import { TicketsSubject } from "./Subjects/Ecrime/TicketsSubject";
import { TokenAuthenticationSubject } from "./Subjects/Ecrime/TokenAuthenticationSubject";
import { ecrimeApiUrl } from "./constants";

export const ApiDocumentation: FC = () => {
  const history = useHistory();

  const hashLink = history.location.hash;
  useEffect(() => {
    if (hashLink) {
      document.getElementById(hashLink.replace("#", ""))?.scrollIntoView();
    }
  }, [hashLink]);

  return (
    <Page classes="apidocumentation" padding="none">
      <CollapsablePanel>
        <CollapsablePanelSidebar isCollapsed={false} isSticky>
          <HelpSideBar />
        </CollapsablePanelSidebar>
        <CollapsablePanelContent>
          <Stack isVertical gutterSize="gigantic">
            <h1 className="f_gigantic f_semibold f_csis">CSIS APIs</h1>
            <Stack isVertical gutterSize="big" align="stretch">
              <h2 id="ecrime-apis" className="f_huge f_semibold f_csis">
                eCrime APIs
              </h2>
              <p>
                eCrime HTTP APIs The external customer-facing portal has a
                public HTTP API for pulling ecrime data. This is useful for
                integrating with various Security Management/Ticket systems.
                Alerts and Tickets API endpoints are always available. The rest
                are subscription based. (E.g. you cannot access the Compromised
                Data API without first subscribing to our Compromised Data
                service)
              </p>
              <p>
                The API currently resides on{" "}
                <span className="f_big f_csis">{`${ecrimeApiUrl}`}</span>
              </p>
              <TokenAuthenticationSubject />
              <AlertsSubject />
              <CompDataSubject />
              <TicketsSubject />
              <PhishingSubject />
            </Stack>
          </Stack>
        </CollapsablePanelContent>
      </CollapsablePanel>
    </Page>
  );
};
