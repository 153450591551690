import { ColumnInterface } from "@csis.com/components/src/atoms/Table/types";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { casesKeys } from "../constants";
import { CasesKey } from "../types";

export type ForensicsColumns = ColumnInterface<CasesKey, TranslationKey>[];

export const columns: ForensicsColumns = [
  { key: casesKeys.DISPLAY_ID, name: "id", isSortable: true },
  {
    key: casesKeys.STATUS,
    name: "status",
    isSortable: true,
  },
  {
    key: casesKeys.SEVERITY,
    name: "severity",
    isSortable: true,
    isCenterAligned: true,
  },
  {
    key: casesKeys.UPLOADER,
    name: "uploader",
    isSortable: true,
  },
  {
    key: casesKeys.DOMAIN,
    name: "domain",
    isSortable: true,
  },
  {
    key: casesKeys.IP,
    name: "ip",
    isSortable: true,
  },
  {
    key: casesKeys.PLATFORM,
    name: "platform",
    isSortable: true,
    isCenterAligned: true,
  },
  {
    key: casesKeys.DEVICE_NAME,
    name: "device_name",
    isSortable: true,
  },
  {
    key: casesKeys.CREATED,
    name: "created",
    isSortable: true,
  },
];
