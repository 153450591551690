import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { QueryParams } from "../types";
import { mapTicketsStartAndEndDateToCorrectType } from "./utils";

export async function fetchTicketsApi(queryParams: Partial<QueryParams>) {
  const params = mapTicketsStartAndEndDateToCorrectType(queryParams);
  return axiosCsisApi.getTicketsApi10TicketGet(params, STRINGIFY_QUERY_PARAMS);
}

export async function exportTicketsCsvApi(queryParams: Partial<QueryParams>) {
  const params = mapTicketsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getExportCsvApi10TicketExportCsvGet(params, {
    format: "blob",
    ...STRINGIFY_QUERY_PARAMS,
  });
}
