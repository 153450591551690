import axios from "axios";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { ContentQueryParams } from "../types";

export async function fetchUrlApi(urlHash: string) {
  return axiosCsisApi.getUrlApi10PhishingUrlUrlHashGet({ urlHash });
}

export async function fetchContentApi(
  urlHash: string,
  queryParams: ContentQueryParams
) {
  return axiosCsisApi.getContentsApi10PhishingContentUrlHashGet({
    urlHash,
    ...queryParams,
  });
}

export async function fetchCaptureApi(captureSource: string) {
  return await axios.get<any>(captureSource, { responseType: "blob" });
}

export async function fetchHtmlApi(captureSource: string) {
  return await axios.get<any>(captureSource);
}
