import classNames from "classnames";
import React from "react";

export interface ListItemLabelInterface {}

const ListItemLabel: React.FC<ListItemLabelInterface> = ({ children }) => {
  const classes = classNames("list__item__label");

  return <div className={classes}>{children}</div>;
};

export default ListItemLabel;
