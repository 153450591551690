import axios from "axios";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { EmergencyCaseUpdateBody } from "./types";

export async function fetchEmergencyCaseByIdAPI(id: string) {
  return axiosCsisApi.getEmergencyResponseCasesByExternalIdApi10AdminEmergencyResponseCaseErCaseExternalIdGet(
    id
  );
}

export async function updateEmergencyCaseAPI(
  id: string,
  EmergencyCase: EmergencyCaseUpdateBody
) {
  return axiosCsisApi.updateExistingEmergencyResponseCaseApi10AdminEmergencyResponseCaseErCaseExternalIdPatch(
    id,
    EmergencyCase
  );
}

export async function fetchAttachmentAPI(
  emergencyCaseId: string,
  attachmentId: string
) {
  return axiosCsisApi.downloadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdGet(
    emergencyCaseId,
    attachmentId,
    {
      format: "blob",
    }
  );
}

export async function deleteEmergencyCaseAttachmentAPI(
  emergencyCaseId: string,
  attachmentId: string
) {
  return axiosCsisApi.deleteErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdDelete(
    emergencyCaseId,
    attachmentId
  );
}

export async function updateEmergencyCaseAttachmentsAPI(
  caseId: string,
  formData: FormData
) {
  return await axios({
    method: "post",
    url: `/1.0/admin/emergency-response-case/${caseId}/attachment`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
