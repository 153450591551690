import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  PlatformHistogramEntry,
  PlatformTotalEntry,
  QueryParams,
} from "../types";
import {
  fetchPlatformStatisticsApi,
  fetchPlatformStatisticsHistogramApi,
} from "./api/api";
import {
  PlatformStatisticsHistogramResponse,
  PlatformStatisticsResponse,
} from "./api/types";

interface StateSlice {
  platformDistribution: PlatformTotalEntry[];
  isPlatformDistributionPending: boolean;
  platformDistributionFetchError: string | null;

  platformDistributionOverTime: PlatformHistogramEntry[];
  isPlatformDistributionOverTimePending: boolean;
  platformDistributionOverTimeFetchError: string | null;
}
const initialState: StateSlice = {
  platformDistribution: [],
  isPlatformDistributionPending: false,
  platformDistributionFetchError: null,

  platformDistributionOverTime: [],
  isPlatformDistributionOverTimePending: false,
  platformDistributionOverTimeFetchError: null,
};

const forensicsStatisticsSlice = createSlice({
  name: "forensicsStatisticsPlatform",
  initialState: initialState,
  reducers: {
    fetchPlatformDistribution(
      _state,
      _action: PayloadAction<Partial<QueryParams>>
    ) {
      //empty handled by saga
    },
    setIsPlatformDistributionPending(state) {
      state.isPlatformDistributionPending = true;
      state.platformDistributionFetchError = null;
    },
    setPlatformDistributionFetchError(state) {
      state.isPlatformDistributionPending = false;
      state.platformDistributionFetchError = "Something went wrong";
    },
    setFetchPlatformDistributionSuccess(
      state,
      action: PayloadAction<PlatformTotalEntry[]>
    ) {
      state.isPlatformDistributionPending = false;
      state.platformDistribution = action.payload;
      state.platformDistributionFetchError = null;
    },

    fetchPlatformDistributionOverTime(
      _state,
      _action: PayloadAction<Partial<QueryParams>>
    ) {
      //empty handled by saga
    },
    setIsPlatformDistributionOverTimePending(state) {
      state.isPlatformDistributionOverTimePending = true;
      state.platformDistributionOverTimeFetchError = null;
    },
    setPlatformDistributionOverTimeFetchError(state) {
      state.isPlatformDistributionOverTimePending = false;
      state.platformDistributionOverTimeFetchError = "Something went wrong";
    },
    setFetchPlatformDistributionOverTimeSuccess(
      state,
      action: PayloadAction<PlatformHistogramEntry[]>
    ) {
      state.isPlatformDistributionOverTimePending = false;
      state.platformDistributionOverTime = action.payload;
      state.platformDistributionOverTimeFetchError = null;
    },
  },
});

export default forensicsStatisticsSlice.reducer;

export const {
  fetchPlatformDistribution,
  setIsPlatformDistributionPending,
  setPlatformDistributionFetchError,
  setFetchPlatformDistributionSuccess,

  fetchPlatformDistributionOverTime,
  setIsPlatformDistributionOverTimePending,
  setPlatformDistributionOverTimeFetchError,
  setFetchPlatformDistributionOverTimeSuccess,
} = forensicsStatisticsSlice.actions;

// Async stuff - sagas

function* fetchPlatformDistributionSaga(
  action: PayloadAction<Partial<QueryParams>>
) {
  yield put(setIsPlatformDistributionPending());

  try {
    const response: AxiosResponse<PlatformStatisticsResponse> = yield call(
      fetchPlatformStatisticsApi,
      action.payload
    );
    yield put(setFetchPlatformDistributionSuccess(response.data.payload));
  } catch (e) {
    yield put(setPlatformDistributionFetchError());
  }
}

function* fetchPlatformDistributionOverTimeSaga(
  action: PayloadAction<Partial<QueryParams>>
) {
  yield put(setIsPlatformDistributionOverTimePending());

  try {
    const response: AxiosResponse<PlatformStatisticsHistogramResponse> =
      yield call(fetchPlatformStatisticsHistogramApi, action.payload);

    yield put(
      setFetchPlatformDistributionOverTimeSuccess(response.data.payload)
    );
  } catch (e) {
    yield put(setPlatformDistributionOverTimeFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchPlatformDistribution.toString(),
    fetchPlatformDistributionSaga
  );
  yield takeLatest(
    fetchPlatformDistributionOverTime.toString(),
    fetchPlatformDistributionOverTimeSaga
  );
}

export function* forensicsStatisticsPlatformSagas() {
  yield all([actionWatcher()]);
}
