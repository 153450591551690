import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { QueryParams } from "../types";
import { mapAlertsStartAndEndDateToCorrectType } from "./utils";

export async function fetchAlertsApi(queryParams: Partial<QueryParams>) {
  const query = mapAlertsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getAlertsApi10AlertGet(query, STRINGIFY_QUERY_PARAMS);
}

export async function exportAlertsCsvApi(queryParams: Partial<QueryParams>) {
  const params = mapAlertsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getExportCsvApi10AlertExportCsvGet(params, {
    format: "blob",
    ...STRINGIFY_QUERY_PARAMS,
  });
}
