import {
  HighlightSubString,
  Icon,
  SearchableCell,
  Stack,
  Tag,
} from "@csis.com/components";
import { checkIfTextContainsSubstring } from "@csis.com/components/src/atoms/HighlightSubString/utils/utils";
import { TableCellFormatter } from "@csis.com/components/src/atoms/Table/types";
import { isArrayOfStrings, isString } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { getFlagName } from "../../Ticket/utils";
import { Flag, FlagColors, isFlagName } from "../../models/Flag/Flag";
import {
  SeverityColors,
  SeverityTranslationKeys,
  isSeverity,
} from "../../models/Severity";
import {
  StatusIcons,
  StatusTranslationKeys,
  isStatus,
} from "../../models/Status";
import {
  isTicketType,
  ticketTypeTranslationKeys,
} from "../../models/TicketType";
import { ticketsKeys } from "../constants";
import { FilterKey, TicketsKey } from "../types";

export const participantsCellWithHandler: (
  handleClick: (newValues: unknown, name: FilterKey) => void
) => TableCellFormatter<TicketsKey> = (handleClick) => {
  return (row, column) => {
    const participants = row[column];
    if (Array.isArray(participants)) {
      return (
        <Stack isVertical>
          {participants.map((participant) => {
            return (
              isString(participant) && (
                <SearchableCell
                  key={"searchable_cell-" + participant}
                  onClick={() => {
                    handleClick([participant], ticketsKeys.PARTICIPANTS);
                  }}
                >
                  {row[ticketsKeys.CREATOR] === participant ? (
                    <Stack align="center" gutterSize="small">
                      <div>{participant}</div>
                      <Icon kind="ticket_author" color="secondary" />
                    </Stack>
                  ) : (
                    participant
                  )}
                </SearchableCell>
              )
            );
          })}
        </Stack>
      );
    }
    return participants;
  };
};

export function severityCell(
  t: (key: TranslationKey, args?: string[]) => string
): TableCellFormatter<TicketsKey> {
  return (row, column) => {
    const severityValue = row[column];
    if (isSeverity(severityValue)) {
      return (
        <Tag size="small" color={SeverityColors[severityValue]} isUppercase>
          {t(SeverityTranslationKeys[severityValue])}
        </Tag>
      );
    }
    return severityValue;
  };
}

export function statusCell(
  t: (key: TranslationKey, args?: string[]) => string
): TableCellFormatter<TicketsKey> {
  return (row, column) => {
    const statusValue = row[column];
    if (isStatus(statusValue)) {
      return (
        <Icon
          kind={StatusIcons[statusValue]}
          color="secondary"
          tooltipText={t(StatusTranslationKeys[statusValue])}
          size="large"
          isInline
        />
      );
    }
    return statusValue;
  };
}

export function typeCellWithHandler(
  t: (key: TranslationKey, args?: string[]) => string,
  handleClick: (newValues: unknown, name: FilterKey) => void
): TableCellFormatter<TicketsKey> {
  return (row, column) => {
    const ticketTypeValue = row[column];
    if (isTicketType(ticketTypeValue)) {
      return (
        <SearchableCell
          onClick={() => {
            handleClick([ticketTypeValue], ticketsKeys.TYPE);
          }}
        >
          {t(ticketTypeTranslationKeys[ticketTypeValue])}
        </SearchableCell>
      );
    }
    return ticketTypeValue;
  };
}

export function flagCell(
  t: (key: TranslationKey, args?: string[]) => string,
  userSubscribedFlags: Flag[]
): TableCellFormatter<TicketsKey> {
  return (row, column) => {
    const flagArray = row[column];

    if (isArrayOfStrings(flagArray)) {
      return (
        <Stack gutterSize="normal" isVertical>
          {flagArray.map((flagValue) => {
            const isKnownFlag = isFlagName(flagValue);
            return (
              <Tag
                color={isKnownFlag ? FlagColors[flagValue] : "blue"}
                key={flagValue}
              >
                {getFlagName(flagValue, t, userSubscribedFlags)}
              </Tag>
            );
          })}
        </Stack>
      );
    } else return null;
  };
}

// if there is a search string, highlight it in the title row
export function titleCell(
  searchString?: string
): TableCellFormatter<TicketsKey> {
  return (row, column) => {
    const title = row[column];

    if (
      isString(title) &&
      searchString &&
      checkIfTextContainsSubstring(title, searchString)
    ) {
      return (
        <span>
          <HighlightSubString text={title} searchString={searchString} />
        </span>
      );
    }

    return (
      <Stack align="center">
        <div>{title}</div>
      </Stack>
    );
  };
}

// if there is a search string, highlight it in the id row
export function idCell(searchString?: string): TableCellFormatter<TicketsKey> {
  return (row, column) => {
    const id = row[column];

    if (
      isString(id) &&
      searchString &&
      checkIfTextContainsSubstring(id, searchString)
    ) {
      return <HighlightSubString text={id} searchString={searchString} />;
    }

    return id;
  };
}
