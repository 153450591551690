import React from "react";
import { Link } from "react-router-dom";
import { Icon, Skeleton } from "@csis.com/components";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { SelectedOnboardingStep } from "../../OnboardingHelp/types";
import { MenuItems, SubMenuItem } from "./types";
import { getClassesForMenu, isLevelActive } from "./utils";

const SecondLevelItems = <
  URL extends string,
  PROD extends string,
  ACC_PROD extends Record<PROD, boolean>
>({
  menuItem,
  route,
  isAdmin,
  isMenusPending,
  enabledMenus,
}: {
  menuItem: SubMenuItem<URL, PROD>;
  route: string;
  isAdmin?: boolean;
  isMenusPending?: boolean;
  enabledMenus?: ACC_PROD;
}) => {
  const { t } = useTranslations();

  if (isMenusPending) {
    return (
      <div className="second-level__loader">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }

  if (!enabledMenus) {
    return null;
  }

  const subChildren = menuItem.children;

  return (
    <div className="second-level">
      {subChildren &&
        Object.values(subChildren).map((subChild) => {
          const { tipProductKey, isAdminOnlyVisible, label, url } = subChild;
          const isMenuItemVisible = !isAdminOnlyVisible || isAdmin;

          if (!isMenuItemVisible) return null;
          if (tipProductKey && !enabledMenus[tipProductKey]) return null;

          const classes = getClassesForMenu(
            route,
            url,
            2,
            "second-level__menuitem"
          );

          return (
            <React.Fragment key={label}>
              <Link to={url}>
                <div title={t(label)} className={classes}>
                  {t(label)}
                  {isAdminOnlyVisible && (
                    <div
                      className="second-level__menuitem__beta-indicator"
                      title="Page only visible internally"
                    >
                      INTERNAL
                    </div>
                  )}
                </div>
              </Link>
              {isLevelActive(route, url, 2) && (
                <ThirdLevelItems
                  secondaryMenuItem={subChild}
                  route={route}
                  isAdmin={isAdmin}
                />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

const ThirdLevelItems = <URL extends string, PROD extends string>({
  secondaryMenuItem,
  route,
  isAdmin,
}: {
  secondaryMenuItem: SubMenuItem<URL, PROD>;
  route: string;
  isAdmin?: boolean;
}) => {
  const { t } = useTranslations();
  const subChildren = secondaryMenuItem.children;

  return (
    <div className="third-level">
      {subChildren &&
        Object.values(subChildren).map((subChild) =>
          !subChild.isAdminOnlyVisible || isAdmin ? (
            <React.Fragment key={subChild.label}>
              <Link to={subChild.url}>
                <div
                  title={t(subChild.label)}
                  className={getClassesForMenu(
                    route,
                    subChild.url,
                    3,
                    "third-level__menuitem"
                  )}
                >
                  {t(subChild.label)}
                </div>
              </Link>
              {isLevelActive(route, subChild.url, 3) && (
                <FourthLevelItems tetriaryMenuItem={subChild} route={route} />
              )}
            </React.Fragment>
          ) : null
        )}
    </div>
  );
};

const FourthLevelItems = <URL extends string, PROD extends string>({
  tetriaryMenuItem,
  route,
}: {
  tetriaryMenuItem: SubMenuItem<URL, PROD>;
  route: string;
}) => {
  const { t } = useTranslations();

  const subChildren = tetriaryMenuItem.children;

  return (
    <div className="fourth-level">
      {subChildren &&
        Object.values(subChildren).map((subChild) => {
          const { label, url } = subChild;

          return (
            <Link to={url} key={label}>
              <div
                title={t(label)}
                className={getClassesForMenu(
                  route,
                  url,
                  4,
                  "fourth-level__menuitem"
                )}
              >
                {t(label)}
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export interface SidebarMenuInterface<
  URL extends string,
  PROD extends string,
  ACC_PROD extends Record<PROD, boolean>
> {
  menuItems: MenuItems<URL, PROD>;
  route: string;
  isAdmin?: boolean;
  isMenusPending?: boolean;
  enabledMenus?: ACC_PROD;
  selectedOnboardingStep?: SelectedOnboardingStep;
}

const SidebarMenu = <
  URL extends string,
  PROD extends string,
  ACC_PROD extends Record<PROD, boolean>
>({
  menuItems,
  route,
  isAdmin,
  isMenusPending,
  enabledMenus,
  selectedOnboardingStep,
}: SidebarMenuInterface<URL, PROD, ACC_PROD>) => {
  const { t } = useTranslations();

  return (
    <nav aria-label="primary sidebar" className="sidebar__menu">
      {Object.values(menuItems).map((menuItem) => {
        const { isAdminOnlyVisible, label, url, icon, isNew } = menuItem;

        if (isAdminOnlyVisible && !isAdmin) return null;

        const isItemHighlightedForOnboarding = !!(
          menuItem.onboardingId &&
          selectedOnboardingStep === menuItem.onboardingId
        );

        const classes = getClassesForMenu(
          route,
          url,
          1,
          "menuitem",
          isItemHighlightedForOnboarding
        );

        return (
          <React.Fragment key={label}>
            <Link to={url}>
              <div className={classes}>
                <div className="menuitem__icon">
                  <Icon size="large" kind={icon} />
                </div>
                <div className="menuitem__label" title={t(label)}>
                  {t(label)}
                </div>
                {isAdminOnlyVisible && (
                  <div
                    className="menuitem__beta-indicator"
                    title="Page only visible internally"
                  >
                    INTERNAL
                  </div>
                )}
                {isNew && (
                  <div
                    className="second-level__menuitem__beta-indicator"
                    title="This page is new"
                  >
                    NEW
                  </div>
                )}
              </div>
            </Link>
            {isLevelActive(route, url, 1) && (
              <SecondLevelItems
                menuItem={menuItem}
                route={route}
                isAdmin={isAdmin}
                isMenusPending={isMenusPending}
                enabledMenus={enabledMenus}
              />
            )}
          </React.Fragment>
        );
      })}
    </nav>
  );
};

export default SidebarMenu;
