import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  PortalsFrontendApiReportingDeprecatedModelReportPreviewPageDetailResponse as ReportPreviewPageDetailResponse,
  PortalsFrontendApiReportingDeprecatedModelReportRequest as ReportRequest,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import {
  updateUserPreferences,
  fetchSuccess as userPreferencesFetchSuccess,
} from "@csis.com/tip/src/userPreferences/slice";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import { openBlobInNewTab } from "@csis.com/tip/src/utils/openBlobInNewTab";
import { LatestSearches } from "../../components/shared/SearchComboInput/AdvancedSearchCard/types";
import {
  getUserPreferences,
  getUserPreferencesForReports,
} from "../../userPreferences/selectors";
import {
  SearchPagePreferences,
  UserPreferences,
} from "../../userPreferences/types";
import {
  generateColumnsBasedOnSettings,
  generateColumnsOrderSetting,
  generateColumnsVisibilitySetting,
} from "../../userPreferences/utils";
import { ReportsColumns, columns } from "./ReportsList/columns";
import {
  downloadReportApi,
  fetchReportsApi,
  generateReportApi,
} from "./api/api";
import { reportsKeys } from "./constants";
import { getReportsColumns } from "./selectors";
import { QueryParams, Report } from "./types";

interface StateSlice {
  reports: Report[] | null;
  hasNextPage: boolean;
  isPending: boolean;
  fetchError: string | null;
  columns: ReportsColumns;

  // keeps a record of reportId and whether its download is pending (true|false)
  reportDownloadStatus: Record<string, boolean>;
  reportDownloadError: null | string;

  isGenerateReportPending: boolean;
  generateReportError: string | null;
  generateReportSuccess: boolean;
}

const initialState: StateSlice = {
  reports: null,
  hasNextPage: false,
  isPending: false,
  fetchError: null,
  columns: columns,

  reportDownloadStatus: {},
  reportDownloadError: null,

  isGenerateReportPending: false,
  generateReportError: null,
  generateReportSuccess: false,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    fetchReports(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchSuccess(state, action: PayloadAction<Report[]>) {
      state.isPending = false;
      state.fetchError = null;
      state.reports = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },
    reorderColumns(state, action: PayloadAction<ReportsColumns>) {
      state.columns = action.payload;
    },

    downloadReport(
      _state,
      _action: PayloadAction<{
        id: string;
        name: string;
        shouldOpenInNewWindow: boolean;
      }>
    ) {
      //empty handled by saga
    },
    setDownloadReportPending(state, action: PayloadAction<string>) {
      const reportId = action.payload;
      state.reportDownloadStatus[reportId] = true;
      state.reportDownloadError = null;
    },
    setDownloadReportError(
      state,
      action: PayloadAction<{ id: string; errorText: string }>
    ) {
      const reportId = action.payload.id;
      state.reportDownloadStatus[reportId] = false;
      state.reportDownloadError = action.payload.errorText;
    },
    setDownloadReportSuccess(state, action: PayloadAction<string>) {
      const reportId = action.payload;
      state.reportDownloadStatus[reportId] = false;
      state.reportDownloadError = null;
    },
    resetDownloadReportState(state) {
      state.reportDownloadError = null;
    },

    generateReport(_state, _action: PayloadAction<ReportRequest>) {
      //empty handled by saga
    },
    setGenerateReportPending(state) {
      state.isGenerateReportPending = true;
      state.generateReportError = null;
    },
    setGenerateReportError(state, action: PayloadAction<string>) {
      state.isGenerateReportPending = false;
      state.generateReportError = action.payload;
    },
    setGenerateReportSuccess(state) {
      state.isGenerateReportPending = false;
      state.generateReportError = null;
      state.generateReportSuccess = true;
    },
    resetGenerateReportState(state) {
      state.isGenerateReportPending = false;
      state.generateReportError = null;
      state.generateReportSuccess = false;
    },

    setLatestSearchesUserPreference(
      _state,
      _action: PayloadAction<LatestSearches>
    ) {
      //empty handled by saga
    },
  },
});

export default reportsSlice.reducer;

export const {
  fetchReports,
  setPending,
  setFetchError,
  fetchSuccess,
  setHasNextPage,
  reorderColumns,

  downloadReport,
  setDownloadReportPending,
  setDownloadReportError,
  setDownloadReportSuccess,
  resetDownloadReportState,

  generateReport,
  setGenerateReportPending,
  setGenerateReportError,
  setGenerateReportSuccess,
  resetGenerateReportState,

  setLatestSearchesUserPreference,
} = reportsSlice.actions;

// Async stuff - sagas

function* fetchReportsSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setPending());
  try {
    const response: AxiosResponse<ReportPreviewPageDetailResponse> = yield call(
      fetchReportsApi,
      action.payload
    );

    yield put(fetchSuccess(response.data.payload.page as Report[]));
    yield put(setHasNextPage(response.data.payload.has_next));

    // here we do this "special" case for when a response has a "working" report status
    // this is because when a user generates a report, the report status is set to "working"
    // so we need to wait a bit and then try and refetch the reports for better UX
    // and hopefully the report status will be "completed" by then

    if (
      response.data.payload.page.some(
        (res) => res[reportsKeys.REPORT_STATUS] === "working"
      )
    ) {
      yield delay(10000);
      const response2: AxiosResponse<ReportPreviewPageDetailResponse> =
        yield call(fetchReportsApi, action.payload);

      yield put(fetchSuccess(response2.data.payload.page as Report[]));
      yield put(setHasNextPage(response2.data.payload.has_next));
    }
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* downloadReportSaga(
  action: PayloadAction<{
    id: string;
    name: string;
    shouldOpenInNewWindow: boolean;
  }>
) {
  yield put(setDownloadReportPending(action.payload.id));
  try {
    const response: AxiosResponse<Blob> = yield call(
      downloadReportApi,
      action.payload.id
    );

    const blob = response.data;
    const reportName = action.payload.name || "report";
    if (action.payload.shouldOpenInNewWindow) {
      openBlobInNewTab(blob, "pdf");
    } else {
      downloadBlobForUser(blob, reportName);
    }
    yield put(setDownloadReportSuccess(action.payload.id));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(
      setDownloadReportError({ id: action.payload.id, errorText: errorMessage })
    );
  }
}

function* generateReportSaga(action: PayloadAction<ReportRequest>) {
  yield put(setGenerateReportPending());
  try {
    yield call(generateReportApi, action.payload);
    yield put(setGenerateReportSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setGenerateReportError(errorMessage));
  }
}

function* reactToColumnsUpdateAndUpdateUserPreferencesSaga(
  action: PayloadAction<ReportsColumns>
) {
  // the user updated the columns so, we "update"
  // the prefs server side on the background
  const newColumns = action.payload;
  const newColumnsOrderSettings = generateColumnsOrderSetting(newColumns);
  const newColumnsVisibilitySettings =
    generateColumnsVisibilitySetting(newColumns);
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = {
      ...userPrefs,
      reports: {
        ...userPrefs?.reports,
        columnsVisibility: newColumnsVisibilitySettings,
        columnsOrder: newColumnsOrderSettings,
      },
    };

    yield put(updateUserPreferences(newPrefs));
  }
}
function* reactToUserPreferencesResponse() {
  const userPrefs: SearchPagePreferences | undefined = yield select(
    getUserPreferencesForReports
  );
  const columns: ReportsColumns = yield select(getReportsColumns);

  if (userPrefs && userPrefs.columnsOrder && userPrefs.columnsVisibility) {
    // generate columns based on the saved user preferences
    const columnsFromSettings = generateColumnsBasedOnSettings(
      userPrefs.columnsOrder,
      userPrefs.columnsVisibility,
      columns
    );

    yield put(reorderColumns(columnsFromSettings));
  }
}

function* setLatestSearchesUserPreferenceSaga(
  action: PayloadAction<LatestSearches>
) {
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };

    newPrefs.reports = {
      ...newPrefs.reports,
      latestSearches: action.payload,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchReports.toString(), fetchReportsSaga);
  yield takeLatest(downloadReport.toString(), downloadReportSaga);
  yield takeLatest(generateReport.toString(), generateReportSaga);
  yield takeLatest(
    reorderColumns.toString(),
    reactToColumnsUpdateAndUpdateUserPreferencesSaga
  );
  yield takeLatest(
    userPreferencesFetchSuccess.toString(),
    reactToUserPreferencesResponse
  );
  yield takeLatest(
    setLatestSearchesUserPreference.toString(),
    setLatestSearchesUserPreferenceSaga
  );
}

export function* reportsSagas() {
  yield all([actionWatcher()]);
}
