import { ColumnInterface } from "@csis.com/components/src/atoms/Table/types";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { ccKeys } from "../constants";
import { CompDataKey } from "../types";

export type CardsColumns = ColumnInterface<CompDataKey, TranslationKey>[];

export const columns: CardsColumns = [
  { key: ccKeys.DISPLAY_ID, name: "id", isSortable: true },
  {
    key: ccKeys.STATUS,
    name: "status",
    isSortable: true,
    isCenterAligned: true,
  },
  { key: ccKeys.CARD_NUMBER, name: "card_number", isSortable: false },
  { key: ccKeys.SOURCE, name: "source", isSortable: true },
  { key: ccKeys.VARIANT, name: "variant", isSortable: true },
  { key: ccKeys.NAME, name: "name", isSortable: true },
  { key: ccKeys.RELEASED, name: "release_date", isSortable: true },
];
