import { createSelector } from "@reduxjs/toolkit";
import { getNewColumnsBasedOnSort } from "@csis.com/tip/src/components/shared/DataTableContainer/utils";
import { RootState } from "@csis.com/tip/src/configureStore";
import { UrlsColumns } from "./Table/columns";

export const getUrls = (state: RootState) => state.urlsReducer.urls;

export const getIsUrlsPending = (state: RootState) =>
  state.urlsReducer.isPending;

export const getUrlsFetchError = (state: RootState) =>
  state.urlsReducer.fetchError;

export const getUrlsHasNextPage = (state: RootState) =>
  state.urlsReducer.hasNextPage;

export const getUrlsColumns = (state: RootState) => state.urlsReducer.columns;

export const getCalculatedColumns = createSelector(
  [getUrlsColumns, (_: RootState, sort?: string) => sort],
  (columns, sort) => {
    return getNewColumnsBasedOnSort(columns, sort);
  }
  // we need to explicitly type it here so typescript doesnt show false warning
) as (state: RootState, sort?: string) => UrlsColumns;

export const getUrlsResult = createSelector(
  [getUrls, getIsUrlsPending, getUrlsFetchError],
  (urls, isUrlsPending, urlsError) => {
    return { urls, isUrlsPending, urlsError };
  }
);

export const getIsCsvExportPending = (state: RootState) =>
  state.urlsReducer.isCsvExportPending;

export const getBulkPostUrlsSuccess = (state: RootState) =>
  state.urlsReducer.bulkPostUrlsSuccess;

export const getIsBulkPostUrlsPending = (state: RootState) =>
  state.urlsReducer.isBulkPostUrlsPending;

export const getBulkPostUrlsError = (state: RootState) =>
  state.urlsReducer.bulkPostUrlsError;

export const getBulkPostUrlsResponse = (state: RootState) =>
  state.urlsReducer.bulkPostUrlsResponse;

export const getBulkPostUrlsResult = createSelector(
  [
    getBulkPostUrlsSuccess,
    getIsBulkPostUrlsPending,
    getBulkPostUrlsError,
    getBulkPostUrlsResponse,
  ],
  (
    bulkPostUrlsSuccess,
    isBulkPostUrlsPending,
    bulkPostUrlsError,
    bulkPostUrlsResponse
  ) => {
    return {
      bulkPostUrlsSuccess,
      isBulkPostUrlsPending,
      bulkPostUrlsError,
      bulkPostUrlsResponse,
    };
  }
);
