import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import {
  RemoteForensicsCaseSummary,
  RemoteForensicsSubPageKey,
} from "./SubPageContainer/types";
import { fetchCaseApi, fetchCaseSummaryApi } from "./api/api";
import { CaseResponse, CaseSummaryResponse } from "./api/types";
import { remoteForensicsSubPageKeys } from "./constants";

interface SubPageState {
  data: any;
  isPending: boolean;
  fetchError: string | null;
}

interface StateSlice {
  case: Partial<Record<RemoteForensicsSubPageKey, SubPageState>>;
  summary: RemoteForensicsCaseSummary;
}
const initialState: StateSlice = {
  case: {
    [remoteForensicsSubPageKeys.ANALYSIS_OVERVIEW]: {
      data: {},
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.DETECTION_TOOLS]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.PROCESSES]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.MEMORY_ANALYSIS]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.SCHEDULED_TASKS]: {
      data: [],
      isPending: false,
      fetchError: null,
    },

    [remoteForensicsSubPageKeys.SUSPICIOUS_EXTENSIONS]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.KNOWN_BAD_HASHES]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.MALWARE_DROPPERS]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.SUSPICIOUS_FILES]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.RAT]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.AMCACHE]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.SHIM]: {
      data: [],
      isPending: false,
      fetchError: null,
    },

    [remoteForensicsSubPageKeys.AUTORUN_KEYS]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.RAT_REGISTRY]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.SUSPICIOUS_REGISTRY_KEYS]: {
      data: [],
      isPending: false,
      fetchError: null,
    },

    [remoteForensicsSubPageKeys.DNS_QUERIES]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.CONNECTIONS]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.OPEN_PORTS]: {
      data: [],
      isPending: false,
      fetchError: null,
    },

    [remoteForensicsSubPageKeys.BROWSER_VISITS]: {
      data: [],
      isPending: false,
      fetchError: null,
    },

    [remoteForensicsSubPageKeys.REGISTRY]: {
      data: {},
      isPending: false,
      fetchError: null,
    },

    [remoteForensicsSubPageKeys.ANDROID_APPLICATIONS]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
    [remoteForensicsSubPageKeys.ANDROID_STORAGE]: {
      data: [],
      isPending: false,
      fetchError: null,
    },
  },
  summary: {},
};

const caseSlice = createSlice({
  name: "case",
  initialState: initialState,
  reducers: {
    fetchCaseSubPage(
      _state,
      _action: PayloadAction<{
        id: string;
        subpage?: RemoteForensicsSubPageKey;
      }>
    ) {
      //empty handled by saga
    },
    setPending(state, action: PayloadAction<RemoteForensicsSubPageKey>) {
      const subpage = action.payload;
      state.case[subpage]!.isPending = true;
      state.case[subpage]!.fetchError = null;
    },
    setFetchError(
      state,
      action: PayloadAction<{
        error: string;
        subpage: RemoteForensicsSubPageKey;
      }>
    ) {
      const subpage = action.payload.subpage;
      state.case[subpage]!.isPending = false;
      state.case[subpage]!.fetchError = action.payload.error;
    },
    fetchSuccess(
      state,
      action: PayloadAction<{
        data: {};
        subpage: RemoteForensicsSubPageKey;
      }>
    ) {
      const subpage = action.payload.subpage;
      state.case[subpage]!.isPending = false;
      state.case[subpage]!.fetchError = null;
      state.case[subpage]!.data = action.payload.data;
    },

    fetchCaseSummary(
      _state,
      _action: PayloadAction<{
        id: string;
      }>
    ) {
      //empty handled by saga
    },
    fetchCaseSummarySuccess(
      state,
      action: PayloadAction<RemoteForensicsCaseSummary>
    ) {
      state.summary = action.payload;
    },
  },
});

export default caseSlice.reducer;

export const {
  fetchCaseSubPage,
  setPending,
  setFetchError,
  fetchSuccess,
  fetchCaseSummary,
  fetchCaseSummarySuccess,
} = caseSlice.actions;

// Async stuff - sagas

function* fetchCaseSubPageSaga(
  action: PayloadAction<{ id: string; subpage: RemoteForensicsSubPageKey }>
) {
  yield put(setPending(action.payload.subpage));

  try {
    const response: AxiosResponse<CaseResponse> = yield call(
      fetchCaseApi,
      action.payload.id,
      action.payload.subpage
    );

    yield put(
      fetchSuccess({
        data: response.data.payload,
        subpage: action.payload.subpage,
      })
    );
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(
      setFetchError({ error: errorMessage, subpage: action.payload.subpage })
    );
  }
}

function* fetchCaseSummarySaga(action: PayloadAction<{ id: string }>) {
  try {
    const response: AxiosResponse<CaseSummaryResponse> = yield call(
      fetchCaseSummaryApi,
      action.payload.id
    );
    yield put(fetchCaseSummarySuccess(response.data.payload));
  } catch (e) {}
}

function* actionWatcher() {
  // important to use takeEvery here instead of takeLatest as we dont want to "cancel"
  // any other requests that have been fired from some other subpage
  yield takeEvery(fetchCaseSubPage.toString(), fetchCaseSubPageSaga);
  yield takeLatest(fetchCaseSummary.toString(), fetchCaseSummarySaga);
}

export function* caseSagas() {
  yield all([actionWatcher()]);
}
