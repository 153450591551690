import axios from "axios";
import * as qs from "query-string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Api } from "@csis.com/tip/src/api/openapi/Api";
import { isString } from "@csis.com/tip/src/models/helpers";
import EmergencyCaseConfirmation from "@csis.com/tip/src/pages/EmergencyCaseConfirmation/EmergencyCaseConfirmation";
import Loading from "@csis.com/tip/src/pages/Loading/Loading";
import * as loginSelectors from "@csis.com/tip/src/pages/Login/selectors";
import {
  checkIsLoggedIn,
  requestBearerToken,
} from "@csis.com/tip/src/pages/Login/slice";
import PasswordReset from "@csis.com/tip/src/pages/PasswordReset/PasswordReset";
import PasswordResetEmail from "@csis.com/tip/src/pages/PasswordReset/PasswordResetEmail/PasswordResetEmail";
import urls from "@csis.com/tip/src/routes/urls";
import MainApp from "./MainApp";

axios.defaults.baseURL = process.env.REACT_APP_CSIS_API_ENDPOINT;

const emergencyCaseConfirmationPathRegex = new RegExp(
  /\/emergency-response\/case\/\w+/
);

export const axiosCsisApi = new Api({
  baseURL: process.env.REACT_APP_CSIS_REDIRECT_URI,
  securityWorker: (accessToken) =>
    accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : {},
});

function App() {
  const dispatch = useDispatch();

  const queryParams = qs.parse(window.location.search);

  // this runs once, on the first visit
  useEffect(() => {
    if (
      queryParams.code &&
      queryParams.state &&
      isString(queryParams.code) &&
      isString(queryParams.state)
    ) {
      // if here, it means its been redirected from a successful login
      dispatch(
        requestBearerToken({ code: queryParams.code, state: queryParams.state })
      );
    } else {
      // if here it means the user visits the page the first time
      // we store the route that the user visited the page so we can redirect
      // after a successful login
      const originalRoute = window.location.pathname + window.location.search;
      dispatch(checkIsLoggedIn({ originalRoute: originalRoute }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoggedIn = useSelector(loginSelectors.getIsLoggedIn);
  const redirectAuthUrl = useSelector(loginSelectors.getRedirectAuthUrl);

  if (window.location.pathname === urls.resetpassword) {
    return <PasswordReset />;
  }

  if (window.location.pathname === urls.resetpassword_email) {
    return <PasswordResetEmail />;
  }

  if (emergencyCaseConfirmationPathRegex.test(window.location.pathname)) {
    return (
      <Router>
        <Switch>
          <Route
            path={urls.emergency_case_confirmation_id}
            component={EmergencyCaseConfirmation}
          />
        </Switch>
      </Router>
    );
  }

  if (redirectAuthUrl) {
    window.location.replace(redirectAuthUrl);
  }

  if (isLoggedIn) {
    return (
      <Router>
        <Switch>
          <Route exact path="*" component={MainApp} />
        </Switch>
      </Router>
    );
  }

  return <Loading />;
}

export default App;
