import { FC } from "react";
import { Stack } from "@csis.com/components";

interface SubjectInterface {
  title: string;
  id: string;
}
export const Subject: FC<SubjectInterface> = ({ title, id, children }) => {
  return (
    <Stack isVertical align="stretch">
      <h3 id={id} className="f_semibold">
        {title}
      </h3>
      <div className="apidocumentation__topic">
        <Stack isVertical gutterSize="large">
          {children}
        </Stack>
      </div>
    </Stack>
  );
};
