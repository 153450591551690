export type Guard<T> = (x: unknown) => x is T;

export function isNumber(x: any): x is number {
  return typeof x === "number";
}

export function isString(x: any): x is string {
  return typeof x === "string";
}

export function isNumberOrString(x: any): x is number | string {
  return typeof x === "number" || typeof x === "string";
}

export function isDate(x: any): x is Date {
  return x instanceof Date;
}

export const isArrayOf =
  <A>(itemGuard: Guard<A>): Guard<Array<A>> =>
  (x: any): x is A[] =>
    Array.isArray(x) && x.every(itemGuard);

export const isArrayOfNumbers = isArrayOf(isNumber);

export const isArrayOfStrings = isArrayOf(isString);

export function genericParse<T>(
  arg: any,
  typecheck?: (x: any) => x is T
): T | undefined {
  if (typecheck && typecheck(arg)) {
    return arg;
  }
  return undefined;
}

export function parseBoolean(arg: any) {
  return arg === "true" || arg === true;
}

// a helper type to use when we want to "overwrite" a property of a type
export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;
