import axios from "axios";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { NewEmergancyCase } from "../types";

export const postEmergencyCaseApi = async (emergencyCase: NewEmergancyCase) => {
  return axiosCsisApi.createNewEmergencyResponseCaseApi10AdminEmergencyResponseCasePost(
    emergencyCase
  );
};

export const postEmergencyCaseAttachmentApi = async (formData: FormData) => {
  return await axios({
    method: "post",
    url: "/1.0/emergency-response-case/attachment",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};
