import axios from "axios";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { RetainerInfo } from "./types";

export async function fetchRetainerInfoAPI() {
  return axiosCsisApi.getRetainerApi10RetainerGet();
}

export async function updateRetainerInfoAPI(retainerInfo: RetainerInfo) {
  return axiosCsisApi.createOrUpdateRetainerForUserApi10RetainerPost(
    retainerInfo
  );
}

export async function updateRetainerAttachmentsAPI(formData: FormData) {
  return await axios({
    method: "post",
    url: "/1.0/retainer/attachment",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export async function deleteRetainerAttachmentAPI(attachmentId: string) {
  return axiosCsisApi.deleteRetainerAttachmentApi10RetainerAttachmentExternalIdDelete(
    attachmentId
  );
}

export async function fetchAttachmentApi(attachmentId: string) {
  return axiosCsisApi.downloadRetainerAttachmentApi10RetainerAttachmentExternalIdGet(
    attachmentId,
    {
      format: "blob",
    }
  );
}
