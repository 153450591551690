import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  addGroupByParamBasedOnRange,
  mapTicketsStartAndEndDateToCorrectType,
} from "@csis.com/tip/src/pages/Tickets/TicketsSearch/api/utils";
import { QueryParams } from "../../types";

export async function fetchSeveritiesStatisticsApi(
  queryParams: Partial<QueryParams>
) {
  const params = mapTicketsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getStatisticsSeverityApi10TicketStatisticsSeverityGet(
    params
  );
}

export async function fetchSeveritiesStatisticsHistogramApi(
  queryParams: Partial<QueryParams>
) {
  const params = addGroupByParamBasedOnRange(
    mapTicketsStartAndEndDateToCorrectType(queryParams)
  );

  return axiosCsisApi.getStatisticsSeverityHistogramApi10TicketStatisticsSeverityHistogramGet(
    params
  );
}
