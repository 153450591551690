import classNames from "classnames";
import React from "react";

export interface CollapsablePanelContentInterface {
  isCollapsed?: boolean;
}

const CollapsablePanelContent: React.FC<CollapsablePanelContentInterface> = ({
  children,
}) => {
  const classes = classNames("collapsable-panel__content ", {});

  return <div className={classes}>{children}</div>;
};

export default CollapsablePanelContent;
