import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import * as qs from "query-string";
import { all, call, put, select, take, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { AUTH_API_ENDPOINTS } from "@csis.com/tip/src/auth/apiEndpoints";
import {
  generateAuthLogoutParams,
  generateAuthRedirectParams,
  generateRandomString,
  getChallengeFromVerifier,
  getEndpointWithParams,
} from "@csis.com/tip/src/auth/utils";
import { getProfileResult } from "../Profile/Security/selectors";
import { fetchProfile } from "../Profile/Security/slice";
import { requestBearerTokenApi } from "./api";
import { deleteCookie, getCookieValue, setCookie } from "./utils";

interface StateSlice {
  isLoggedIn: boolean;
  redirectAuthUrl: string | null;
  redirectUrl: string | null;
}
const initialState: StateSlice = {
  isLoggedIn: false,
  redirectAuthUrl: null, // used to redirect to the authserver if needed (user is not logged-in)
  redirectUrl: null, // used to redirect to the originalRoute the user visited originally, after a successful login
};

const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    checkIsLoggedIn(_state, _action: PayloadAction<{ originalRoute: string }>) {
      // handled by saga
    },
    requestBearerToken(
      _state,
      _action: PayloadAction<{ code: string; state: string }>
    ) {
      // handled by saga
    },
    postLogout(_state) {
      // handled by saga
    },
    setIsLoggedIn(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
    setRedirectAuthUrl(state, action: PayloadAction<string>) {
      state.redirectAuthUrl = action.payload;
    },
    setRedirectUrl(state, action: PayloadAction<string | null>) {
      state.redirectUrl = action.payload;
    },
  },
});

export default loginSlice.reducer;

export const {
  postLogout,
  requestBearerToken,
  setIsLoggedIn,
  checkIsLoggedIn,
  setRedirectAuthUrl,
  setRedirectUrl,
} = loginSlice.actions;

function* triggerAuthFlowSaga(originalRoute?: string) {
  // B1. Generate state, code_verifier and code challenge
  // and store the first 2 in cookies
  const state = generateRandomString();
  setCookie("state", state);

  const codeVerifier = generateRandomString();
  setCookie("codeVerifier", codeVerifier);

  const challenge: string = yield call(getChallengeFromVerifier, codeVerifier);

  // Store the originalRoute in a cookie if exists
  if (originalRoute) {
    setCookie("originalRoute", originalRoute);
  }

  // B2. Generate the redirect url
  const authRedirectParams = generateAuthRedirectParams(challenge, state);
  const paramsAsString = qs.stringify(authRedirectParams);

  const redirectAuthUrl = getEndpointWithParams(
    AUTH_API_ENDPOINTS.authorize,
    paramsAsString
  );

  yield put(setRedirectAuthUrl(redirectAuthUrl));
}

function getLogoutUrl(idToken: string) {
  const paramsAsString = qs.stringify(generateAuthLogoutParams(idToken));
  return getEndpointWithParams(AUTH_API_ENDPOINTS.logout, paramsAsString);
}

function* validateStoredToken(
  bearerTokenFromCookie: string,
  idTokenFromCookie: string
) {
  // check that this stored token is still valid
  axios.defaults.headers.common = {
    Authorization: `Bearer ${bearerTokenFromCookie}`,
  };
  axiosCsisApi.setSecurityData(bearerTokenFromCookie);
  try {
    // we try to fetch the profile with the token
    yield put(fetchProfile());

    // and we wait until its either a success or a fail
    yield take(["profile/fetchProfileSuccess", "profile/setFetchProfileError"]);

    const { profile, profileFetchError } = yield select(getProfileResult);

    if (profile && idTokenFromCookie && !profileFetchError) {
      yield put(setIsLoggedIn(true));
    } else {
      yield call(triggerAuthFlowSaga);
    }
  } catch (e: unknown) {
    yield call(triggerAuthFlowSaga);
  }
}

function* checkIsLoggedInSaga(
  action: PayloadAction<{ originalRoute: string }>
) {
  // do the magic here,
  // either A. check there is a token and verify it
  // or     B. do the whole auth flow
  const bearerTokenFromCookie = getCookieValue("bearerToken");
  const idTokenFromCookie = getCookieValue("idToken");

  if (bearerTokenFromCookie && idTokenFromCookie) {
    // A.
    yield call(validateStoredToken, bearerTokenFromCookie, idTokenFromCookie);
  } else {
    // B.
    yield call(triggerAuthFlowSaga, action.payload.originalRoute);
  }
}

function* requestBearerTokenSaga(
  action: PayloadAction<{ code: string; state: string }>
) {
  // Read the state and code verifier and originalRoute(if exists) from the cookies
  const stateFromCookie = getCookieValue("state");
  const codeVerifierFromCookie = getCookieValue("codeVerifier");
  const originalRoute = getCookieValue("originalRoute");

  // 1. Check if the server state === our stored state +  we have a stored code_verifier
  if (stateFromCookie === action.payload.state && codeVerifierFromCookie) {
    // delete the cookies since they are "used" now
    deleteCookie("state");
    deleteCookie("codeVerifier");
    deleteCookie("originalRoute");

    try {
      //2. make the post request for the token
      const response: AxiosResponse<{
        opaque_access_token: string;
        id_token: string;
      }> = yield call(requestBearerTokenApi, {
        code: action.payload.code,
        codeVerifier: codeVerifierFromCookie,
      });

      const bearerToken = response.data.opaque_access_token;
      const idToken = response.data.id_token;

      // by not setting expiration date, it expires when the browser closes
      setCookie("bearerToken", bearerToken);
      setCookie("idToken", idToken);

      axios.defaults.headers.common = {
        Authorization: `Bearer ${bearerToken}`,
      };
      axiosCsisApi.setSecurityData(bearerToken);

      // we try to fetch the profile with the token
      yield put(fetchProfile());

      // and we wait until its either a success or a fail
      yield take([
        "profile/fetchProfileSuccess",
        "profile/setFetchProfileError",
      ]);

      const { profile, profileFetchError, profileFetchErrorStatus } =
        yield select(getProfileResult);

      if (profile && bearerToken && !profileFetchError) {
        yield put(setIsLoggedIn(true));
        yield put(setRedirectUrl(originalRoute));
      } else {
        if (
          profileFetchErrorStatus &&
          (profileFetchErrorStatus === 401 || profileFetchErrorStatus === 403)
        ) {
          // redirect to login only if the request is unauthorized
          yield call(triggerAuthFlowSaga);
        } else {
          // in any other case 400/500 etc
          // send the user to the homepage, so we avoid the infinite loop - and relevant errors will show
          // since in theory, the token is "legit" but the profile is not available / crashing
          // so we avoid the infinite loop situation
          yield put(setIsLoggedIn(true));
        }
      }
    } catch (e: unknown) {
      // send him back ->
      yield call(triggerAuthFlowSaga);
    }
  } else {
    // means its shouldnt be here so send to login
    yield call(triggerAuthFlowSaga);
  }
}

function* postLogoutSaga() {
  // get logout redirect url
  const logoutUrl = getLogoutUrl(getCookieValue("idToken"));

  // remove cookies
  deleteCookie("bearerToken");
  deleteCookie("idToken");
  deleteCookie("codeVerifier");
  deleteCookie("state");

  // set loggedin to false
  yield put(setIsLoggedIn(false));

  // redirect to logout endpoint
  window.location.replace(logoutUrl);
}

function* actionWatcher() {
  yield takeLatest(checkIsLoggedIn.toString(), checkIsLoggedInSaga);
  yield takeLatest(requestBearerToken.toString(), requestBearerTokenSaga);
  yield takeLatest(postLogout.toString(), postLogoutSaga);
}

export function* loginSagas() {
  yield all([actionWatcher()]);
}
