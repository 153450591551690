import { FC } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeader,
  Stack,
} from "@csis.com/components";

export const DocumentationCodeExample: FC = ({ children }) => {
  return (
    <Accordion headerGutterSize="space-between" headerColor="grey">
      <AccordionItem>
        <AccordionItemHeader>
          <span className="f_normal">Examples</span>
        </AccordionItemHeader>
        <AccordionItemBody>
          <Stack isVertical align="stretch">
            {children}
          </Stack>
        </AccordionItemBody>
      </AccordionItem>
    </Accordion>
  );
};
