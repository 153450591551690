import { axiosCsisApi } from "@csis.com/tip/src/App";
import { Status } from "../../models/Status";

export async function fetchAlertApi(id: string) {
  return axiosCsisApi.getAlertApi10AlertExternalIdGet(id);
}

export async function patchAlertStatusApi({
  alertId,
  status,
}: {
  alertId: string;
  status: Status;
}) {
  return axiosCsisApi.editStatusApi10AlertExternalIdPatch(alertId, { status });
}

export async function fetchAlertAttachmentApi(
  alertId: string,
  attachmentId: string
) {
  return axiosCsisApi.getAttachmentApi10AlertExternalIdAttachmentAttachmentExternalIdGet(
    attachmentId,
    alertId,
    {
      format: "blob",
    }
  );
}
