/* eslint-disable sonarjs/cognitive-complexity */
import classNames from "classnames";
import React from "react";
import Icon from "../../icons/Icon";
import { LARGE, NORMAL, SMALL } from "../../sizes";
import CheckBox from "../CheckBox/CheckBox";
import IconButton from "../IconButton/IconButton";
import * as types from "./types";
import * as utils from "./utils";

function getSortIcon(sort?: types.SortDirection) {
  if (sort === "asc") {
    return (
      <span className="table__sort-icon">
        <Icon kind="up-arrow-drop" />
      </span>
    );
  } else if (sort === "desc") {
    return (
      <span className="table__sort-icon">
        <Icon kind="down-arrow-drop" />
      </span>
    );
  }
  return (
    <span className="table__sort-icon">
      <Icon kind="up-and-down-arrow-drop" />
    </span>
  );
}

export interface TableColumnsInterface<
  ROW extends types.RowInterface,
  COLUMN_KEY extends string,
  COLUMN_NAME extends string
> {
  columns?: types.ColumnInterface<COLUMN_KEY, COLUMN_NAME>[];
  isSelectable?: boolean;
  tableName: string;
  size?: SMALL | NORMAL | LARGE;
  rows?: ROW[];
  subElementsConfig?: types.SubElementsConfig<COLUMN_KEY>;
  onSelectAllRows?: types.OnSelectAllRows;
  selectedRows?: types.SelectedRows;
  onSortByColumn?: (
    columnKey: COLUMN_KEY,
    direction: types.SortDirection | null
  ) => void;
  onHeaderSettingsClick?: () => void;
  columnNameTransform?: (columnName: COLUMN_NAME) => string;
  dataTestId?: string;
}

const TableHeader = <
  ROW extends types.RowInterface,
  COLUMN_KEY extends string,
  COLUMN_NAME extends string
>({
  columns,
  rows,
  tableName,
  selectedRows,
  subElementsConfig,
  size,
  isSelectable,
  onSelectAllRows,
  onSortByColumn,
  columnNameTransform,
  onHeaderSettingsClick,
  dataTestId,
}: TableColumnsInterface<ROW, COLUMN_KEY, COLUMN_NAME>) => {
  const handleColumnClick = React.useCallback(
    (column: types.ColumnInterface<COLUMN_KEY, string>) => {
      if (!onSortByColumn) {
        return;
      }
      if (column.isSortable) {
        // the order is no sort -> desc -> asc -> no sort ...
        if (column.sort) {
          if (column.sort === "desc") {
            onSortByColumn(column.key, "asc");
          } else {
            onSortByColumn(column.key, null);
          }
        } else {
          onSortByColumn(column.key, "desc");
        }
      }
    },
    [onSortByColumn]
  );

  // when the column cell is focused we can sort it with enter
  const handleKeyPress = React.useCallback(
    (
      event: React.KeyboardEvent,
      column: types.ColumnInterface<COLUMN_KEY, string>
    ) => {
      if (event.key === "Enter") {
        event.stopPropagation();
        event.preventDefault();
        handleColumnClick(column);
      }
    },
    [handleColumnClick]
  );

  return (
    <tr className="table__header">
      {isSelectable && (
        <th className="checkbox-cell">
          <CheckBox
            onClick={onSelectAllRows}
            isChecked={utils.isAllRowsSelected(rows, selectedRows)}
            isMiddleState={selectedRows?.rows && selectedRows?.rows.length > 0}
            name={`${tableName}-select-all`}
            clickableArea={size === "large" ? "big" : size}
            dataTestId={dataTestId + "-select-all"}
          />
        </th>
      )}
      {
        // empty cell for the sub row icon
        subElementsConfig && <td />
      }
      {columns
        ?.filter((c) => !c.isHidden)
        .map((column, i) => (
          <th
            key={column.key}
            onClick={() => {
              handleColumnClick(column);
            }}
            data-test-id={dataTestId + "-" + column.key + "-column"}
            className={classNames("cell", {
              "cell--sortable": column.isSortable,
              "cell--centered": column.isCenterAligned,
              "cell--right-aligned": column.isRightAligned,
              "cell--last-with-extra-space":
                onHeaderSettingsClick && utils.isLastColumn(i, columns),
            })}
            onKeyPress={(ev) => handleKeyPress(ev, column)}
            tabIndex={column.isSortable ? 0 : -1}
            aria-sort={utils.getAriaSort(column)}
          >
            <span
              title={
                columnNameTransform
                  ? columnNameTransform(column.name)
                  : column.name
              }
            >
              {columnNameTransform
                ? columnNameTransform(column.name)
                : column.name}
            </span>
            {column.isSortable && getSortIcon(column.sort)}
          </th>
        ))}
      {onHeaderSettingsClick && (
        <div className="table__header__settings-btn">
          <IconButton
            isCircle
            type="text"
            size={size === "large" ? "big" : "normal"}
            spacing="tiny"
            icon="settings"
            tooltipText="Header settings"
            onButtonClick={onHeaderSettingsClick}
            dataTestId={dataTestId + "-settings-btn"}
          />
        </div>
      )}
    </tr>
  );
};

export default TableHeader;
