import { axiosCsisApi } from "@csis.com/tip/src/App";
import { QueryParams } from "../../types";

export async function fetchSourceStatisticsApi(
  queryParams: Partial<QueryParams>
) {
  return axiosCsisApi.getVictimSourceStatsApi10CompromisedDataCredentialReportStatisticsSourceGet(
    queryParams
  );
}
