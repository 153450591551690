import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { QueryParams, StatusHistogramEntry, StatusTotalEntry } from "../types";
import {
  fetchStatusStatisticsApi,
  fetchStatusStatisticsHistogramApi,
} from "./api/api";
import {
  StatusStatisticsHistogramResponse,
  StatusStatisticsResponse,
} from "./api/types";

const forensicsStatisticsStatusSlice = createAsyncArrayFetchSlice<
  StatusTotalEntry,
  QueryParams
>("forensicsStatisticsStatus");

export const forensicsStatisticsStatusReducer =
  forensicsStatisticsStatusSlice.reducer;

export const {
  fetchData: fetchStatusDistribution,
  setIsPending: setIsStatusDistributionPending,
  setFetchError: setStatusDistributionFetchError,
  setFetchSuccess: setFetchStatusDistributionSuccess,
} = forensicsStatisticsStatusSlice.actions;

const forensicsStatisticsStatusOverTimeSlice = createAsyncArrayFetchSlice<
  StatusHistogramEntry,
  QueryParams
>("forensicsStatisticsStatusOverTime");

export const forensicsStatisticsStatusOverTimeReducer =
  forensicsStatisticsStatusOverTimeSlice.reducer;

export const {
  fetchData: fetchStatusDistributionOverTime,
  setIsPending: setIsStatusDistributionOverTimePending,
  setFetchError: setStatusDistributionOverTimeFetchError,
  setFetchSuccess: setFetchStatusDistributionOverTimeSuccess,
} = forensicsStatisticsStatusOverTimeSlice.actions;

// Async stuff - sagas

function* fetchStatusDistributionSaga(
  action: PayloadAction<Partial<QueryParams>>
) {
  yield put(setIsStatusDistributionPending());

  try {
    const response: AxiosResponse<StatusStatisticsResponse> = yield call(
      fetchStatusStatisticsApi,
      action.payload
    );
    yield put(setFetchStatusDistributionSuccess(response.data.payload));
  } catch (e) {
    yield put(setStatusDistributionFetchError());
  }
}

function* fetchStatusDistributionOverTimeSaga(
  action: PayloadAction<Partial<QueryParams>>
) {
  yield put(setIsStatusDistributionOverTimePending());

  try {
    const response: AxiosResponse<StatusStatisticsHistogramResponse> =
      yield call(fetchStatusStatisticsHistogramApi, action.payload);

    yield put(setFetchStatusDistributionOverTimeSuccess(response.data.payload));
  } catch (e) {
    yield put(setStatusDistributionOverTimeFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchStatusDistribution.toString(),
    fetchStatusDistributionSaga
  );
  yield takeLatest(
    fetchStatusDistributionOverTime.toString(),
    fetchStatusDistributionOverTimeSaga
  );
}

export function* forensicsStatisticsStatusSagas() {
  yield all([actionWatcher()]);
}
