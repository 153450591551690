import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { QueryParams } from "../types";
import { mapInsightsStartAndEndDateToCorrectType } from "./utils";

export async function fetchArticlesApi(queryParams: Partial<QueryParams>) {
  const query = mapInsightsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getArticlesApi10ArticleGet(query, STRINGIFY_QUERY_PARAMS);
}
