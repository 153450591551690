import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  GetClientRolesApi10OrganizationClientRolesGetParams,
  RoleDetailListResponse,
  RolePreview,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { getSelectedOrgId } from "../../../Security/selectors";
import { fetchClientRolesApi } from "./api/api";

const clientRolesSlice = createAsyncArrayFetchSlice<RolePreview, undefined>(
  "clientRoles"
);

export default clientRolesSlice.reducer;

export const {
  fetchData: fetchClientRoles,
  setIsPending: setIsClientRolesFetchPending,
  setFetchError: setClientRolesFetchError,
  setFetchSuccess: setFetchClientRolesSuccess,
} = clientRolesSlice.actions;

// Async stuff - sagas

function* fetchClientRolesSaga() {
  yield put(setIsClientRolesFetchPending());

  const selectedOrgId: string | undefined = yield select(getSelectedOrgId);
  if (!selectedOrgId) {
    yield put(setClientRolesFetchError());
    return;
  }
  const params: GetClientRolesApi10OrganizationClientRolesGetParams = {
    organization_id: selectedOrgId,
  };
  try {
    const response: AxiosResponse<RoleDetailListResponse> = yield call(
      fetchClientRolesApi,
      params
    );
    yield put(setFetchClientRolesSuccess(response.data.payload));
  } catch (e) {
    yield put(setClientRolesFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchClientRoles.toString(), fetchClientRolesSaga);
}

export function* clientRolesSagas() {
  yield all([actionWatcher()]);
}
