import { IconKind } from "@csis.com/components/src/icons/Icon";
import { TranslationKey } from "@csis.com/tip/src/translations/types";

export const PlatformValues = {
  WINDOWS: "windows",
  ANDROID: "android",
} as const;

export type Platform = typeof PlatformValues[keyof typeof PlatformValues];

export const isPlatform = (x: any): x is Platform =>
  Object.values(PlatformValues).includes(x);

// this is the part we localize
export const PlatformTranslationKeys: { [key in Platform]: TranslationKey } = {
  [PlatformValues.WINDOWS]: "windows",
  [PlatformValues.ANDROID]: "android",
} as const;

export const PlatformIcons: { [key in Platform]: IconKind } = {
  [PlatformValues.WINDOWS]: "windows_logo",
  [PlatformValues.ANDROID]: "android_logo",
} as const;
