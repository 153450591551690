import axios from "axios";
import * as qs from "query-string";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { API_ENDPOINTS } from "@csis.com/tip/src/api/apiEndpoints";
import { GetExportCsvApi10InvestigationExportCsvGetParams } from "@csis.com/tip/src/api/openapi/data-contracts";
import {
  STRINGIFY_QUERY_PARAMS,
  getEndpointWithParams,
} from "@csis.com/tip/src/api/utils";
import { investigationsKeys } from "../constants";
import { QueryParams } from "../types";
import { InvestigationsResponse } from "./types";

export async function fetchInvestigationApi(
  queryParams: Partial<QueryParams>,
  onlyExternallyVisible: boolean = true
) {
  const entityType = queryParams[investigationsKeys.ENTITY_TYPE];
  const entityValue = queryParams[investigationsKeys.ENTITY_VALUE];

  if (entityType && entityValue) {
    const paramsAsString = qs.stringify({
      ...queryParams,
      only_externally_visible: onlyExternallyVisible,
    });

    const url = getEndpointWithParams(
      API_ENDPOINTS.investigation,
      paramsAsString
    );
    return await axios.get<InvestigationsResponse>(url);
  }
  return null;
}

export async function exportInvestigationCsvApi(
  queryParams: Partial<QueryParams>,
  onlyExternallyVisible: boolean = true
) {
  const params = {
    ...queryParams,
    only_externally_visible: onlyExternallyVisible,
  } as GetExportCsvApi10InvestigationExportCsvGetParams;

  return axiosCsisApi.getExportCsvApi10InvestigationExportCsvGet(params, {
    format: "blob",
    ...STRINGIFY_QUERY_PARAMS,
  });
}
