/* eslint-disable sonarjs/no-duplicate-string */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  AuditlogEventPageDetailResponse,
  AuditlogEventRead,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { PredefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import { getSelectedOrgId } from "../../Security/selectors";
import { AuditLogColumns, columns } from "./Table/columns";
import { exportAuditLogCsvApi, fetchAuditLogApi } from "./api/api";
import { QueryParams } from "./types";

const testDataRows2: AuditlogEventRead[] = [
  {
    event_timestamp: "2024-05-23T08:17:56.545987+00:00",
    actor_name: "bhv@csis.com",
    actor_external_id: "b57849f2-ac9b-41c7-a155-320ab112f568",
    actor_ip: "87.66.73.202",
    actor_type: "user",
    activity: "ticket_viewed",
    target_name: "bhv@csis.com",
    target_external_id: "b57849f2-ac9b-41c7-a155-320ab112f568",
    correlation_id: undefined,
    external_id: "10bd32fc-159b-414a-9d13-168688c9781b",
    activity_description: "desc",
  },
  {
    event_timestamp: "2024-05-23T08:17:12.243904+00:00",
    actor_name: "bhv@csis.com",
    actor_external_id: "b57849f2-ac9b-41c7-a155-320ab112f568",
    actor_ip: "87.66.73.202",
    actor_type: "user",
    activity: "user_logged_in",
    target_name: "bhv@csis.com",
    target_external_id: "b57849f2-ac9b-41c7-a155-320ab112f568",
    correlation_id: undefined,
    external_id: "bc46044a-dd6b-4f41-9bca-d79d8f951406",
    activity_description: "desc",
  },
  {
    event_timestamp: "2024-05-23T08:17:12.126343+00:00",
    actor_name: "bhv@csis.com",
    actor_external_id: "b57849f2-ac9b-41c7-a155-320ab112f568",
    actor_ip: "87.66.73.202",
    actor_type: "user",
    activity: "authorization_code_issued",
    target_name: "bhv@csis.com",
    target_external_id: "b57849f2-ac9b-41c7-a155-320ab112f568",
    correlation_id: undefined,
    external_id: "12cab0a4-0a51-4ea5-a87a-9907c1fd8f95",
    activity_description: "desc",
  },
  {
    event_timestamp: "2024-05-23T08:16:54.486224+00:00",
    actor_name: undefined,
    actor_external_id: undefined,
    actor_ip: "198.35.72.86",
    actor_type: undefined,
    activity: "authentication_failed",
    target_name: "bhv@csis.com",
    target_external_id: undefined,
    correlation_id: undefined,
    external_id: "b4199eef-879a-438e-ae73-46de4a86477c",
    activity_description: "desc",
  },
];

interface StateSlice {
  auditLog: AuditlogEventRead[] | null;
  hasNextPage: boolean;
  isPending: boolean;
  fetchError: string | null;
  columns: AuditLogColumns;
  isCsvExportPending: boolean;
}
const initialState: StateSlice = {
  auditLog: null,
  hasNextPage: false,
  isPending: false,
  fetchError: null,
  columns: columns,
  isCsvExportPending: false,
};

const auditLogSlice = createSlice({
  name: "auditLog",
  initialState: initialState,
  reducers: {
    fetchAuditLog(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setAuditLogPending(state) {
      state.isPending = true;
      state.fetchError = null;
      state.auditLog = [];
    },
    setFetchAuditLogError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
      state.auditLog = [];
    },
    fetchAuditLogSuccess(state, action: PayloadAction<AuditlogEventRead[]>) {
      state.isPending = false;
      state.fetchError = null;
      state.auditLog = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },
    reorderColumns(state, action: PayloadAction<AuditLogColumns>) {
      state.columns = action.payload;
    },
    setResultsPerPageUserPreference(
      _state,
      _action: PayloadAction<PredefinedResultsPerPage>
    ) {
      //empty handled by saga
    },

    exportAuditLogCsv(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setCsvExportPending(state) {
      state.isCsvExportPending = true;
    },
    setCsvExportComplete(state) {
      state.isCsvExportPending = false;
    },
  },
});

export default auditLogSlice.reducer;

export const {
  fetchAuditLog,
  setAuditLogPending,
  setFetchAuditLogError,
  fetchAuditLogSuccess,
  setHasNextPage,
  reorderColumns,
  setResultsPerPageUserPreference,
  exportAuditLogCsv,
  setCsvExportPending,
  setCsvExportComplete,
} = auditLogSlice.actions;

function* fetchAuditLogSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setAuditLogPending());

  const organizationId: string = yield select(getSelectedOrgId) || "";

  const params = {
    queryParams: action.payload,
    organizationId,
  };

  try {
    const response: AxiosResponse<AuditlogEventPageDetailResponse> = yield call(
      fetchAuditLogApi,
      params
    );

    yield put(fetchAuditLogSuccess(response.data.payload.page));
    yield put(setHasNextPage(response.data.payload.has_next));
  } catch (e) {
    yield put(fetchAuditLogSuccess(testDataRows2));
    yield put(setHasNextPage(false));
    //const errorMessage = handleRequestError(e);
    //yield put(setFetchAuditLogError(errorMessage));
  }
}

function* exportAuditLogCsvSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setCsvExportPending());

  try {
    const response: AxiosResponse<Blob> = yield call(
      exportAuditLogCsvApi,
      action.payload
    );

    const blob = response.data;
    downloadBlobForUser(blob, "auditLog_csv.csv");

    yield put(setCsvExportComplete());
  } catch (e) {
    yield put(setCsvExportComplete());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchAuditLog.toString(), fetchAuditLogSaga);
  yield takeLatest(exportAuditLogCsv.toString(), exportAuditLogCsvSaga);
}

export function* auditLogSagas() {
  yield all([actionWatcher()]);
}
