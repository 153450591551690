import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  CreateClientApi10OrganizationClientPostParams,
  DeleteClientApi10OrganizationClientExternalIdDeleteParams,
  GetClientsApi10OrganizationClientGetParams,
  UpdateClientApi10OrganizationClientExternalIdPutParams,
  UpdateClientBody,
} from "@csis.com/tip/src/api/openapi/data-contracts";

export async function fetchApiClientsApi(
  params: GetClientsApi10OrganizationClientGetParams
) {
  return axiosCsisApi.getClientsApi10OrganizationClientGet(params);
}

export async function postApiClientsApi(
  query: CreateClientApi10OrganizationClientPostParams,
  data: UpdateClientBody
) {
  return axiosCsisApi.createClientApi10OrganizationClientPost(query, data);
}

export async function updateApiClientsApi(
  params: UpdateClientApi10OrganizationClientExternalIdPutParams,
  data: UpdateClientBody
) {
  return axiosCsisApi.updateClientApi10OrganizationClientExternalIdPut(
    params,
    data
  );
}

export async function deleteApiClientsApi(
  params: DeleteClientApi10OrganizationClientExternalIdDeleteParams
) {
  return axiosCsisApi.deleteClientApi10OrganizationClientExternalIdDelete(
    params
  );
}
