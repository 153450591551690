import { axiosCsisApi } from "@csis.com/tip/src/App";
import { QueryParams } from "../../types";

export async function fetchStatusStatisticsApi(
  queryParams: Partial<QueryParams>
) {
  return axiosCsisApi.getVictimStatsStateApi10CompromisedDataCredentialReportStatisticsStatusGet(
    queryParams
  );
}
