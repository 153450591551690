// this should be 1:1 with the api endpoints
export const remoteForensicsSubPageKeys = {
  // Analysis
  ANALYSIS_OVERVIEW: "analysis-overview",
  DETECTION_TOOLS: "detection-tools",
  PROCESSES: "processes",
  MEMORY_ANALYSIS: "memory-analysis",
  SCHEDULED_TASKS: "scheduled-tasks",

  // File system
  SUSPICIOUS_EXTENSIONS: "suspicious-extensions",
  KNOWN_BAD_HASHES: "suspicious-hashes",
  MALWARE_DROPPERS: "malware-droppers",
  SUSPICIOUS_FILES: "suspicious-files",
  RAT: "filesystem-remote-admin-tools",
  AMCACHE: "amcache",
  SHIM: "app-compat-cache",

  // Network
  DNS_QUERIES: "dns-queries",
  CONNECTIONS: "net-connections",
  OPEN_PORTS: "open-ports",

  // Registry
  AUTORUN_KEYS: "autorun-keys",
  RAT_REGISTRY: "registry-remote-admin-tools",
  SUSPICIOUS_REGISTRY_KEYS: "registry-suspicious-keys",

  //Browser
  BROWSER_VISITS: "browser-suspicious-visits",

  // Android
  ANDROID_APPLICATIONS: "applications",
  ANDROID_STORAGE: "externalstorage",

  // NOT USED YET?
  REGISTRY: "registry",
} as const;
