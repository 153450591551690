import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  GetUserGroupsApi10OrganizationUserGroupGetParams,
  UserGroupDetail,
  UserGroupDetailListResponse,
  UserGroupUpdate,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { getSelectedOrgId } from "@csis.com/tip/src/pages/Profile/Security/selectors";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { createAsyncDataUpdateSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncDataUpdateSlice";
import {
  deleteUserGroupApi,
  fetchUserGroupsApi,
  postUserGroupApi,
  updateUserGroupApi,
} from "./api/api";

const userGroupsSlice = createAsyncArrayFetchSlice<UserGroupDetail, undefined>(
  "userGroups"
);

export const userGroupsReducer = userGroupsSlice.reducer;

export const {
  fetchData: fetchUserGroups,
  setIsPending: setIsUserGroupsFetchPending,
  setFetchError: setUserGroupsFetchError,
  setFetchSuccess: setFetchUserGroupsSuccess,
} = userGroupsSlice.actions;

export type UserGroupCreatePayload = {
  data: UserGroupUpdate;
  action: "create";
};
export type UserGroupUpdatePayload = {
  id: string;
  data: UserGroupUpdate;
  action: "update";
};
export type UserGroupDeletePayload = { id: string; action: "delete" };
export type UserGroupModifyPayload =
  | UserGroupCreatePayload
  | UserGroupUpdatePayload
  | UserGroupDeletePayload;

const userGroupsUpdateSlice =
  createAsyncDataUpdateSlice<UserGroupModifyPayload>("userGroupsUpdate");

export const userGroupsUpdateReducer = userGroupsUpdateSlice.reducer;

export const {
  updateData: modifyUserGroups,
  setIsUpdatePending,
  setIsUpdateError,
  setIsUpdateSuccess,
  resetState,
} = userGroupsUpdateSlice.actions;

export type UserGroupUsersAddPayload = {
  id: string;
  action: "add";
  users: string[];
};
export type UserGroupUsersDeletePayload = {
  id: string;
  action: "remove";
  users: string[];
};
export type UserGroupUsersModifyPayload =
  | UserGroupUsersAddPayload
  | UserGroupUsersDeletePayload;

const userGroupUpdateUsersSlice =
  createAsyncDataUpdateSlice<UserGroupUsersModifyPayload>(
    "userGroupUsersUpdate"
  );

export const userGroupsUpdateUsersReducer = userGroupUpdateUsersSlice.reducer;

export const {
  updateData: modifyUserGroupUsers,
  setIsUpdatePending: setIsUpdateUsersPending,
  setIsUpdateError: setIsUpdateUsersError,
  setIsUpdateSuccess: setIsUpdateUsersSuccess,
  resetState: resetUsersState,
} = userGroupUpdateUsersSlice.actions;

// Async stuff - sagas

function* fetchUserGroupsSaga() {
  yield put(setIsUserGroupsFetchPending());

  const selectedOrgId: string | undefined = yield select(getSelectedOrgId);
  if (!selectedOrgId) {
    yield put(setUserGroupsFetchError());
    return;
  }
  const params: GetUserGroupsApi10OrganizationUserGroupGetParams = {
    organization_id: selectedOrgId,
  };

  try {
    const response: AxiosResponse<UserGroupDetailListResponse> = yield call(
      fetchUserGroupsApi,
      params
    );
    yield put(setFetchUserGroupsSuccess(response.data.payload));
  } catch (e) {
    yield put(setUserGroupsFetchError());
  }
}

function* modifyUserGroupsSaga(action: PayloadAction<UserGroupModifyPayload>) {
  yield put(setIsUpdatePending());
  const selectedOrgId: string | undefined = yield select(getSelectedOrgId);
  if (!selectedOrgId) {
    return;
  }

  try {
    if (action.payload.action === "create") {
      yield call(
        postUserGroupApi,
        { organization_id: selectedOrgId },
        action.payload.data
      );
    } else if (action.payload.action === "delete") {
      yield call(deleteUserGroupApi, action.payload.id);
    } else if (action.payload.action === "update") {
      yield call(
        updateUserGroupApi,
        { externalId: action.payload.id, organization_id: selectedOrgId },
        action.payload.data
      );
    } else {
      throw new Error("Invalid action");
    }
    yield put(setIsUpdateSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setIsUpdateError(errorMessage));
  }
}

function* modifyUserGroupUsersSaga(
  action: PayloadAction<UserGroupUsersModifyPayload>
) {
  yield put(setIsUpdateUsersPending());

  try {
    if (action.payload.action === "add") {
      yield call(
        axiosCsisApi.addUsersToUserGroupsApi10OrganizationUserGroupExternalIdUserPut,
        { externalId: action.payload.id },
        { users: action.payload.users }
      );
    } else if (action.payload.action === "remove") {
      yield call(
        axiosCsisApi.removeUsersInUserGroupApi10OrganizationUserGroupExternalIdUserDelete,
        action.payload.id,
        { users: action.payload.users }
      );
    } else {
      throw new Error("Invalid action");
    }
    yield put(setIsUpdateUsersSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setIsUpdateUsersError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchUserGroups.toString(), fetchUserGroupsSaga);
  yield takeLatest(modifyUserGroups.toString(), modifyUserGroupsSaga);
  yield takeLatest(modifyUserGroupUsers.toString(), modifyUserGroupUsersSaga);
}

export function* userGroupsSagas() {
  yield all([actionWatcher()]);
}
