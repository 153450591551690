/* eslint-disable sonarjs/no-duplicate-string */
import { Icon, SearchableCell, Stack } from "@csis.com/components";
import * as tableTypes from "@csis.com/components/src/atoms/Table/types";
import { truncateText } from "@csis.com/components/src/utils/utils";
import { isString } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { getFlagIconAndCountryCode } from "@csis.com/tip/src/utils/flags";
import { isAsnEntry } from "../../models/Asn/Asn";
import { isBrandEntry } from "../../models/Brand/Brand";
import { isOriginPreview } from "../../models/Origin/Origin";
import {
  StatusColors,
  StatusIcons,
  StatusTranslationKeys,
  StatusValues,
  isStatus,
} from "../../models/Status";
import { urlsKeys } from "../constants";
import { FilterKey } from "../types";

const MAX_ELEMENTS_PER_ROW = 3;

export const formatUrl: tableTypes.TableCellFormatter<string> = (
  row,
  column
) => {
  const url = row[column];
  const truncatedUrl = isString(url)
    ? truncateText({ text: url, maxChars: 60 })
    : url;

  if (row[urlsKeys.STATUS] === StatusValues.ACTIVE) {
    return <span className="f_csis">{truncatedUrl}</span>;
  } else {
    return truncatedUrl;
  }
};

export const originCell: tableTypes.TableCellFormatter<string> = (
  row,
  column
) => {
  const origin = row[column];

  if (origin && isOriginPreview(origin)) {
    return (
      <Stack isVertical>
        <div>{origin.origin_name}</div>
        <div title="Origin" className="f_secondary f_small f_underline-dashed">
          [{origin.origin_class}]
        </div>
        <div className="f_small f_secondary">via {origin.origin_method}</div>
      </Stack>
    );
  }
  return "";
};

export function statusCell(
  t: (key: TranslationKey, args?: string[]) => string
): tableTypes.TableCellFormatter<string> {
  return (row, column) => {
    const statusValue = row[column];
    if (isStatus(statusValue)) {
      return (
        <Icon
          kind={StatusIcons[statusValue]}
          color={StatusColors[statusValue]}
          size="large"
          tooltipText={t(StatusTranslationKeys[statusValue])}
          isInline
        />
      );
    }
    return statusValue;
  };
}

export const brandCellWithHandler: (
  handleClick: (newValues: unknown, name: FilterKey) => void
) => tableTypes.TableCellFormatter<string> = (handleClick) => {
  return (row, column) => {
    const brand = row[column];

    return brand && isBrandEntry(brand) ? (
      <SearchableCell
        key={"searchable_cell-" + brand.name}
        onClick={() => {
          handleClick([brand.name], urlsKeys.TARGETED_BRAND);
        }}
        dataTestId={"searchable-cell-" + column + "-" + brand.name}
      >
        {brand.display_name}
      </SearchableCell>
    ) : null;
  };
};

export const asnCellWithHandler: (
  handleClick: (newValues: unknown, name: FilterKey) => void
) => tableTypes.TableCellFormatter<string> = (handleClick) => {
  return (row, column) => {
    const asns = row[column];
    if (!asns) {
      return null;
    }

    if (Array.isArray(asns)) {
      return (
        <Stack isVertical>
          {asns.slice(0, MAX_ELEMENTS_PER_ROW).map((asn) => {
            return asn && isAsnEntry(asn) ? (
              <SearchableCell
                size={asns.length > 1 ? "small" : undefined}
                key={"searchable_cell-" + asn.asn}
                onClick={() => {
                  handleClick([asn.asn], urlsKeys.ASN);
                }}
                dataTestId={"searchable-cell-" + column + "-" + asn.asn}
              >
                {asn.orgname}
              </SearchableCell>
            ) : null;
          })}
          {asns.length > MAX_ELEMENTS_PER_ROW && (
            <div className="f_small">...</div>
          )}
        </Stack>
      );
    }
    return null;
  };
};

export const countryCellWithHandler: (
  handleClick: (newValues: unknown, name: FilterKey) => void
) => tableTypes.TableCellFormatter<string> = (handleClick) => {
  return (row, column) => {
    const countryCodes = row[column];
    if (!countryCodes) {
      return null;
    }

    if (Array.isArray(countryCodes)) {
      return (
        <Stack isVertical>
          {countryCodes.slice(0, MAX_ELEMENTS_PER_ROW).map((country) => {
            return isString(country) && country.length === 2 ? (
              <SearchableCell
                dataTestId={"searchable-cell-" + column + "-" + row[column]}
                onClick={() => {
                  handleClick([country], urlsKeys.COUNTRY);
                }}
                key={country}
              >
                {getFlagIconAndCountryCode(country)}
              </SearchableCell>
            ) : (
              country
            );
          })}
          {countryCodes.length > MAX_ELEMENTS_PER_ROW && (
            <div className="f_small">...</div>
          )}
        </Stack>
      );
    }
    return null;
  };
};

export const ipCell: (
  handleClick: (newValues: unknown, fieldKey: FilterKey) => void
) => tableTypes.TableCellFormatter<string> = (handleClick) => {
  return (row, column) => {
    const ips = row[column];
    if (!ips) {
      return null;
    }

    if (Array.isArray(ips)) {
      return (
        <Stack isVertical>
          {ips.slice(0, MAX_ELEMENTS_PER_ROW).map((ip) => {
            return (
              isString(ip) && (
                <SearchableCell
                  size={ips.length > 1 ? "small" : undefined}
                  dataTestId={"searchable-cell-" + column + "-" + row[column]}
                  onClick={() => {
                    handleClick([ip], urlsKeys.IP);
                  }}
                  key={ip}
                >
                  {ip}
                </SearchableCell>
              )
            );
          })}
          {ips.length > MAX_ELEMENTS_PER_ROW && (
            <div className="f_small">...</div>
          )}
        </Stack>
      );
    }
    return null;
  };
};
