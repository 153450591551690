import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  StatisticsCountListResponse,
  StatisticsHistogramCountListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import {
  AlerterHistogramEntry,
  AlerterTotalEntry,
  QueryParams,
} from "../types";
import {
  fetchAlerterStatisticsApi,
  fetchAlerterStatisticsHistogramApi,
} from "./api/api";

interface StateSlice {
  alerterDistribution: AlerterTotalEntry[];
  isAlerterDistributionPending: boolean;
  alerterDistributionFetchError: string | null;

  alerterDistributionOverTime: AlerterHistogramEntry[];
  isAlerterDistributionOverTimePending: boolean;
  alerterDistributionOverTimeFetchError: string | null;
}
const initialState: StateSlice = {
  alerterDistribution: [],
  isAlerterDistributionPending: false,
  alerterDistributionFetchError: null,

  alerterDistributionOverTime: [],
  isAlerterDistributionOverTimePending: false,
  alerterDistributionOverTimeFetchError: null,
};

const alertsStatisticsSlice = createSlice({
  name: "alertsStatisticsAlerter",
  initialState: initialState,
  reducers: {
    fetchAlerterDistribution(_state, _action: PayloadAction<QueryParams>) {
      //empty handled by saga
    },
    setIsAlerterDistributionPending(state) {
      state.isAlerterDistributionPending = true;
      state.alerterDistributionFetchError = null;
    },
    setAlerterDistributionFetchError(state) {
      state.isAlerterDistributionPending = false;
      state.alerterDistributionFetchError = "Something went wrong";
    },
    setFetchAlerterDistributionSuccess(
      state,
      action: PayloadAction<AlerterTotalEntry[]>
    ) {
      state.isAlerterDistributionPending = false;
      state.alerterDistribution = action.payload;
      state.alerterDistributionFetchError = null;
    },

    fetchAlerterDistributionOverTime(
      _state,
      _action: PayloadAction<QueryParams>
    ) {
      //empty handled by saga
    },
    setIsAlerterDistributionOverTimePending(state) {
      state.isAlerterDistributionOverTimePending = true;
      state.alerterDistributionOverTimeFetchError = null;
    },
    setAlerterDistributionOverTimeFetchError(state) {
      state.isAlerterDistributionOverTimePending = false;
      state.alerterDistributionOverTimeFetchError = "Something went wrong";
    },
    setFetchAlerterDistributionOverTimeSuccess(
      state,
      action: PayloadAction<AlerterHistogramEntry[]>
    ) {
      state.isAlerterDistributionOverTimePending = false;
      state.alerterDistributionOverTime = action.payload;
      state.alerterDistributionOverTimeFetchError = null;
    },
  },
});

export default alertsStatisticsSlice.reducer;

export const {
  fetchAlerterDistribution,
  setIsAlerterDistributionPending,
  setAlerterDistributionFetchError,
  setFetchAlerterDistributionSuccess,

  fetchAlerterDistributionOverTime,
  setIsAlerterDistributionOverTimePending,
  setAlerterDistributionOverTimeFetchError,
  setFetchAlerterDistributionOverTimeSuccess,
} = alertsStatisticsSlice.actions;

// Async stuff - sagas

function* fetchAlerterDistributionSaga(action: PayloadAction<QueryParams>) {
  yield put(setIsAlerterDistributionPending());

  try {
    const response: AxiosResponse<StatisticsCountListResponse> = yield call(
      fetchAlerterStatisticsApi,
      action.payload
    );

    yield put(setFetchAlerterDistributionSuccess(response.data.payload));
  } catch (e) {
    yield put(setAlerterDistributionFetchError());
  }
}

function* fetchAlerterDistributionOverTimeSaga(
  action: PayloadAction<QueryParams>
) {
  yield put(setIsAlerterDistributionOverTimePending());

  try {
    const response: AxiosResponse<StatisticsHistogramCountListResponse> =
      yield call(fetchAlerterStatisticsHistogramApi, action.payload);

    yield put(
      setFetchAlerterDistributionOverTimeSuccess(response.data.payload)
    );
  } catch (e) {
    yield put(setAlerterDistributionOverTimeFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchAlerterDistribution.toString(),
    fetchAlerterDistributionSaga
  );
  yield takeLatest(
    fetchAlerterDistributionOverTime.toString(),
    fetchAlerterDistributionOverTimeSaga
  );
}

export function* alertsStatisticsAlerterSagas() {
  yield all([actionWatcher()]);
}
