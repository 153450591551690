import React, { useCallback } from "react";

export interface FormInterface {
  id: string;
  onFormSubmit: () => void;
}

const Form: React.FC<FormInterface> = ({ id, onFormSubmit, children }) => {
  const handleFormSubmit = useCallback(
    (e?: React.FormEvent) => {
      // debug the validity with formRef.current?.reportValidity()
      // submitted forms have passed validity checks
      // so if it reaches here the form is validated
      if (e) {
        e.preventDefault();
      }
      onFormSubmit();
    },
    [onFormSubmit]
  );

  return (
    <form id={id} onSubmit={handleFormSubmit}>
      {children}
    </form>
  );
};

export default Form;
