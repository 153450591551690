import axios from "axios";
import { API_ENDPOINTS } from "@csis.com/tip/src/api/apiEndpoints";
import { MdfeForm } from "../types";

export async function fetchIsValidFormApi(id: string) {
  return await axios.get<any>(API_ENDPOINTS.onboarding_mdfecustomer_id(id));
}

export async function postFormByIdApi(form: MdfeForm, id: string) {
  return await axios({
    method: "post",
    url: API_ENDPOINTS.onboarding_mdfecustomer_id(id),
    data: form,
  });
}
