import { createSelector } from "@reduxjs/toolkit";
import { getNewColumnsBasedOnSort } from "@csis.com/tip/src/components/shared/DataTableContainer/utils";
import { RootState } from "@csis.com/tip/src/configureStore";
import { ForensicsColumns } from "./CasesTable/columns";

export const getCases = (state: RootState) => state.casesReducer.cases;

export const getIsCasesPending = (state: RootState) =>
  state.casesReducer.isPending;

export const getCasesFetchError = (state: RootState) =>
  state.casesReducer.fetchError;

export const getHasNextPage = (state: RootState) =>
  state.casesReducer.hasNextPage;

export const getCasesResult = createSelector(
  [getCases, getIsCasesPending, getCasesFetchError],
  (cases, isCasesPending, casesFetchError) => {
    return { cases, isCasesPending, casesFetchError };
  }
);

export const getRemForensicsColumns = (state: RootState) =>
  state.casesReducer.columns;

export const getCalculatedColumns = createSelector(
  [getRemForensicsColumns, (_: RootState, sort?: string) => sort],
  (columns, sort) => {
    return getNewColumnsBasedOnSort(columns, sort);
  }
  // we need to explicitly type it here so typescript doesnt show false warning
) as (state: RootState, sort?: string) => ForensicsColumns;
