/* eslint-disable sonarjs/no-identical-functions */
import { FC } from "react";
import { Stack } from "@csis.com/components";

const MenuGroup: FC<{ header: string; isHeaderTopLevel?: boolean }> = ({
  header,
  isHeaderTopLevel = false,
  children,
}) => {
  const headerId = `#${header.toLowerCase().replace(" ", "-")}`;

  return (
    <Stack isVertical>
      {isHeaderTopLevel ? (
        <a href={headerId}>
          <h2 className="f_semibold f_csis f_large">{header}</h2>
        </a>
      ) : (
        <a href={headerId} className="f_semibold">
          {header}
        </a>
      )}
      <div className="help-and-support-page__menu-group">
        <nav aria-label={header.toLowerCase()}>
          <Stack isVertical>{children}</Stack>
        </nav>
      </div>
    </Stack>
  );
};

export const HelpSideBar = () => {
  return (
    <nav aria-label="api documentation sidebar">
      <Stack isVertical gutterSize="huge">
        <MenuGroup header="eCrime APIs" isHeaderTopLevel>
          <Stack isVertical>
            <MenuGroup header="Gaining Access">
              <a href="#token-authentication">Token Authentication</a>
            </MenuGroup>

            <MenuGroup header="Alerts">
              <a href="#list-subscribed-alerters">List subscribed Alerters</a>
              <a href="#alert-detail">Alert Detail</a>
              <a href="#alert-search">Alert Search</a>
            </MenuGroup>

            <MenuGroup header="Compromised Data">
              <a href="#list-compromised-payment-card-reports">
                List compromised payment card reports
              </a>
              <a href="#list-compromised-credential-reports">
                List compromised credential reports
              </a>
            </MenuGroup>

            <MenuGroup header="Tickets">
              <a href="#list-all-tickets">List all Tickets</a>
              <a href="#view-single-ticket">View single Ticket</a>
              <a href="#ticket-thread">Ticket Thread</a>
            </MenuGroup>

            <MenuGroup header="Phishing">
              <a href="#check-if-a-url-is-in-our-records">
                Check if a url is in our records
              </a>
              <a href="#check-if-a-domain-is-in-our-records">
                Check if a domain is in our records
              </a>
              <a href="#check-if-a-hostname-is-in-our-records">
                Check if a hostname is in our records
              </a>
              <a href="#get-stats-for-a-url">Get stats for a URL</a>
              <a href="#submit-a-url">Submit a URL</a>
              <a href="#get-all-urls">Get all URLs</a>
              <a href="#specific-url-contents">Specific URL contents</a>
            </MenuGroup>
          </Stack>
        </MenuGroup>
      </Stack>
    </nav>
  );
};
