import { Guard, isArrayOf } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";

export const SeverityValues = {
  NA: "na",
  INFO: "info",
  LOW: "low",
  MEDIUM: "medium",
  HIGH: "high",
  CRITICAL: "critical",
} as const;

export type Severity = typeof SeverityValues[keyof typeof SeverityValues];

export const isSeverity = (x: any): x is Severity => {
  return Object.values(SeverityValues).includes(x);
};

export const isArrayOfSeverities: Guard<Severity[]> = isArrayOf(isSeverity);

export const SeverityColors = {
  [SeverityValues.NA]: "blue",
  [SeverityValues.INFO]: "blue",
  [SeverityValues.LOW]: "orange",
  [SeverityValues.MEDIUM]: "orange",
  [SeverityValues.HIGH]: "red",
  [SeverityValues.CRITICAL]: "red",
} as const;

export const SeverityClassColors = {
  [SeverityValues.NA]: "f_blue",
  [SeverityValues.INFO]: "f_blue",
  [SeverityValues.LOW]: "f_orange",
  [SeverityValues.MEDIUM]: "f_orange",
  [SeverityValues.HIGH]: "f_red",
  [SeverityValues.CRITICAL]: "f_red",
} as const;

// this is the part we localize
export const SeverityTranslationKeys: { [key in Severity]: TranslationKey } = {
  [SeverityValues.NA]: "na",
  [SeverityValues.INFO]: "info",
  [SeverityValues.LOW]: "low",
  [SeverityValues.MEDIUM]: "medium",
  [SeverityValues.HIGH]: "high",
  [SeverityValues.CRITICAL]: "critical",
} as const;
