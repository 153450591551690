import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { ForensicsColumns, columns } from "./CasesTable/columns";
import { fetchCasesApi } from "./api/api";
import { CasesResponse } from "./api/types";
import { CasesRow, QueryParams } from "./types";

interface StateSlice {
  cases: CasesRow[] | null;
  hasNextPage: boolean;
  isPending: boolean;
  fetchError: string | null;
  columns: ForensicsColumns;
}
const initialState: StateSlice = {
  cases: null,
  hasNextPage: false,
  isPending: false,
  fetchError: null,
  columns: columns,
};

const casesSlice = createSlice({
  name: "cases",
  initialState: initialState,
  reducers: {
    fetchCases(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
      state.cases = [];
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
      state.cases = [];
    },
    fetchSuccess(state, action: PayloadAction<CasesRow[]>) {
      state.isPending = false;
      state.fetchError = null;
      state.cases = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },
    reorderColumns(state, action: PayloadAction<ForensicsColumns>) {
      state.columns = action.payload;
    },
  },
});

export default casesSlice.reducer;

export const {
  fetchCases,
  setPending,
  setFetchError,
  fetchSuccess,
  setHasNextPage,
  reorderColumns,
} = casesSlice.actions;

// Async stuff - sagas

function* fetchCasesSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setPending());

  try {
    const response: AxiosResponse<CasesResponse> = yield call(
      fetchCasesApi,
      action.payload
    );

    yield put(fetchSuccess(response.data.payload.page as CasesRow[]));
    yield put(setHasNextPage(response.data.payload.has_next));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchCases.toString(), fetchCasesSaga);
}

export function* casesSagas() {
  yield all([actionWatcher()]);
}
