import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  CreateUserGroupApi10OrganizationUserGroupPostParams,
  GetUserGroupsApi10OrganizationUserGroupGetParams,
  UpdateUserGroupApi10OrganizationUserGroupExternalIdPutParams,
  UserGroupUpdate,
} from "@csis.com/tip/src/api/openapi/data-contracts";

export async function fetchUserGroupsApi(
  params: GetUserGroupsApi10OrganizationUserGroupGetParams
) {
  return axiosCsisApi.getUserGroupsApi10OrganizationUserGroupGet(params);
}

export async function postUserGroupApi(
  params: CreateUserGroupApi10OrganizationUserGroupPostParams,
  data: UserGroupUpdate
) {
  return axiosCsisApi.createUserGroupApi10OrganizationUserGroupPost(
    params,
    data
  );
}

export async function updateUserGroupApi(
  params: UpdateUserGroupApi10OrganizationUserGroupExternalIdPutParams,
  data: UserGroupUpdate
) {
  return axiosCsisApi.updateUserGroupApi10OrganizationUserGroupExternalIdPut(
    params,
    data
  );
}

export async function deleteUserGroupApi(external_id: string) {
  return axiosCsisApi.deleteUserGroupApi10OrganizationUserGroupExternalIdDelete(
    external_id
  );
}
