import classNames from "classnames";
import React from "react";

export interface CollapsablePanelInterface {}

const CollapsablePanel: React.FC<CollapsablePanelInterface> = ({
  children,
}) => {
  const classes = classNames("collapsable-panel", {});

  return <div className={classes}>{children}</div>;
};

export default CollapsablePanel;
