import { axiosCsisApi } from "@csis.com/tip/src/App";

export async function fetchArticleApi(id: string) {
  return axiosCsisApi.getArticleApi10ArticleExternalIdGet({ externalId: id });
}

export async function fetchArticleAttachmentApi(
  articleId: string,
  attachmentId: string
) {
  return axiosCsisApi.getAttachmentApi10ArticleExternalIdAttachmentAttachmentExternalIdGet(
    {
      attachmentExternalId: attachmentId,
      externalId: articleId,
    },
    {
      format: "blob",
    }
  );
}

export async function fetchRelatedArticlesApi(id: string) {
  return axiosCsisApi.getRelatedArticlesApi10ArticleExternalIdRelatedGet({
    externalId: id,
    limit: 5,
  });
}
