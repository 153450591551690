import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Carousel, Table } from "@csis.com/components";
import { OnRowClick } from "@csis.com/components/src/atoms/Table/types";
import { fetchStatusDistribution } from "@csis.com/tip/src/pages/Products/Phishing/Statistics/Status/slice";
import { urlsKeys } from "@csis.com/tip/src/pages/Products/Phishing/UrlsSearch/constants";
import { getUrlsResult } from "@csis.com/tip/src/pages/Products/Phishing/UrlsSearch/selectors";
import { fetchUrls } from "@csis.com/tip/src/pages/Products/Phishing/UrlsSearch/slice";
import {
  QueryParams,
  UrlsRow,
} from "@csis.com/tip/src/pages/Products/Phishing/UrlsSearch/types";
import urlTemplates from "@csis.com/tip/src/routes/urlTemplates";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { getNewLocationUrl } from "@csis.com/tip/src/utils/updateLocationWithParams";
import { StatusPieChart } from "../../Phishing/Statistics/Status/StatusPieChart";
import { getStatusDistributionResult } from "../../Phishing/Statistics/Status/selectors";
import { UrlsColumns } from "../../Phishing/UrlsSearch/Table/columns";
import {
  formatUrl,
  statusCell,
} from "../../Phishing/UrlsSearch/Table/tableCellFormatters";
import { StatusValues } from "../../Phishing/models/Status";
import ProductCard from "./ProductCard/ProductCard";
import { ELEMENTS_PER_TABLE, REQUEST_DELAY_MS } from "./constants";

const columns: UrlsColumns = [
  { key: urlsKeys.URL, name: "url" },
  { key: urlsKeys.STATUS, name: "status" },
];

const slidesInfo = [
  {
    subtitle: "Status Distributions",
    viewAllUrl: urls.products_phishing_statistics,
  },
  {
    subtitle: "Latest URLs",
    viewAllUrl: urls.products_phishing_urls,
  },
];

export default function PhishingProductCard({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [requestDelay, setRequestDelay] = useState<number>(REQUEST_DELAY_MS);
  const { t } = useTranslations();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  function handleCarouselChange(newSlideIndex: number) {
    setCurrentSlideIndex(newSlideIndex);
  }

  useEffect(() => {
    const queryParams: Partial<QueryParams> = {
      [urlsKeys.START_DATE]: startDate,
      [urlsKeys.END_DATE]: endDate,
    };

    dispatch(fetchStatusDistribution(queryParams));
    const timer = setTimeout(() => {
      dispatch(fetchUrls(queryParams));
      setRequestDelay(0);
    }, requestDelay);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endDate, startDate]);

  const {
    urls: phishingUrls,
    isUrlsPending,
    urlsError,
  } = useSelector(getUrlsResult);

  const handleRowClick: OnRowClick<UrlsRow> = (row) => {
    history.push(
      urlTemplates._products_phishing_urls_url_id(row[urlsKeys.HASH])
    );
  };

  const { statusDistributions, isStatusDistributionPending } = useSelector(
    getStatusDistributionResult
  );

  const totalMaliciousUrls =
    statusDistributions?.find(
      (element) => element.name === StatusValues.MALICIOUS
    )?.count || 0;

  const totalMaliciousUrlsUrl = getNewLocationUrl(urls.products_phishing_urls, {
    [urlsKeys.STATUS]: [StatusValues.MALICIOUS],
    [urlsKeys.START_DATE]: startDate,
    [urlsKeys.END_DATE]: endDate,
  });

  const formatters = useMemo(
    () => ({
      [urlsKeys.URL]: formatUrl,
      [urlsKeys.STATUS]: statusCell(t),
    }),
    [t]
  );

  return (
    <ProductCard dataTestId="phishing-product-card">
      <ProductCard.Header
        title={t("anti-phishing")}
        subTitle={slidesInfo[currentSlideIndex]?.subtitle}
        viewAllUrl={slidesInfo[currentSlideIndex]?.viewAllUrl}
      />
      <ProductCard.Carousel>
        <Carousel
          onChange={handleCarouselChange}
          name="urls-carousel"
          hasAutoplay
          isLoading={isStatusDistributionPending}
          items={[
            <StatusPieChart startDate={startDate} endDate={endDate} />,
            <Table
              name={"phishing-productcard-table"}
              columns={columns}
              rows={phishingUrls?.slice(0, ELEMENTS_PER_TABLE)}
              isLoading={isUrlsPending}
              error={urlsError}
              ghostRows={ELEMENTS_PER_TABLE}
              isFullWidth
              hasUnlimitedCellWidth
              onRowClick={handleRowClick}
              formatters={formatters}
              columnNameTransform={t}
              size="small"
            />,
          ]}
        />
      </ProductCard.Carousel>

      <ProductCard.Footer
        text={`${totalMaliciousUrls} Malicious URLs`}
        url={totalMaliciousUrlsUrl}
      />
    </ProductCard>
  );
}
