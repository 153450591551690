import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { ListResponseASNPreview } from "@csis.com/tip/src/api/openapi/data-contracts";
import { AsnEntry } from "./Asn";
import { fetchAsnsApi } from "./api/api";

interface StateSlice {
  asns: AsnEntry[];
  isPending: boolean;
  error: string | null;
}
const initialState: StateSlice = {
  asns: [],
  isPending: false,
  error: null,
};

const asnsSlice = createSlice({
  name: "asns",
  initialState: initialState,
  reducers: {
    fetchAsns(_state, _action: PayloadAction<string[]>) {
      //empty handled by saga
    },
    setFetchPending(state) {
      //empty handled by saga
      state.isPending = true;
      state.error = null;
    },
    setFetchError(state) {
      state.isPending = false;
      state.error = "Something went wrong";
    },
    fetchSuccess(state, action: PayloadAction<AsnEntry[]>) {
      state.asns = action.payload;
      state.isPending = false;
      state.error = null;
    },
  },
});

export default asnsSlice.reducer;

export const { fetchAsns, fetchSuccess, setFetchPending, setFetchError } =
  asnsSlice.actions;

// Async stuff - sagas

function* fetchAsnsSaga(action: PayloadAction<string[]>) {
  yield put(setFetchPending());
  const countries = action.payload;
  try {
    const response: AxiosResponse<ListResponseASNPreview> = yield call(
      fetchAsnsApi,
      countries
    );

    yield put(fetchSuccess(response.data.payload));
  } catch (e) {
    yield put(setFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchAsns.toString(), fetchAsnsSaga);
}

export function* phishingAsnsSagas() {
  yield all([actionWatcher()]);
}
