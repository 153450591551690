import { FC } from "react";
import { CodeBlock, Divider } from "@csis.com/components";
import { DocumentationCodeExample } from "../../DocumentationCodeExample/DocumentationCodeExample";
import {
  EndpointSection,
  EndpointSectionContent,
  EndpointSectionHeader,
} from "../../EnpointSection/EndpointSection";
import {
  QueryParamList,
  QueryParamListTerm,
  QueryParamListTermDetails,
} from "../../QueryParamList/QueryParamList";
import { ecrimeApiUrl } from "../../constants";
import { Subject } from "../Subject";
import { ENDPOINT_HEADING_LEVELS } from "../constants";

export const TicketsSubject: FC = () => {
  return (
    <Subject title="Tickets" id="tickets">
      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}tickets`}
          method="GET"
          title="List all Tickets"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="list-all-tickets"
        />

        <EndpointSectionContent>
          <p>Fetches the list of all tickets.</p>
          <QueryParamList>
            <QueryParamListTerm
              term="created_start"
              types={["String", "Date"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>
                Date in the form of “yyyy-mm-dd”. If specified, returns tickets
                created after <em>created_start</em> inclusive.
              </p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="created_end"
              types={["String", "Date"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>
                Date in the form of “yyyy-mm-dd”. If specified, returns tickets
                created before <em>created_end</em> exclusive.
              </p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="closed_start"
              types={["String", "Date"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>
                Date in the form of “yyyy-mm-dd”. If specified, returns tickets
                closed after <em>closed_start</em> inclusive.
              </p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="closed_end"
              types={["String", "Date"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>
                Date in the form of “yyyy-mm-dd”. Optional. If specified,
                returns tickets closed before <em>closed_end</em> exclusive.
              </p>
            </QueryParamListTermDetails>

            <QueryParamListTerm term="status" types={["String"]} isOptional />
            <QueryParamListTermDetails>
              <p>
                Ticket status. If specified, filters the ticket list by status.
                Status:{" "}
                <em>
                  new, in progress, pending customer, pending csis, confirmed,
                  closed.
                </em>
              </p>
            </QueryParamListTermDetails>

            <QueryParamListTerm term="type" types={["String"]} isOptional />
            <QueryParamListTermDetails>
              <p>
                Ticket type (name or full_name). If specified, filters the
                ticket list by type. Types:{" "}
                <span className="f_csis">
                  {`${ecrimeApiUrl}tickets/ticket-types`}
                </span>
              </p>
            </QueryParamListTermDetails>
          </QueryParamList>

          <ListAllTicketsExample />
        </EndpointSectionContent>
      </EndpointSection>

      <Divider />

      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}tickets/<ticket id>`}
          method="GET"
          title="View single Ticket"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="view-single-ticket"
        />

        <EndpointSectionContent>
          <p>Fetches a single ticket with the given id. </p>

          <ViewSingleTicketExample />
        </EndpointSectionContent>
      </EndpointSection>

      <Divider />

      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}tickets/<ticket id>/thread`}
          method="GET"
          title="Ticket Thread"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="ticket-thread"
        />

        <EndpointSectionContent>
          <p>Fetches the comment thread of the specified ticket. </p>

          <TicketThreadExample />
        </EndpointSectionContent>
      </EndpointSection>
    </Subject>
  );
};

const ListAllTicketsExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}tickets/`}
      />
    </DocumentationCodeExample>
  );
};

const ViewSingleTicketExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}tickets/123456789`}
      />

      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}tickets/123456789'
headers = {
    'Accept': 'application/json',
    'Authorization': 'Token <place-your-token-here>',
}
r = requests.get(url, headers=headers)
r.raise_for_status()
r.json()
`}
      />

      <h5 className="f_semibold">Sample response</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`{
    'alert_id': null,
    'closed': null,
    'analysis_id': null,
    'cirk_reference': null,
    'created': '2016-01-20T12:43:57Z',
    'creator': 'someone@example.com',
    'customer_reference': null,
    'description': '...',
    'id': 123456789,
    'status': 'New',
    'title': 'Forensics Ticket',
    'type': 'Forensics',
    'owners': ['Your Company'],
    'related_items': [{'type': 'cirk', 'id': 1234, 'title': '42-42-1'}]
}
`}
      />
    </DocumentationCodeExample>
  );
};

const TicketThreadExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}tickets/123456789/thread`}
      />

      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}tickets/123456789/thread'
headers = {
    'Accept': 'application/json',
    'Authorization': 'Token <place-your-token-here>',

}
r = requests.get(url, headers=headers)
r.raise_for_status()
r.json()`}
      />
      <h5 className="f_semibold">Sample response</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`[
    {
        'attachments': [],
        'comment': 'This is the first comment'
        'id': 987654321,
        'type': 'comment',
        'when': '2019-02-05T14:38:26Z',
        'who': 'user@login.com'
    },
    {
        'attachments': [],
        'comment': 'Another comment',
        'id': 987654322,
        'type': 'comment',
        'when': '2019-02-05T14:14:57Z',
        'who': 'another@user.dk'
    },
    {
        'attachments': [
            {
                'attachment_id': 4242,
                'filename': 'EvilFile.exe',
                'size': 97280
            }
        ],
        'comment': 'hi,\r\n\r\nHere's an attachment for you, please download',
        'id': 987654323,
        'type': 'comment',
        'when': '2019-01-31T14:44:33Z',
        'who': 'inves@tigat.or'
    }
]`}
      />
    </DocumentationCodeExample>
  );
};
