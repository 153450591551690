import { FC } from "react";
import { CodeBlock, Divider } from "@csis.com/components";
import { DocumentationCodeExample } from "../../DocumentationCodeExample/DocumentationCodeExample";
import {
  EndpointSection,
  EndpointSectionContent,
  EndpointSectionHeader,
} from "../../EnpointSection/EndpointSection";
import {
  QueryParamList,
  QueryParamListTerm,
  QueryParamListTermDetails,
} from "../../QueryParamList/QueryParamList";
import { ecrimeApiUrl } from "../../constants";
import { Subject } from "../Subject";
import { ENDPOINT_HEADING_LEVELS } from "../constants";

export const CompDataSubject: FC = () => {
  return (
    <Subject title="Compromised data" id="compromised-data">
      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}dropdata/payment-card/report`}
          method="GET"
          title="List compromised payment card reports"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="list-compromised-payment-card-reports"
        />

        <EndpointSectionContent>
          <p>
            Lists payment card reports that are matching the filters. The
            maximum number of reports is 10,000 per page. You will find
            pagination information in the "meta" object.
          </p>

          <QueryParamList>
            <QueryParamListTerm
              term="released-after"
              types={["String", "Date", "Datetime"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>Date (yyyy-mm-dd) or Datetime (yyyy-mm-dd hh:mm:ss).</p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="released-before"
              types={["String", "Date", "Datetime"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>Date (yyyy-mm-dd) or Datetime (yyyy-mm-dd hh:mm:ss).</p>
            </QueryParamListTermDetails>

            <QueryParamListTerm term="fields" types={["Keys"]} isOptional />
            <QueryParamListTermDetails>
              <p>
                {" "}
                List of keys that should be output, and not more; used to mask
                pii. Valid keys are:{" "}
                <em>
                  card_number, name, expiry_date, cvv, pin, stamp, customer,
                  customer_comment, id, external_id, released, risklevel,
                  source, variant, state, matcher
                </em>
              </p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="status-eq"
              types={["Integer"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <ol type="1" start={1}>
                <li>New</li>
                <li>Open</li>
                <li>Closed</li>
              </ol>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="id-after"
              types={["Integer"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>
                {" "}
                Only reports with an ID greater than <em>id-after</em> will be
                listed.
              </p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="per_page"
              types={["Integer"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>
                {" "}
                Defaults to the maximum of 10,000 reports per page. This
                parameter can be used to lower the number of listed reports.
              </p>
            </QueryParamListTermDetails>
          </QueryParamList>

          <ListCompCardReportExample />
        </EndpointSectionContent>
      </EndpointSection>

      <Divider />

      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}dropdata/credential/report`}
          method="GET"
          title="List compromised credential reports"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="list-compromised-credential-reports"
        />

        <EndpointSectionContent>
          <p>
            Lists credential reports that are matching the filters. The maximum
            number of reports is 10,000 per page. You will find pagination
            information in the "meta" object.
          </p>

          <QueryParamList>
            <QueryParamListTerm
              term="released-after"
              types={["String", "Date", "Datetime"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>Date (yyyy-mm-dd) or Datetime (yyyy-mm-dd hh:mm:ss).</p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="released-before"
              types={["String", "Date", "Datetime"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>Date (yyyy-mm-dd) or Datetime (yyyy-mm-dd hh:mm:ss).</p>
            </QueryParamListTermDetails>

            <QueryParamListTerm term="fields" types={["Keys"]} isOptional />
            <QueryParamListTermDetails>
              <p>
                List of keys that should be output, and not more; used to mask
                pii. Valid keys are:{" "}
                <em>
                  email, username, name, url, password, ip, phone_number,
                  address, user_agent, stamp, customer, customer_comment, id,
                  external_id, released, risklevel, source, variant, state,
                  matcher
                </em>
              </p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="status-eq"
              types={["Integer"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <ol type="1" start={1}>
                <li>New</li>
                <li>Open</li>
                <li>Closed</li>
              </ol>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="id-after"
              types={["Integer"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>
                Only reports with an ID greater than <em>id-after</em> will be
                listed.
              </p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="per_page"
              types={["Integer"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>
                Defaults to the maximum of 10,000 reports per page. This
                parameter can be used to lower the number of listed reports.
              </p>
            </QueryParamListTermDetails>
          </QueryParamList>

          <ListCompCredentialReportExample />
        </EndpointSectionContent>
      </EndpointSection>
    </Subject>
  );
};

const ListCompCardReportExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}dropdata/payment-card/report?per_page=1`}
      />

      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`import requests
base_url = '${ecrimeApiUrl}'
next_page = '/api/1.0/dropdata/payment-card/report?per_page=1'
headers = {
    'Accept': 'application/json',
    'Authorization': 'Token <place-your-token-here>',
}
while next_page:
    r = requests.get(base_url + next_page, headers=headers)
    r.raise_for_status()
    output = r.json()
    print(output['objects'])
    next_page = output['meta']['next']`}
      />

      <h5 className="f_semibold">Expected Output</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`{
  "objects": [
    {
      "card_number": "5555555534",
      "name": "John Doe",
      "expiry_date": "01/21",
      "cvv": "123",
      "variant": "MalwareXYZ",
      "pin": "1234",
      "stamp": "2020-01-01 00:00:00 GMT",
      "customer": "A-Test-BU",
      "customer_comment": "A Customer Comment",
      "id": 1003,
      "released": "2022-05-04 13:10:31 GMT",
      "risklevel": "Normal",
      "source": "DropdataDump_v46",
      "state": "New",
      "matcher": "Prefix: 555"
    }
  ],
  "meta": {
    "per_page": 10000,
    "first": "/api/1.0/dropdata/payment-card/report",
    "next": "/api/1.0/dropdata/payment-card/report?id-after=1003"
  }
}`}
      />
    </DocumentationCodeExample>
  );
};

const ListCompCredentialReportExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={` curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}dropdata/credential/report?per_page=1`}
      />

      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`import requests
base_url = '${ecrimeApiUrl}'
next_page = '/api/1.0/dropdata/credential/report?per_page=1'
headers = {
    'Accept': 'application/json',
    'Authorization': 'Token <place-your-token-here>',
}
while next_page:
    r = requests.get(base_url + next_page, headers=headers)
    r.raise_for_status()
    output = r.json()
    print(output['objects'])
    next_page = output['meta']['next']`}
      />

      <h5 className="f_semibold">Expected Output</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`{
  "objects": [
    {
      "email": "login@bank.com",
      "username": "john_doe_12",
      "name": "John Doe",
      "url": "https://login.bank.com",
      "password": "Passw0rd!",
      "ip": "10.1.2.3",
      "variant": "MalwareXYZ",
      "phone_number": "+45555555",
      "user_agent": "Firefox",
      "stamp": "2020-01-01 00:00:00 GMT",
      "customer": "CSIS Admin",
      "customer_comment": "A Customer Comment",
      "id": 1004,
      "released": "2022-05-04 13:21:32 GMT",
      "risklevel": "Normal",
      "source": "DropdataDump_v46",
      "state": "New",
      "matcher": "String: 'bank.com'"
    }
  ],
  "meta": {
    "per_page": 10000,
    "first": "/api/1.0/dropdata/credential/report",
    "next": "/api/1.0/dropdata/credential/report?id-after=1004"
  }
}`}
      />
    </DocumentationCodeExample>
  );
};
