import { History } from "history";

// given a url and wether the ctrl key is pressed or not,
// it either navigates to that or opens it in a new tab
export function navigateOrOpenNewTab(
  url: string,
  history: History<unknown>,
  isCtrlKeyPressed?: boolean
) {
  if (isCtrlKeyPressed) {
    window.open(url, "_blank");
  } else {
    history.push(url);
  }
}
