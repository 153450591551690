import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  addGroupByParamBasedOnRange,
  mapTicketsStartAndEndDateToCorrectType,
} from "@csis.com/tip/src/pages/Tickets/TicketsSearch/api/utils";
import { QueryParams } from "../../types";

export async function fetchTypeStatisticsApi(
  queryParams: Partial<QueryParams>
) {
  const params = mapTicketsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getStatisticsTypeApi10TicketStatisticsTypeGet(params);
}

export async function fetchTypeStatisticsHistogramApi(
  queryParams: Partial<QueryParams>
) {
  const params = addGroupByParamBasedOnRange(
    mapTicketsStartAndEndDateToCorrectType(queryParams)
  );

  return axiosCsisApi.getStatisticsTypeHistogramApi10TicketStatisticsTypeHistogramGet(
    params
  );
}
