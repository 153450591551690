import { createSelector } from "@reduxjs/toolkit";
import { getNewColumnsBasedOnSort } from "@csis.com/tip/src/components/shared/DataTableContainer/utils";
import { RootState } from "@csis.com/tip/src/configureStore";
import { VictimsColumns } from "./Table/columns";

export const getCompromisedData = (state: RootState) =>
  state.compromisedDataVictimsInfoReducer.compromisedData;

export const getIsCompromisedDataPending = (state: RootState) =>
  state.compromisedDataVictimsInfoReducer.isCompromisedDataPending;

export const getCompromisedDataFetchError = (state: RootState) =>
  state.compromisedDataVictimsInfoReducer.compromisedDataFetchError;

export const getCompromisedDataResult = createSelector(
  [
    getCompromisedData,
    getIsCompromisedDataPending,
    getCompromisedDataFetchError,
  ],
  (compromisedData, isCompromisedDataPending, compromisedDataFetchError) => {
    return {
      compromisedData,
      isCompromisedDataPending,
      compromisedDataFetchError,
    };
  }
);

export const getCompromisedDataColumns = (state: RootState) =>
  state.compromisedDataVictimsInfoReducer.columns;

export const getCalculatedColumns = createSelector(
  [getCompromisedDataColumns, (_: RootState, sort?: string) => sort],
  (columns, sort) => {
    return getNewColumnsBasedOnSort(columns, sort);
  }
  // we need to explicitly type it here so typescript doesnt show false warning
) as (state: RootState, sort?: string) => VictimsColumns;

export const getHasNextPage = (state: RootState) =>
  state.compromisedDataVictimsInfoReducer.hasNextPage;

export const getIsCsvExportPending = (state: RootState) =>
  state.compromisedDataVictimsInfoReducer.isCsvExportPending;

export const getBulkActionSuccess = (state: RootState) =>
  state.compromisedDataVictimsInfoReducer.bulkUpdateSuccess;

export const getBulkActionError = (state: RootState) =>
  state.compromisedDataVictimsInfoReducer.bulkUpdateError;

export const getIsBulkActionPending = (state: RootState) =>
  state.compromisedDataVictimsInfoReducer.isBulkUpdatePending;
