import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { QueryParams } from "../types";

export async function fetchPortalNewsApi(queryParams: Partial<QueryParams>) {
  return await axiosCsisApi.getPortalNewsApi10PortalNewsGet(
    queryParams,
    STRINGIFY_QUERY_PARAMS
  );
}

export async function postPortalNewsReadApi() {
  return await axiosCsisApi.setLastReadApi10PortalNewsLastReadPost();
}
