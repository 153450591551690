import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { ListResponseBrandPreview } from "@csis.com/tip/src/api/openapi/data-contracts";
import { BrandEntry } from "./Brand";
import { fetchBrandsApi } from "./api/api";

interface StateSlice {
  brands: BrandEntry[];
  isPending: boolean;
  error: string | null;
}
const initialState: StateSlice = {
  brands: [],
  isPending: false,
  error: null,
};

const brandsSlice = createSlice({
  name: "brands",
  initialState: initialState,
  reducers: {
    fetchBrands() {
      //empty handled by saga
    },
    setFetchPending(state) {
      //empty handled by saga
      state.isPending = true;
      state.error = null;
    },
    setFetchError(state) {
      state.isPending = false;
      state.error = "Something went wrong";
    },
    fetchSuccess(state, action: PayloadAction<BrandEntry[]>) {
      state.brands = action.payload;
      state.isPending = false;
      state.error = null;
    },
  },
});

export default brandsSlice.reducer;

export const { fetchBrands, fetchSuccess, setFetchPending, setFetchError } =
  brandsSlice.actions;

// Async stuff - sagas

function* fetchBrandsSaga() {
  yield put(setFetchPending());
  try {
    const response: AxiosResponse<ListResponseBrandPreview> = yield call(
      fetchBrandsApi
    );

    yield put(fetchSuccess(response.data.payload));
  } catch (e) {
    yield put(setFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchBrands.toString(), fetchBrandsSaga);
}

export function* phishingBrandsSagas() {
  yield all([actionWatcher()]);
}
