import classNames from "classnames";
import React, { useState } from "react";
import IconButton from "../IconButton/IconButton";

export interface CollapsablePanelSidebarInterface {
  isCollapsed?: boolean;
  isSticky?: boolean;
}

const CollapsablePanelSidebar: React.FC<CollapsablePanelSidebarInterface> = ({
  isCollapsed,
  isSticky,
  children,
}) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(
    Boolean(isCollapsed)
  );

  const classes = classNames("collapsable-panel__sidebar", {
    "collapsable-panel__sidebar--collapsed": isSidebarCollapsed,
  });

  return (
    <div className={classes}>
      <div
        className={
          "collapsable-panel__sidebar__group" + (isSticky ? "--sticky" : "")
        }
      >
        <div className="collapsable-panel__sidebar__slide-button">
          <IconButton
            type="text"
            size="large"
            isCircle
            spacing="normal"
            icon={"menu_collapsed"}
            onButtonClick={() => {
              setIsSidebarCollapsed(!isSidebarCollapsed);
            }}
            tooltipText="Sliding Menu"
          />
        </div>
        <div className="collapsable-panel__sidebar__elements">{children}</div>
      </div>
    </div>
  );
};

export default CollapsablePanelSidebar;
