export const API_ENDPOINTS = {
  //Investigations
  investigation: `/1.0/investigation/entity`,
  entitytype: "/1.0/investigation/entity-type",

  //Remote Forensics
  remoteforensics: "/1.0/remote-forensics/",
  remoteforensics_id_subpage: (id: string, subpage?: string) =>
    subpage
      ? `/1.0/remote-forensics/${id}/${subpage}`
      : `/1.0/remote-forensics/${id}`,

  //MDR
  //MDFE Onboarding
  onboarding_mdfecustomer_id: (id: string) =>
    `/1.0/onboarding/mdfe-customer/${id}`,
} as const;

export type AllowedEndpoint = typeof API_ENDPOINTS[keyof typeof API_ENDPOINTS];
