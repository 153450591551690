import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { predefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";

export const ccKeys = {
  ID: "id",
  DISPLAY_ID: "display_id",
  CARD_NUMBER: "card_number",
  NAME: "name",
  RELEASED: "released",
  SUBMITTED: "submitted",
  RESOLVED: "resolved",
  STAMP: "stamp",
  STATUS: "status",
  SOURCE: "source",
  VARIANT: "variant",
  MATCHER: "identifier",
  RELEASED_BEFORE: "released_before",
  RELEASED_AFTER: "released_after",
  // keys used in all search pages:
  ...searchKeys,
} as const;

export const filterKeys = [
  ccKeys.STATUS,
  ccKeys.SOURCE,
  ccKeys.VARIANT,
] as const;

export const DEFAULT_RESULTS_PER_PAGE = predefinedResultsPerPage.TEN;
