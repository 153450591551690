import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { PortalsFrontendApiReportingDeprecatedModelReportRequest as ReportRequest } from "../../../api/openapi/data-contracts";
import { QueryParams } from "../types";
import { mapReportsStartAndEndDateToCorrectType } from "./utils";

export async function fetchReportsApi(queryParams: Partial<QueryParams>) {
  const query = mapReportsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getReportsApi10ReportGet(query, STRINGIFY_QUERY_PARAMS);
}

export async function downloadReportApi(id: string) {
  return axiosCsisApi.downloadLatestReportApi10ReportReportExternalIdDownloadGet(
    { reportExternalId: id },
    {
      format: "blob",
    }
  );
}

export async function generateReportApi(newReport: ReportRequest) {
  return axiosCsisApi.generateReportApi10ReportPost({}, newReport);
}
