import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import {
  deleteEmergencyCaseAttachmentAPI,
  fetchAttachmentAPI,
  fetchEmergencyCaseByIdAPI,
  updateEmergencyCaseAPI,
  updateEmergencyCaseAttachmentsAPI,
} from "./api/api";
import { EmergencyCaseResponse, EmergencyCaseUpdateBody } from "./api/types";
import { EmergencyCase, EmergencyCaseAttachment } from "./types";

interface StateSlice {
  emergencyCase: EmergencyCase | null;
  isEmergencyCasePending: boolean;
  emergencyCaseFetchError: string | null;

  isEmergencyCaseUpdating: boolean;
  emergencyCaseUpdateError: string | null;
  emergencyCaseUpdateSuccess: boolean;

  isEmergencyCaseAttachmentUpdating: boolean;
  emergencyCaseAttachmentUpdateError: string | null;
  emergencyCaseAttachmentUpdateSuccess: boolean;

  isEmergencyCaseAttachmentDeleting: boolean;
  emergencyCaseAttachmentDeleteError: string | null;
  emergencyCaseAttachmentDeleteSuccess: boolean;
}

const initialState: StateSlice = {
  emergencyCase: null,
  isEmergencyCasePending: false,
  emergencyCaseFetchError: null,

  isEmergencyCaseUpdating: false,
  emergencyCaseUpdateError: null,
  emergencyCaseUpdateSuccess: false,

  isEmergencyCaseAttachmentUpdating: false,
  emergencyCaseAttachmentUpdateError: null,
  emergencyCaseAttachmentUpdateSuccess: false,

  isEmergencyCaseAttachmentDeleting: false,
  emergencyCaseAttachmentDeleteError: null,
  emergencyCaseAttachmentDeleteSuccess: false,
};

const emergencyCaseSlice = createSlice({
  name: "emergencyCase",
  initialState: initialState,
  reducers: {
    fetchEmergencyCaseById(_state, _action: PayloadAction<string>) {
      //empty handled by saga
    },
    setEmergencyCasePending(state) {
      state.isEmergencyCasePending = true;
      state.emergencyCaseFetchError = null;
    },
    setFetchEmergencyCaseError(state, action: PayloadAction<string>) {
      state.isEmergencyCasePending = false;
      state.emergencyCaseFetchError = action.payload;
      state.emergencyCase = null;
    },
    fetchEmergencyCaseSuccess(state, action: PayloadAction<EmergencyCase>) {
      state.isEmergencyCasePending = false;
      state.emergencyCaseFetchError = null;
      state.emergencyCase = action.payload;
    },
    resetEmergencyCaseState(state) {
      state.emergencyCase = null;
      state.isEmergencyCasePending = false;
      state.emergencyCaseFetchError = null;
    },
    updateEmergencyCase(
      _state,
      _action: PayloadAction<{
        caseId: string;
        case: EmergencyCaseUpdateBody;
      }>
    ) {
      //empty handled by saga
    },
    setEmergencyCaseUpdating(state) {
      state.isEmergencyCaseUpdating = true;
      state.emergencyCaseUpdateError = null;
      state.emergencyCaseUpdateSuccess = false;
    },
    setUpdateEmergencyCaseError(state, action: PayloadAction<string>) {
      state.isEmergencyCaseUpdating = false;
      state.emergencyCaseUpdateError = action.payload;
    },
    setUpdateEmergencyCaseSuccess(state, action: PayloadAction<EmergencyCase>) {
      state.isEmergencyCaseUpdating = false;
      state.emergencyCaseFetchError = null;
      state.emergencyCaseUpdateSuccess = true;
      state.emergencyCase = { ...state.emergencyCase, ...action.payload };
    },
    resetEmergencyCaseUpdateState(state) {
      state.isEmergencyCaseUpdating = false;
      state.emergencyCaseUpdateError = null;
      state.emergencyCaseUpdateSuccess = false;
    },
    deleteEmergencyCaseAttachmentById(
      _state,
      _action: PayloadAction<{ caseId: string; attachmentId: string }>
    ) {
      //empty handled by saga
    },
    setDeleteEmergencyCaseAttachmentPending(state) {
      state.isEmergencyCaseAttachmentDeleting = true;
      state.emergencyCaseAttachmentDeleteError = null;
      state.emergencyCaseAttachmentDeleteSuccess = false;
    },
    setDeleteEmergencyCaseAttachmentError(
      state,
      action: PayloadAction<string>
    ) {
      state.isEmergencyCaseAttachmentDeleting = false;
      state.emergencyCaseAttachmentDeleteError = action.payload;
    },
    setDeleteEmergencyCaseAttachmentSuccess(
      state,
      action: PayloadAction<string>
    ) {
      state.isEmergencyCaseAttachmentDeleting = false;
      state.emergencyCaseAttachmentDeleteError = null;
      state.emergencyCaseAttachmentDeleteSuccess = true;
      state.emergencyCase = {
        ...state.emergencyCase,
        attachments: state.emergencyCase?.attachments.length
          ? state.emergencyCase?.attachments.filter(
              (item) => item.external_id !== action.payload
            )
          : [],
      } as EmergencyCase;
    },
    resetDeleteEmergencyCaseAttachmentState(state) {
      state.isEmergencyCaseAttachmentDeleting = false;
      state.emergencyCaseAttachmentDeleteError = null;
      state.emergencyCaseAttachmentDeleteSuccess = false;
    },
    updateEmergencyCaseAttachments(
      _state,
      _action: PayloadAction<{ caseId: string; files: FormData }>
    ) {
      //empty handled by saga
    },
    setUpdateEmergencyCaseAttachmentsPending(state) {
      state.isEmergencyCaseAttachmentUpdating = true;
      state.emergencyCaseAttachmentUpdateError = null;
      state.emergencyCaseAttachmentUpdateSuccess = false;
    },
    setUpdateEmergencyCaseAttachmentsError(
      state,
      action: PayloadAction<string>
    ) {
      state.isEmergencyCaseAttachmentUpdating = false;
      state.emergencyCaseAttachmentUpdateError = action.payload;
      state.emergencyCaseAttachmentUpdateSuccess = false;
    },
    setUpdateEmergencyCaseAttachmentsSuccess(
      state,
      action: PayloadAction<EmergencyCaseAttachment[]>
    ) {
      state.isEmergencyCaseAttachmentUpdating = false;
      state.emergencyCaseAttachmentUpdateError = null;
      state.emergencyCaseAttachmentUpdateSuccess = true;
      state.emergencyCase = {
        ...state.emergencyCase,
        attachments: [
          ...(state.emergencyCase?.attachments || []),
          ...action.payload,
        ],
      } as EmergencyCase;
    },
    resetUpdateEmergencyCaseAttachmentsState(state) {
      state.isEmergencyCaseAttachmentUpdating = false;
      state.emergencyCaseAttachmentUpdateError = null;
      state.emergencyCaseAttachmentUpdateSuccess = false;
    },
    downloadAttachment(
      _state,
      _action: PayloadAction<{
        emergencyCaseId: string;
        attachmentId: string;
        filename: string;
      }>
    ) {
      //empty handled by saga
    },
  },
});

export const {
  fetchEmergencyCaseById,
  setEmergencyCasePending,
  setFetchEmergencyCaseError,
  fetchEmergencyCaseSuccess,
  resetEmergencyCaseState,
  updateEmergencyCase,
  setEmergencyCaseUpdating,
  setUpdateEmergencyCaseError,
  setUpdateEmergencyCaseSuccess,
  resetEmergencyCaseUpdateState,
  deleteEmergencyCaseAttachmentById,
  setDeleteEmergencyCaseAttachmentPending,
  setDeleteEmergencyCaseAttachmentError,
  setDeleteEmergencyCaseAttachmentSuccess,
  resetDeleteEmergencyCaseAttachmentState,
  updateEmergencyCaseAttachments,
  setUpdateEmergencyCaseAttachmentsPending,
  setUpdateEmergencyCaseAttachmentsError,
  setUpdateEmergencyCaseAttachmentsSuccess,
  resetUpdateEmergencyCaseAttachmentsState,
  downloadAttachment,
} = emergencyCaseSlice.actions;

export default emergencyCaseSlice.reducer;

function* fetchEmergencyCaseByIdSaga(action: PayloadAction<string>) {
  yield put(setEmergencyCasePending());
  try {
    const response: AxiosResponse<EmergencyCaseResponse> = yield call(
      fetchEmergencyCaseByIdAPI,
      action.payload
    );
    yield put(fetchEmergencyCaseSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchEmergencyCaseError(errorMessage));
  }
}

function* updateEmergencyCaseSaga(
  action: PayloadAction<{
    caseId: string;
    case: EmergencyCaseUpdateBody;
  }>
) {
  yield put(setEmergencyCaseUpdating());
  try {
    const response: AxiosResponse<EmergencyCaseResponse> = yield call(
      updateEmergencyCaseAPI,
      action.payload.caseId,
      action.payload.case
    );
    yield put(setUpdateEmergencyCaseSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setUpdateEmergencyCaseError(errorMessage));
  }
}

function* downloadAttachmentSaga(
  action: PayloadAction<{
    emergencyCaseId: string;
    attachmentId: string;
    filename: string;
  }>
) {
  try {
    const response: AxiosResponse<Blob> = yield call(
      fetchAttachmentAPI,
      action.payload.emergencyCaseId,
      action.payload.attachmentId
    );

    const blob = response.data;
    downloadBlobForUser(blob, action.payload.filename);
  } catch (e) {}
}

function* deleteEmergencyCaseAttachmentSaga(
  action: PayloadAction<{ caseId: string; attachmentId: string }>
) {
  yield put(setDeleteEmergencyCaseAttachmentPending());
  try {
    yield call(
      deleteEmergencyCaseAttachmentAPI,
      action.payload.caseId,
      action.payload.attachmentId
    );
    yield put(
      setDeleteEmergencyCaseAttachmentSuccess(action.payload.attachmentId)
    );
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setDeleteEmergencyCaseAttachmentError(errorMessage));
  }
}

function* updateEmergencyCaseAttachmentSaga(
  action: PayloadAction<{ caseId: string; files: FormData }>
) {
  yield put(setUpdateEmergencyCaseAttachmentsPending());
  try {
    const response: AxiosResponse<any> = yield call(
      updateEmergencyCaseAttachmentsAPI,
      action.payload.caseId,
      action.payload.files
    );
    yield put(setUpdateEmergencyCaseAttachmentsSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setUpdateEmergencyCaseAttachmentsError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchEmergencyCaseById.toString(),
    fetchEmergencyCaseByIdSaga
  );
  yield takeLatest(updateEmergencyCase.toString(), updateEmergencyCaseSaga);
  yield takeLatest(downloadAttachment.toString(), downloadAttachmentSaga);
  yield takeLatest(
    deleteEmergencyCaseAttachmentById.toString(),
    deleteEmergencyCaseAttachmentSaga
  );
  yield takeLatest(
    updateEmergencyCaseAttachments.toString(),
    updateEmergencyCaseAttachmentSaga
  );
}

export function* emergencyCaseSaga() {
  yield all([actionWatcher()]);
}
