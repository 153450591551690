import { createSelector } from "@reduxjs/toolkit";
import { getNewColumnsBasedOnSort } from "@csis.com/tip/src/components/shared/DataTableContainer/utils";
import { RootState } from "@csis.com/tip/src/configureStore";
import { EmergencyCasesColumns } from "./Table/columns";

export const getEmergencyCases = (state: RootState) =>
  state.emergencyCasesReducer.emergencyCases;

export const getIsEmergencyCasesPending = (state: RootState) =>
  state.emergencyCasesReducer.isEmergencyCasesPending;

export const getEmergencyCasesFetchError = (state: RootState) =>
  state.emergencyCasesReducer.emergencyCasesFetchError;

export const getEmergencyCasesResult = createSelector(
  [getEmergencyCases, getIsEmergencyCasesPending, getEmergencyCasesFetchError],
  (emergencyCases, isEmergencyCasesPending, emergencyCasesFetchError) => {
    return {
      emergencyCases,
      isEmergencyCasesPending,
      emergencyCasesFetchError,
    };
  }
);

export const getEmergencyCasesColumns = (state: RootState) =>
  state.emergencyCasesReducer.columns;

export const getCalculatedColumns = createSelector(
  [getEmergencyCasesColumns, (_: RootState, sort?: string) => sort],
  (columns, sort) => {
    return getNewColumnsBasedOnSort(columns, sort);
  }
  // we need to explicitly type it here so typescript doesnt show false warning
) as (state: RootState, sort?: string) => EmergencyCasesColumns;

export const getHasNextPage = (state: RootState) =>
  state.emergencyCasesReducer.hasNextPage;

export const getAdminUsers = (state: RootState) =>
  state.emergencyCasesReducer.adminUsers;

export const getIsAdminUsersPending = (state: RootState) =>
  state.emergencyCasesReducer.isAdminUsersPending;

export const getFetchAdminUsersError = (state: RootState) =>
  state.emergencyCasesReducer.fetchAdminUsersError;

export const getAdminUsersResult = createSelector(
  [getAdminUsers, getIsAdminUsersPending, getFetchAdminUsersError],
  (responsibleUsers, isResponsibleUsersPending, fetchResponsibleUsersError) => {
    return {
      responsibleUsers,
      isResponsibleUsersPending,
      fetchResponsibleUsersError,
    };
  }
);
