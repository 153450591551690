import { History } from "history";
import * as qs from "query-string";
import { useHistory } from "react-router-dom";

export const stringifyParams = (queryParams: {}) =>
  qs.stringify(queryParams, { arrayFormat: "index" });

export const getNewLocationUrl = (url: string, queryParams: {}) =>
  url + "?" + stringifyParams(queryParams);

function updateLocationWithHistoryProvided(history: History<unknown>) {
  return function updateLocationWithParams(url: string, queryParams: {}) {
    const newLocationURL = getNewLocationUrl(url, queryParams);
    history.push(newLocationURL);
  };
}

export function useUpdateLocationWithParams() {
  const history = useHistory();

  // return a partial function that is called already with history
  // so it needs only url and query params
  return updateLocationWithHistoryProvided(history);
}
