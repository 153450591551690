/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, GridItem, Stack, Toast } from "@csis.com/components";
import { DateRange } from "@csis.com/components/src/atoms/DatePicker/types";
import Page from "@csis.com/tip/src/components/shared/Page/Page";
import { getUserSubscribedProducts } from "@csis.com/tip/src/pages/Profile/Security/selectors";
import { NewTicketDialog } from "@csis.com/tip/src/pages/Tickets/NewTicketDialog/NewTicketDialog";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import {
  getIsoDateString,
  getOneMonthAgoAsDateIsoString,
  getStartAndEndDate,
  getTomorrowMidnightAsDateIsoString,
} from "@csis.com/tip/src/utils/dateCalculations";
import ArticlesOverviewCard from "./Overview/ArticlesOverviewCard/ArticlesOverviewCard";
import TicketsOverviewCard from "./Overview/TicketsOverviewCard/TicketsOverviewCard";
import OverviewTopBar from "./Overview/TopBar/TopBar";
import AlertsProductCard from "./ProductCards/AlertsProductCard";
import CardsProductCard from "./ProductCards/CardsProductCard";
import CredentialsProductCard from "./ProductCards/CredentialsProductCard";
import ForensicsProductCard from "./ProductCards/ForensicsProductCard";
import PhishingProductCard from "./ProductCards/PhishingProductCard";
import TicketsProductCard from "./ProductCards/TicketsProductCard";

function LandingPage() {
  const { t } = useTranslations();

  const [overviewStartDate, setOverviewStartDate] = useState<
    string | undefined
  >(getOneMonthAgoAsDateIsoString());
  const [overviewEndDate, setOverviewEndDate] = useState<string | undefined>(
    getTomorrowMidnightAsDateIsoString()
  );

  const userSubscribedProducts = useSelector(getUserSubscribedProducts);

  const handleOverviewDateRangeSelected = (
    value: Date | DateRange | undefined
  ) => {
    const [startDate, endDate] = getStartAndEndDate(value);
    setOverviewStartDate(startDate ? getIsoDateString(startDate) : undefined);
    setOverviewEndDate(endDate ? getIsoDateString(endDate) : undefined);
  };

  const [isNewTicketDialogShown, setNewTicketDialogShown] =
    useState<boolean>(false);

  const [isNewTicketCreatedSuccessfully, setIsNewTicketCreatedSuccessfully] =
    useState<boolean>(false);

  const handleCreateTicketSuccess = useCallback(() => {
    setNewTicketDialogShown(false);
    setIsNewTicketCreatedSuccessfully(true);
  }, []);

  return (
    <Page classes="landing-page">
      <Stack isVertical align="stretch" gutterSize="none">
        <Grid gutterSize="huge">
          <GridItem span={12} responsiveSize="large">
            <OverviewTopBar
              startDate={overviewStartDate}
              endDate={overviewEndDate}
              onDateRangeSelected={handleOverviewDateRangeSelected}
              setNewTicketDialogShown={setNewTicketDialogShown}
            />
          </GridItem>
        </Grid>
        <Grid gutterSize="huge">
          <GridItem
            span={userSubscribedProducts?.articles ? 6 : 12}
            responsiveSize="large"
          >
            <TicketsOverviewCard
              startDate={overviewStartDate}
              endDate={overviewEndDate}
            />
          </GridItem>
          {userSubscribedProducts?.articles && (
            <GridItem span={6} responsiveSize="large">
              {userSubscribedProducts?.articles && (
                <ArticlesOverviewCard
                  startDate={overviewStartDate}
                  endDate={overviewEndDate}
                />
              )}
            </GridItem>
          )}
        </Grid>
        <Grid gutterSize="huge">
          <GridItem span={6} responsiveSize="large">
            <TicketsProductCard
              startDate={overviewStartDate}
              endDate={overviewEndDate}
            />
          </GridItem>
          <GridItem span={6} responsiveSize="large">
            <AlertsProductCard
              startDate={overviewStartDate}
              endDate={overviewEndDate}
            />
          </GridItem>
        </Grid>

        {userSubscribedProducts?.straydata && (
          <Grid gutterSize="huge">
            <GridItem span={6} responsiveSize="large">
              <CardsProductCard
                startDate={overviewStartDate}
                endDate={overviewEndDate}
              />
            </GridItem>
            <GridItem span={6} responsiveSize="large">
              <CredentialsProductCard
                startDate={overviewStartDate}
                endDate={overviewEndDate}
              />
            </GridItem>
          </Grid>
        )}
        <Grid gutterSize="huge">
          {userSubscribedProducts?.cirk && (
            <GridItem
              span={userSubscribedProducts?.phishing ? 6 : 12}
              responsiveSize="large"
            >
              <ForensicsProductCard
                startDate={overviewStartDate}
                endDate={overviewEndDate}
              />
            </GridItem>
          )}
          {userSubscribedProducts?.phishing && (
            <GridItem
              span={userSubscribedProducts?.cirk ? 6 : 12}
              responsiveSize="large"
            >
              <PhishingProductCard
                startDate={overviewStartDate}
                endDate={overviewEndDate}
              />
            </GridItem>
          )}
        </Grid>
      </Stack>
      {isNewTicketDialogShown && (
        <NewTicketDialog
          setNewTicketDialogShown={setNewTicketDialogShown}
          onSuccess={handleCreateTicketSuccess}
        />
      )}
      <Toast
        isShown={isNewTicketCreatedSuccessfully}
        onHide={() => setIsNewTicketCreatedSuccessfully(false)}
        icon="check"
        text={t("ticket_successfully_created")}
      />
    </Page>
  );
}

export default LandingPage;
