import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link, Stack, Table } from "@csis.com/components";
import { OnRowClick } from "@csis.com/components/src/atoms/Table/types";
import { StatisticsCount } from "@csis.com/tip/src/api/openapi/data-contracts";
import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { getSeveritiesDistributionResult as getTicketsSeveritiesDistributionResult } from "@csis.com/tip/src/pages/Tickets/Statistics/Severity/selectors";
import { fetchSeveritiesDistribution as fetchTicketsSeveritiesDistribution } from "@csis.com/tip/src/pages/Tickets/Statistics/Severity/slice";
import { getStatusDistributionResult as getTicketsStatusDistributionResult } from "@csis.com/tip/src/pages/Tickets/Statistics/Status/selectors";
import { fetchStatusDistribution } from "@csis.com/tip/src/pages/Tickets/Statistics/Status/slice";
import { SeveritiesTotalEntry } from "@csis.com/tip/src/pages/Tickets/Statistics/types";
import { ticketsKeys } from "@csis.com/tip/src/pages/Tickets/TicketsSearch/constants";
import { SeverityValues } from "@csis.com/tip/src/pages/Tickets/models/Severity";
import { StatusValues as TicketsStatusValues } from "@csis.com/tip/src/pages/Tickets/models/Status";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { getNewLocationUrl } from "@csis.com/tip/src/utils/updateLocationWithParams";
import { ticketsCountCell, ticketsSeverityCell } from "../tableFormatters";

export default function TicketsOverviewCard({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslations();

  const { statusDistributions: ticketsStatusDistributions } = useSelector(
    getTicketsStatusDistributionResult
  );
  const {
    severitiesDistributions: ticketsSeveritiesDistributions,
    isSeveritiesDistributionPending,
  } = useSelector(getTicketsSeveritiesDistributionResult);

  // map the ticketsSeveritiesDistributions to group low, info and false positive together
  // also it adds their count to the other group
  const groupedTicketsSeveritiesDistributions: StatisticsCount[] =
    useMemo(() => {
      const groupedSeverities: StatisticsCount[] = [];
      const otherSeverities: StatisticsCount[] = [];

      ticketsSeveritiesDistributions.forEach((severity) => {
        if (
          severity.name === SeverityValues.NA ||
          severity.name === SeverityValues.INFO ||
          severity.name === SeverityValues.FALSE_POSITIVE
        ) {
          otherSeverities.push(severity);
        } else {
          groupedSeverities.push(severity);
        }
      });

      const totalOtherSeverities: StatisticsCount = {
        name: "other",
        count: 0,
      };

      otherSeverities.forEach((severity) => {
        totalOtherSeverities.count += severity.count;
      });

      groupedSeverities.push(totalOtherSeverities);

      return groupedSeverities;
    }, [ticketsSeveritiesDistributions]);

  useEffect(() => {
    const queryParams = {
      [searchKeys.START_DATE]: startDate,
      [searchKeys.END_DATE]: endDate,
    };

    dispatch(fetchTicketsSeveritiesDistribution(queryParams));
    dispatch(fetchStatusDistribution(queryParams));
  }, [dispatch, endDate, startDate]);

  const totalTicketsNew =
    ticketsStatusDistributions?.find(
      (element) => element.name === TicketsStatusValues.NEW
    )?.count || 0;

  const totalTicketsAwaitingReply =
    ticketsStatusDistributions?.find(
      (element) => element.name === TicketsStatusValues.PENDING_CUSTOMER
    )?.count || 0;

  const totalTicketsAwaitingCsisReply =
    ticketsStatusDistributions?.find(
      (element) => element.name === TicketsStatusValues.PENDING_CSIS
    )?.count || 0;

  const totalTicketsConfirmed =
    ticketsStatusDistributions?.find(
      (element) => element.name === TicketsStatusValues.CONFIRMED
    )?.count || 0;

  const ticketsFormatters = useMemo(
    () => ({
      count: ticketsCountCell,
      name: ticketsSeverityCell(t),
    }),
    [t]
  );

  const handleRowClick: OnRowClick<SeveritiesTotalEntry> = useCallback(
    (row) => {
      const severity = row.name;
      const severtyParams =
        severity === "other"
          ? [
              SeverityValues.INFO,
              SeverityValues.FALSE_POSITIVE,
              SeverityValues.NA,
            ]
          : [severity];

      const ticketsSeveritySelectedUrl = getNewLocationUrl(
        urls.tickets_search,
        {
          [ticketsKeys.SEVERITY]: severtyParams,
          [ticketsKeys.START_DATE]: startDate,
          [ticketsKeys.END_DATE]: endDate,
        }
      );

      history.push(ticketsSeveritySelectedUrl);
    },
    [endDate, history, startDate]
  );

  const ticketsOpenUrl = getNewLocationUrl(urls.tickets_search, {
    [ticketsKeys.STATUS]: [
      TicketsStatusValues.NEW,
      TicketsStatusValues.PENDING_CUSTOMER,
      TicketsStatusValues.PENDING_CSIS,
      TicketsStatusValues.CONFIRMED,
    ],
    [ticketsKeys.START_DATE]: startDate,
    [ticketsKeys.END_DATE]: endDate,
  });

  const totalOpenTickets =
    totalTicketsNew +
    totalTicketsAwaitingReply +
    totalTicketsAwaitingCsisReply +
    totalTicketsConfirmed;

  return (
    <div className="landing-page__tickets-overview">
      <Stack gutterSize="large" align="center">
        <div className="landing-page__tickets-overview__total">
          <Link href={ticketsOpenUrl} isFullWidth>
            <Stack isVertical align="center">
              <div className="f_gigantic f_semibold">{totalOpenTickets}</div>
              <div
                className="f_semibold f_uppercase"
                title="Tickets that are new, pending or confirmed"
              >
                {t("open_tickets")}
              </div>
            </Stack>
          </Link>
        </div>
        {(groupedTicketsSeveritiesDistributions.length > 0 ||
          groupedTicketsSeveritiesDistributions) && (
          <Table
            name="product-overview-table"
            hasHeader={false}
            size="small"
            isBorderless
            rows={groupedTicketsSeveritiesDistributions}
            onRowClick={handleRowClick}
            isLoading={isSeveritiesDistributionPending}
            ghostRows={5}
            columns={[
              { key: "count", name: "count", isRightAligned: true },
              { key: "name", name: "name" },
            ]}
            formatters={ticketsFormatters}
            dataTestId={"tickets-overview-table"}
          />
        )}
      </Stack>
    </div>
  );
}
