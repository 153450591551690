import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Form,
  GenericFormError,
  InputFile,
  Stack,
  Toast,
} from "@csis.com/components";
import {
  getDeleteRetainerAttachmentResult,
  getRetainer,
  getUpdateRetainerAttachmentResult,
} from "../selectors";
import {
  deleteRetainerAttachmentById,
  resetDeleteRetainerAttachmentState,
  updateRetainerAttachmentsById,
} from "../slice";
import { getRetainerAttachments } from "../utils";

const EditAttachmentsDialog = ({
  retainerId,
  onDialogClose,
  onSuccess,
}: {
  retainerId: string;
  onDialogClose: () => void;
  onSuccess: () => void;
}) => {
  const dispatch = useDispatch();
  const retainer = useSelector(getRetainer);
  const [isAttachmentsActionCompleted, setIsAttachmentsActionCompleted] =
    useState<boolean>(false);
  const [actionCompleteText, setActionCompleteText] = useState<string>("");

  const {
    retainerAttachmentUpdateSuccess,
    isRetainerAttachmentUpdating,
    retainerAttachmentUpdateError,
  } = useSelector(getUpdateRetainerAttachmentResult);
  const {
    retainerAttachmentDeleteSuccess,
    isRetainerAttachmentDeleting,
    retainerAttachmentDeleteError,
  } = useSelector(getDeleteRetainerAttachmentResult);

  const attachments = useMemo(() => {
    return getRetainerAttachments(retainer?.attachments);
  }, [retainer?.attachments]);

  const handleFileRemove = (fileId: string) => {
    dispatch(
      deleteRetainerAttachmentById({
        retainerId: retainer?.external_id || "",
        attachmentId: fileId,
      })
    );
  };

  const handleFileUpload = (files: FileList) => {
    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("files", file);
    });

    dispatch(updateRetainerAttachmentsById({ retainerId, formData }));
  };

  useEffect(() => {
    if (retainerAttachmentUpdateSuccess) {
      setIsAttachmentsActionCompleted(true);
      setActionCompleteText("Attachments updated successfully!");
    }
  }, [retainerAttachmentUpdateSuccess]);

  useEffect(() => {
    if (retainerAttachmentDeleteSuccess) {
      setIsAttachmentsActionCompleted(true);
      setActionCompleteText("Attachment deleted successfully!");
    }
  }, [retainerAttachmentDeleteSuccess]);

  useEffect(() => {
    // runs when component unmounts
    return () => {
      dispatch(resetDeleteRetainerAttachmentState());
    };
  }, [dispatch]);

  return (
    <Dialog
      name="retainer-attachments-dialog"
      onClose={onDialogClose}
      header="Edit Attachments"
    >
      <Form id="retainer-attachments-form" onFormSubmit={onSuccess}>
        <Stack isVertical gutterSize="large" align="stretch">
          <Stack isVertical>
            <InputFile
              isPending={
                isRetainerAttachmentUpdating || isRetainerAttachmentDeleting
              }
              name="retainer_files"
              files={attachments}
              onFilesUpload={handleFileUpload}
              onFileRemove={handleFileRemove}
            />
          </Stack>
          {retainerAttachmentUpdateError && (
            <GenericFormError errorText={retainerAttachmentUpdateError} />
          )}
          {retainerAttachmentDeleteError && (
            <GenericFormError errorText={retainerAttachmentDeleteError} />
          )}
        </Stack>
      </Form>
      <Toast
        isShown={isAttachmentsActionCompleted}
        onHide={() => setIsAttachmentsActionCompleted(false)}
        icon="check"
        text={actionCompleteText}
      />
    </Dialog>
  );
};

export default EditAttachmentsDialog;
