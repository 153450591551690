import { searchKeys } from "@csis.com/tip/src/models/pageSearch/constants";
import { predefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";

export const casesKeys = {
  ID: "id",
  DISPLAY_ID: "display_id",
  CREATED: "created",
  CREATED_AFTER: "created_after",
  CREATED_BEFORE: "created_before",
  BU: "business_unit",
  IP: "source_ip",
  UPLOADER: "uploader_name",
  DEVICE_NAME: "device_name",
  STATUS: "status",
  SEVERITY: "severity",
  DOMAIN: "domain",
  PLATFORM: "platform",
  // keys used in all search pages:
  ...searchKeys,
} as const;

export const filterKeys = [casesKeys.STATUS, casesKeys.SEVERITY] as const;

export const DEFAULT_RESULTS_PER_PAGE = predefinedResultsPerPage.TEN;
