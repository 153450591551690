import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  EscalationContactListResponse,
  EscalationContactPreview,
  EscalationContactUpdateBody,
  GetEscalationContactsApi10OrganizationEscalationContactGetParams,
  UpdateEscalationContactsApi10OrganizationEscalationContactPostParams,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { createAsyncDataUpdateSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncDataUpdateSlice";
import { getSelectedOrgId } from "../../../Security/selectors";
import {
  fetchEscalationContactsApi,
  postEscalationContactsApi,
} from "./api/api";

const escalationContactsSlice = createAsyncArrayFetchSlice<
  EscalationContactPreview,
  undefined
>("escalationContacts");

export const escalationContactsReducer = escalationContactsSlice.reducer;

export const {
  fetchData: fetchEscalationContacts,
  setIsPending: setIsEscalationContactsFetchPending,
  setFetchError: setEscalationContactsFetchError,
  setFetchSuccess: setFetchEscalationContactsSuccess,
} = escalationContactsSlice.actions;

const escalationContactsUpdateSlice = createAsyncDataUpdateSlice<
  EscalationContactUpdateBody[]
>("escalationContactsUpdate");

export const escalationContactsUpdateReducer =
  escalationContactsUpdateSlice.reducer;

export const {
  updateData: updateEscalationContacts,
  setIsUpdatePending,
  setIsUpdateError,
  setIsUpdateSuccess,
  resetState,
} = escalationContactsUpdateSlice.actions;

// Async stuff - sagas

function* fetchEscalationContactsSaga() {
  yield put(setIsEscalationContactsFetchPending());

  const selectedOrgId: string | undefined = yield select(getSelectedOrgId);
  if (!selectedOrgId) {
    yield put(setEscalationContactsFetchError());
    return;
  }
  const params: GetEscalationContactsApi10OrganizationEscalationContactGetParams =
    {
      organization_id: selectedOrgId,
    };

  try {
    const response: AxiosResponse<EscalationContactListResponse> = yield call(
      fetchEscalationContactsApi,
      params
    );
    yield put(setFetchEscalationContactsSuccess(response.data.payload));
  } catch (e) {
    yield put(setEscalationContactsFetchError());
  }
}

function* updateEscalationContactsSaga(
  action: PayloadAction<EscalationContactUpdateBody[]>
) {
  yield put(setIsUpdatePending());
  const selectedOrgId: string | undefined = yield select(getSelectedOrgId);
  if (!selectedOrgId) {
    yield put(setIsUpdateError());
    return;
  }
  const params: UpdateEscalationContactsApi10OrganizationEscalationContactPostParams =
    {
      organization_id: selectedOrgId,
    };
  try {
    yield call(postEscalationContactsApi, params, action.payload);

    yield put(setIsUpdateSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setIsUpdateError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchEscalationContacts.toString(),
    fetchEscalationContactsSaga
  );
  yield takeLatest(
    updateEscalationContacts.toString(),
    updateEscalationContactsSaga
  );
}

export function* escalationContactsSagas() {
  yield all([actionWatcher()]);
}
