import { HighlightSubString, Icon, Tag } from "@csis.com/components";
import { checkIfTextContainsSubstring } from "@csis.com/components/src/atoms/HighlightSubString/utils/utils";
import { TableCellFormatter } from "@csis.com/components/src/atoms/Table/types";
import { isString } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import {
  PlatformIcons,
  PlatformTranslationKeys,
  isPlatform,
} from "../../models/Platform";
import {
  SeverityColors,
  SeverityTranslationKeys,
  isSeverity,
} from "../../models/Severity";
import { StatusTranslationKeys, isStatus } from "../../models/Status";
import { CasesKey } from "../types";

export function severityCell(
  t: (key: TranslationKey, args?: string[]) => string
): TableCellFormatter<CasesKey> {
  return (row, column) => {
    const severityValue = row[column];
    if (isSeverity(severityValue)) {
      return (
        <Tag size="small" color={SeverityColors[severityValue]} isUppercase>
          {t(SeverityTranslationKeys[severityValue])}
        </Tag>
      );
    }
    return severityValue;
  };
}

export function statusCell(
  t: (key: TranslationKey, args?: string[]) => string
): TableCellFormatter<CasesKey> {
  return (row, column) => {
    const statusValue = row[column];
    if (isStatus(statusValue)) {
      return t(StatusTranslationKeys[statusValue]);
    }
    return statusValue;
  };
}

export function platformCell(
  t: (key: TranslationKey, args?: string[]) => string
): TableCellFormatter<CasesKey> {
  return (row, column) => {
    const platformValue = row[column];
    if (isPlatform(platformValue)) {
      return (
        <Icon
          kind={PlatformIcons[platformValue]}
          color="secondary"
          size="big"
          tooltipText={t(PlatformTranslationKeys[platformValue])}
          isInline
        />
      );
    }
    return platformValue;
  };
}

// if there is a search string, highlight it in the id row
export function idCell(searchString?: string): TableCellFormatter<CasesKey> {
  return (row, column) => {
    const id = row[column];

    if (
      isString(id) &&
      searchString &&
      checkIfTextContainsSubstring(id, searchString)
    ) {
      return <HighlightSubString text={id} searchString={searchString} />;
    }

    return id;
  };
}

export function uploaderCell(
  searchString?: string
): TableCellFormatter<CasesKey> {
  return (row, column) => {
    const uploader = row[column];

    if (
      isString(uploader) &&
      searchString &&
      checkIfTextContainsSubstring(uploader, searchString)
    ) {
      return <HighlightSubString text={uploader} searchString={searchString} />;
    }

    return uploader;
  };
}

export function domainCell(
  searchString?: string
): TableCellFormatter<CasesKey> {
  return (row, column) => {
    const domain = row[column];

    if (
      isString(domain) &&
      searchString &&
      checkIfTextContainsSubstring(domain, searchString)
    ) {
      return <HighlightSubString text={domain} searchString={searchString} />;
    }

    return domain;
  };
}
