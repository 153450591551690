import { createSelector } from "@reduxjs/toolkit";
import { getNewColumnsBasedOnSort } from "@csis.com/tip/src/components/shared/DataTableContainer/utils";
import { RootState } from "@csis.com/tip/src/configureStore";
import { TicketsColumns } from "./Table/columns";

export const getTickets = (state: RootState) => state.ticketsReducer.tickets;

export const getIsTicketsPending = (state: RootState) =>
  state.ticketsReducer.isTicketsPending;

export const getTicketsFetchError = (state: RootState) =>
  state.ticketsReducer.ticketsFetchError;

export const getTicketsResult = createSelector(
  [getTickets, getIsTicketsPending, getTicketsFetchError],
  (tickets, isTicketsPending, ticketsFetchError) => {
    return { tickets, isTicketsPending, ticketsFetchError };
  }
);

export const getTicketsColumns = (state: RootState) =>
  state.ticketsReducer.columns;

export const getHasNextPage = (state: RootState) =>
  state.ticketsReducer.hasNextPage;

export const getCalculatedColumns = createSelector(
  [getTicketsColumns, (_: RootState, sort?: string) => sort],
  (columns, sort) => {
    return getNewColumnsBasedOnSort(columns, sort);
  }
  // we need to explicitly type it here so typescript doesnt show false warning
) as (state: RootState, sort?: string) => TicketsColumns;

export const getIsCsvExportPending = (state: RootState) =>
  state.ticketsReducer.isCsvExportPending;
