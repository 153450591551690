import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  EscalationContactUpdateBody,
  GetEscalationContactsApi10OrganizationEscalationContactGetParams,
  UpdateEscalationContactsApi10OrganizationEscalationContactPostParams,
} from "@csis.com/tip/src/api/openapi/data-contracts";

export async function fetchEscalationContactsApi(
  params: GetEscalationContactsApi10OrganizationEscalationContactGetParams
) {
  return axiosCsisApi.getEscalationContactsApi10OrganizationEscalationContactGet(
    params
  );
}

export async function postEscalationContactsApi(
  params: UpdateEscalationContactsApi10OrganizationEscalationContactPostParams,
  data: EscalationContactUpdateBody[]
) {
  return axiosCsisApi.updateEscalationContactsApi10OrganizationEscalationContactPost(
    params,
    data
  );
}
