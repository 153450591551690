import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { QueryParams } from "../types";

export async function fetchEmergencyCesesApi(
  queryParams: Partial<QueryParams>
) {
  return axiosCsisApi.getEmergencyResponseCasesApi10AdminEmergencyResponseCaseGet(
    queryParams,
    STRINGIFY_QUERY_PARAMS
  );
}

export async function fetchAdminUsersApi() {
  return axiosCsisApi.getAdminUsersApi10AdminUserAdminGet();
}
