import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  addGroupByParamBasedOnRange,
  mapAlertsStartAndEndDateToCorrectType,
} from "@csis.com/tip/src/pages/Alerts/AlertsSearch/api/utils";
import { QueryParams } from "../../types";

export async function fetchAlerterStatisticsApi(
  queryParams: Partial<QueryParams>
) {
  const params = mapAlertsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getStatisticsAlerterApi10AlertStatisticsAlerterGet(
    params
  );
}

export async function fetchAlerterStatisticsHistogramApi(
  queryParams: Partial<QueryParams>
) {
  const params = addGroupByParamBasedOnRange(
    mapAlertsStartAndEndDateToCorrectType(queryParams)
  );

  return axiosCsisApi.getStatisticsAlerterHistogramApi10AlertStatisticsAlerterHistogramGet(
    params
  );
}
