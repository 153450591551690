import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Skeleton, Stack, Tag } from "@csis.com/components";
import { DateSpanWrapper as DateSpan } from "@csis.com/tip/src/components/wrappers/DateSpanWrapper";
import { insightsKeys } from "@csis.com/tip/src/pages/ThreatInsights/Search/constants";
import { getThreatInsightsResult } from "@csis.com/tip/src/pages/ThreatInsights/Search/selectors";
import { fetchInsights } from "@csis.com/tip/src/pages/ThreatInsights/Search/slice";
import { InsightsRow } from "@csis.com/tip/src/pages/ThreatInsights/Search/types";
import { CategoryEntry } from "@csis.com/tip/src/pages/ThreatInsights/models/Category/Category";
import { getArticleCategories } from "@csis.com/tip/src/pages/ThreatInsights/models/Category/selectors";
import { fetchCategories } from "@csis.com/tip/src/pages/ThreatInsights/models/Category/slice";
import { getCategoryDisplayName } from "@csis.com/tip/src/pages/ThreatInsights/models/Category/utils";
import {
  SeverityColors,
  SeverityTranslationKeys,
} from "@csis.com/tip/src/pages/ThreatInsights/models/Severity";
import urlTemplates from "@csis.com/tip/src/routes/urlTemplates";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";

function Articles({
  insights,
  categories,
  isPending,
}: {
  insights: InsightsRow[];
  categories: CategoryEntry[];
  isPending: boolean;
}) {
  const { t } = useTranslations();

  return (
    <Stack gutterSize="normal" isVertical align="stretch">
      <div className="f_semibold f_secondary">LATEST THREAT INSIGHTS</div>
      {isPending && <Skeleton type="multiline" />}
      {insights &&
        insights.length > 0 &&
        insights.map((insight) => (
          <div className="landing-page__articles-overview__article">
            <Stack key={insight.id} isExpanded>
              <Stack justify="space-between" isExpanded>
                <Stack gutterSize="small" isVertical isExpanded>
                  <Link
                    withStyling={false}
                    href={urlTemplates._products_threatinsights_article_id(
                      insight[insightsKeys.ID]
                    )}
                    dataTestId={
                      "latest-article-link-" + insight[insightsKeys.ID]
                    }
                  >
                    <div className="f_semibold f_underline-onhover landing-page__articles-overview__title">
                      {insight[insightsKeys.TITLE]}
                    </div>
                  </Link>
                  <Stack gutterSize="small" isExpanded isWrap={true}>
                    <Stack isWrap={true}>
                      <Tag
                        key="category-tag"
                        color="grey"
                        size="small"
                        isUppercase
                      >
                        {getCategoryDisplayName(
                          insight[insightsKeys.CATEGORY],
                          categories
                        )}
                      </Tag>
                      <Tag
                        key="severity-tag"
                        size="small"
                        color={SeverityColors[insight[insightsKeys.SEVERITY]]}
                        isUppercase
                      >
                        {t(
                          SeverityTranslationKeys[
                            insight[insightsKeys.SEVERITY]
                          ]
                        )}
                      </Tag>
                    </Stack>
                    <div className="f_small">
                      <DateSpan date={insight[insightsKeys.CREATED]} />
                    </div>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </div>
        ))}
    </Stack>
  );
}

function ArticlesCompact({
  insights,
  categories,
  isPending,
}: {
  insights: InsightsRow[];
  categories: CategoryEntry[];
  isPending: boolean;
}) {
  return (
    <>
      <div
        className="landing-page__articles-overview"
        data-test-id="articles-overview-card"
      >
        <Articles
          insights={insights}
          categories={categories}
          isPending={isPending}
        />
      </div>
    </>
  );
}

export default function ArticlesOverviewCard({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) {
  const dispatch = useDispatch();
  const categories = useSelector(getArticleCategories);
  const { insights, isPending } = useSelector(getThreatInsightsResult);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    const queryParams = {
      [insightsKeys.PER_PAGE]: 2,
    };
    dispatch(fetchInsights(queryParams));
  }, [dispatch, startDate, endDate]);

  return (
    <ArticlesCompact
      insights={insights && insights.length > 0 ? insights : []}
      categories={categories}
      isPending={isPending}
    />
  );
}
