import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Carousel, PieChart, Table } from "@csis.com/components";
import { OnRowClick } from "@csis.com/components/src/atoms/Table/types";
import { casesKeys } from "@csis.com/tip/src/pages/Products/RemoteForensics/RemoteForensicsSearch/constants";
import { getCasesResult } from "@csis.com/tip/src/pages/Products/RemoteForensics/RemoteForensicsSearch/selectors";
import { fetchCases } from "@csis.com/tip/src/pages/Products/RemoteForensics/RemoteForensicsSearch/slice";
import { CasesRow } from "@csis.com/tip/src/pages/Products/RemoteForensics/RemoteForensicsSearch/types";
import urlTemplates from "@csis.com/tip/src/routes/urlTemplates";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { getNewLocationUrl } from "@csis.com/tip/src/utils/updateLocationWithParams";
import { ForensicsColumns } from "../../RemoteForensics/RemoteForensicsSearch/CasesTable/columns";
import {
  platformCell,
  severityCell,
  statusCell,
} from "../../RemoteForensics/RemoteForensicsSearch/CasesTable/tableCellFormatters";
import { getStatusDistributionResult } from "../../RemoteForensics/RemoteForensicsStatistics/Status/selectors";
import { fetchStatusDistribution } from "../../RemoteForensics/RemoteForensicsStatistics/Status/slice";
import {
  getPieLabelGenerator,
  transformTotalDataToPiechartData,
} from "../../RemoteForensics/RemoteForensicsStatistics/transformers";
import {
  StatusTranslationKeys,
  StatusValues,
} from "../../RemoteForensics/models/Status";
import ProductCard from "./ProductCard/ProductCard";
import { ELEMENTS_PER_TABLE, REQUEST_DELAY_MS } from "./constants";

export const columns: ForensicsColumns = [
  { key: casesKeys.DISPLAY_ID, name: "id" },
  { key: casesKeys.STATUS, name: "status" },
  { key: casesKeys.SEVERITY, name: "severity" },
  { key: casesKeys.PLATFORM, name: "platform" },
];

const slidesInfo = [
  {
    subtitle: "Status Distributions",
    viewAllUrl: urls.products_remoteforensics_statistics,
  },
  {
    subtitle: "Latest Cases",
    viewAllUrl: urls.products_remoteforensics_search,
  },
];

export default function ForensicsProductCard({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [requestDelay, setRequestDelay] = useState<number>(REQUEST_DELAY_MS);
  const { t } = useTranslations();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  function handleCarouselChange(newSlideIndex: number) {
    setCurrentSlideIndex(newSlideIndex);
  }

  useEffect(() => {
    const queryParams = {
      [casesKeys.START_DATE]: startDate,
      [casesKeys.END_DATE]: endDate,
    };

    dispatch(fetchStatusDistribution(queryParams));

    const timer = setTimeout(() => {
      dispatch(fetchCases(queryParams));
      setRequestDelay(0);
    }, requestDelay);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endDate, startDate]);

  const { cases, isCasesPending, casesFetchError } =
    useSelector(getCasesResult);

  const handleRowClick: OnRowClick<CasesRow> = useCallback(
    (row) => {
      const caseId = row[casesKeys.ID];
      history.push(
        urlTemplates._products_remoteforensics_case_id_analysisoverview(caseId)
      );
    },
    [history]
  );

  const {
    statusDistributions,
    isStatusDistributionPending,
    statusDistributionError,
  } = useSelector(getStatusDistributionResult);

  const statusDistributionsAsPieChart = transformTotalDataToPiechartData(
    statusDistributions,
    getPieLabelGenerator(t, StatusTranslationKeys)
  );

  const totalNewReports =
    statusDistributions?.find((element) => element.name === StatusValues.NEW)
      ?.count || 0;

  const newReportsUrl = getNewLocationUrl(
    urls.products_remoteforensics_search,
    {
      [casesKeys.STATUS]: [StatusValues.NEW],
      [casesKeys.START_DATE]: startDate,
      [casesKeys.END_DATE]: endDate,
    }
  );

  const formatters = useMemo(
    () => ({
      [casesKeys.SEVERITY]: severityCell(t),
      [casesKeys.STATUS]: statusCell(t),
      [casesKeys.PLATFORM]: platformCell(t),
    }),
    [t]
  );

  return (
    <ProductCard dataTestId="forensics-product-card">
      <ProductCard.Header
        title={t("remote_forensics")}
        subTitle={slidesInfo[currentSlideIndex]?.subtitle}
        viewAllUrl={slidesInfo[currentSlideIndex]?.viewAllUrl}
      />

      <ProductCard.Carousel>
        <Carousel
          onChange={handleCarouselChange}
          name="forensics-carousel"
          hasAutoplay
          items={[
            <PieChart
              dataKey="amount"
              isLoading={isStatusDistributionPending}
              hasLegend={false}
              data={statusDistributionsAsPieChart}
              error={statusDistributionError}
            />,
            <Table
              name={"remforensics-productcard-table"}
              columns={columns}
              rows={cases?.slice(0, ELEMENTS_PER_TABLE)}
              isLoading={isCasesPending}
              error={casesFetchError}
              ghostRows={ELEMENTS_PER_TABLE}
              isFullWidth
              hasUnlimitedCellWidth
              size="small"
              columnNameTransform={t}
              onRowClick={handleRowClick}
              formatters={formatters}
            />,
          ]}
        />
      </ProductCard.Carousel>
      <ProductCard.Footer
        text={`${totalNewReports} ${t("new_reports")}`}
        url={newReportsUrl}
      />
    </ProductCard>
  );
}
