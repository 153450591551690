import * as qs from "query-string";
import {
  BusinessUnitWithRetainerPreview,
  RetainerAttachmentPreview,
  RetainerTypePreview,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import {
  FilterOption,
  extractElementsFromQueryParams,
} from "@csis.com/tip/src/components/shared/SearchComboInput/utils";
import {
  genericParse,
  isArrayOf,
  isNumber,
  isNumberOrString,
  isString,
} from "@csis.com/tip/src/models/helpers";
import { isPredefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";
import { getRetainerDisplayName } from "../models/RetainerType/RetainerType";
import { RetainerType, retainersKeys } from "./constants";
import { FilterKey, QueryParams, RetainerRow } from "./types";

export function getRetainerType(flags: Array<string> | undefined) {
  if (!flags) {
    return "-";
  }
  const isIT = flags.includes(RetainerType.IT);
  const isOT = flags.includes(RetainerType.OT);
  switch (true) {
    case isIT && isOT:
      return "IT & OT Retainer";
    case isIT:
      return "IT Retainer";
    case isOT:
      return "OT Retainer";
    default:
      return "-";
  }
}

export const retainerPreviewToTableRow = (
  retainer: BusinessUnitWithRetainerPreview
): RetainerRow => ({
  [retainersKeys.ID]: retainer[retainersKeys.ID],
  [retainersKeys.RETAINER_ID]: retainer[retainersKeys.RETAINER]?.external_id,
  [retainersKeys.NAME]: retainer[retainersKeys.NAME],
  [retainersKeys.FULL_NAME]: retainer[retainersKeys.FULL_NAME],
  [retainersKeys.TYPE]: retainer[retainersKeys.RETAINER]?.flags || [],
  [retainersKeys.UPDATED_AT]:
    retainer[retainersKeys.RETAINER]?.[retainersKeys.UPDATED_AT],
  [retainersKeys.PRIMARY_CONTACT_NAME]:
    retainer[retainersKeys.RETAINER]?.[retainersKeys.PRIMARY_CONTACT_NAME],
  [retainersKeys.PRIMARY_PHONE_NUMBER]:
    retainer[retainersKeys.RETAINER]?.[retainersKeys.PRIMARY_PHONE_NUMBER],
  [retainersKeys.ATTACHMENTS]: retainer[retainersKeys.RETAINER]?.[
    retainersKeys.ATTACHMENTS
  ].map((attachment: RetainerAttachmentPreview) => attachment.filename),
});

export const calculateQueryParams = (
  searchParams: qs.ParsedQuery<string | number>
): QueryParams => {
  return {
    [retainersKeys.BUSINESS_UNIT]: genericParse(
      searchParams?.[retainersKeys.BUSINESS_UNIT],
      isNumberOrString
    )?.toString(),
    [retainersKeys.TYPE]:
      genericParse(searchParams?.[retainersKeys.TYPE], isArrayOf(isString)) ||
      [],
    [retainersKeys.OFFSET]: genericParse(
      searchParams?.[retainersKeys.OFFSET],
      isNumber
    ),
    [retainersKeys.PER_PAGE]: genericParse(
      searchParams?.[retainersKeys.PER_PAGE],
      isPredefinedResultsPerPage
    ),

    [retainersKeys.SORT]: genericParse(
      searchParams?.[retainersKeys.SORT],
      isString
    ),
  };
};

export function getFilterElementsFromQueryParams(
  queryParams: QueryParams,
  retainerTypes: RetainerTypePreview[]
) {
  const filterOptions: FilterOption<FilterKey>[] = [
    {
      key: retainersKeys.TYPE,
      keyTranslationKey: "type",
      valueTransformFunction: (retainerTypeName) =>
        getRetainerDisplayName(retainerTypeName, retainerTypes),
    },
  ];

  return extractElementsFromQueryParams({ queryParams, filterOptions });
}
