import { createSelector } from "@reduxjs/toolkit";
import { getNewColumnsBasedOnSort } from "@csis.com/tip/src/components/shared/DataTableContainer/utils";
import { RootState } from "@csis.com/tip/src/configureStore";
import { AlertsColumns } from "./Table/columns";

export const getAlerts = (state: RootState) => state.alertsReducer.alerts;

export const getIsAlertsPending = (state: RootState) =>
  state.alertsReducer.isAlertsPending;

export const getAlertsFetchError = (state: RootState) =>
  state.alertsReducer.alertsFetchError;

export const getAlertsResult = createSelector(
  [getAlerts, getIsAlertsPending, getAlertsFetchError],
  (alerts, isAlertsPending, alertsFetchError) => {
    return { alerts, isAlertsPending, alertsFetchError };
  }
);

export const getAlertsColumns = (state: RootState) =>
  state.alertsReducer.columns;

export const getHasNextPage = (state: RootState) =>
  state.alertsReducer.hasNextPage;

export const getCalculatedColumns = createSelector(
  [getAlertsColumns, (_: RootState, sort?: string) => sort],
  (columns, sort) => {
    return getNewColumnsBasedOnSort(columns, sort);
  }
  // we need to explicitly type it here so typescript doesnt show false warning
) as (state: RootState, sort?: string) => AlertsColumns;

export const getIsCsvExportPending = (state: RootState) =>
  state.alertsReducer.isCsvExportPending;
