import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  AdminUserPreview,
  EmergencyResponseCasePreview,
  EmergencyResponseCasePreviewPageDetailResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { EmergencyCasesColumns, columns } from "./Table/columns";
import { fetchAdminUsersApi, fetchEmergencyCesesApi } from "./api/api";
import { AdminUsersResponse, QueryParams } from "./types";

interface StateSlice {
  emergencyCases: EmergencyResponseCasePreview[] | null;
  hasNextPage: boolean;
  isEmergencyCasesPending: boolean;
  emergencyCasesFetchError: string | null;
  columns: EmergencyCasesColumns;
  adminUsers: AdminUserPreview[];
  isAdminUsersPending: boolean;
  fetchAdminUsersError: string | null;
}

const initialState: StateSlice = {
  emergencyCases: null,
  hasNextPage: false,
  isEmergencyCasesPending: false,
  emergencyCasesFetchError: null,
  columns: columns,
  adminUsers: [],
  isAdminUsersPending: false,
  fetchAdminUsersError: null,
};

const emergencyCasesSlice = createSlice({
  name: "emergencyCases",
  initialState: initialState,
  reducers: {
    fetchEmergencyCases(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setEmergencyCasesPending(state) {
      state.isEmergencyCasesPending = true;
      state.emergencyCasesFetchError = null;
      state.emergencyCases = [];
    },
    setFetchEmergencyCasesError(state, action: PayloadAction<string>) {
      state.isEmergencyCasesPending = false;
      state.emergencyCasesFetchError = action.payload;
      state.emergencyCases = [];
    },
    fetchEmergencyCasesSuccess(
      state,
      action: PayloadAction<EmergencyResponseCasePreview[]>
    ) {
      state.isEmergencyCasesPending = false;
      state.emergencyCasesFetchError = null;
      state.emergencyCases = action.payload;
    },
    reorderColumns(state, action: PayloadAction<EmergencyCasesColumns>) {
      state.columns = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },
    fetchAdminUsers(_state, _action: PayloadAction<void>) {
      //empty handled by saga
    },
    fetchAdminUsersPending(state) {
      state.isAdminUsersPending = true;
      state.fetchAdminUsersError = null;
      state.adminUsers = [];
    },
    fetchAdminUsersSuccess(state, action: PayloadAction<any[]>) {
      state.isAdminUsersPending = false;
      state.fetchAdminUsersError = null;
      state.adminUsers = action.payload;
    },
    fetchAdminUsersError(state, action: PayloadAction<string>) {
      state.isAdminUsersPending = false;
      state.fetchAdminUsersError = action.payload;
      state.adminUsers = [];
    },
  },
});

export default emergencyCasesSlice.reducer;

export const {
  fetchEmergencyCases,
  setEmergencyCasesPending,
  setFetchEmergencyCasesError,
  fetchEmergencyCasesSuccess,
  reorderColumns,
  setHasNextPage,
  fetchAdminUsers,
  fetchAdminUsersPending,
  fetchAdminUsersSuccess,
  fetchAdminUsersError,
} = emergencyCasesSlice.actions;

function* fetchAdminUsersSaga() {
  yield put(fetchAdminUsersPending());
  try {
    const response: AxiosResponse<AdminUsersResponse> = yield call(
      fetchAdminUsersApi
    );
    yield put(fetchAdminUsersSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(fetchAdminUsersError(errorMessage));
  }
}

function* fetchEmergencyCasesSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setEmergencyCasesPending());
  try {
    const response: AxiosResponse<EmergencyResponseCasePreviewPageDetailResponse> =
      yield call(fetchEmergencyCesesApi, action.payload);

    yield put(fetchEmergencyCasesSuccess(response.data.payload.page));
    yield put(setHasNextPage(response.data.payload.has_next));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchEmergencyCasesError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchEmergencyCases.toString(), fetchEmergencyCasesSaga);
  yield takeLatest(fetchAdminUsers.toString(), fetchAdminUsersSaga);
}

export function* emergencyCasesSaga() {
  yield all([actionWatcher()]);
}
