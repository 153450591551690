import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  addGroupByParamBasedOnRange,
  mapForensicsStartAndEndDateToCorrectType,
} from "@csis.com/tip/src/pages/Products/RemoteForensics/RemoteForensicsSearch/api/utils";
import { QueryParams } from "../../types";

export async function fetchStatusStatisticsApi(
  queryParams: Partial<QueryParams>
) {
  const params = mapForensicsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getStatisticsStatusApi10RemoteForensicsStatisticsStatusGet(
    params
  );
}

export async function fetchStatusStatisticsHistogramApi(
  queryParams: Partial<QueryParams>
) {
  const params = addGroupByParamBasedOnRange(
    mapForensicsStartAndEndDateToCorrectType(queryParams)
  );

  return axiosCsisApi.getStatisticsStatusHistogramApi10RemoteForensicsStatisticsStatusHistogramGet(
    params
  );
}
