import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { PortalsFrontendApiAlertsDeprecatedAppAlertDetailResponse as AlertDetailResponse } from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import { Status } from "../models/Status";
import {
  fetchAlertApi,
  fetchAlertAttachmentApi,
  patchAlertStatusApi,
} from "./api/api";
import { Alert } from "./types";

interface StateSlice {
  alert: Alert | null;
  isPending: boolean;
  fetchError: string | null;
}
const initialState: StateSlice = {
  alert: null,
  isPending: false,
  fetchError: null,
};

const alertSlice = createSlice({
  name: "alert",
  initialState: initialState,
  reducers: {
    fetchAlertById(_, __: PayloadAction<string>) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchSuccess(state, action: PayloadAction<Alert>) {
      state.isPending = false;
      state.alert = action.payload;
      state.fetchError = null;
    },
    updateAlertStatus(
      _state,
      _action: PayloadAction<{ alertId: string; status: Status }>
    ) {
      //empty handled by saga
    },
    setAlertStatusUpdateError(_state) {},

    downloadAlertAttachment(
      _state,
      _action: PayloadAction<{
        alertId: string;
        attachmentId: string;
        filename: string;
      }>
    ) {
      //empty handled by saga
    },
    setDownloadAlertAttachmentError(_state) {},
  },
});

export default alertSlice.reducer;

export const {
  fetchAlertById,
  setPending,
  setFetchError,
  fetchSuccess,

  updateAlertStatus,
  setAlertStatusUpdateError,

  downloadAlertAttachment,
  setDownloadAlertAttachmentError,
} = alertSlice.actions;

// Async stuff - sagas

function* fetchAlertByIdSaga(action: PayloadAction<string>) {
  yield put(setPending());
  try {
    const response: AxiosResponse<AlertDetailResponse> = yield call(
      fetchAlertApi,
      action.payload
    );

    yield put(fetchSuccess(response.data.payload as Alert));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* updateAlertStatusSaga(
  action: PayloadAction<{ alertId: string; status: Status }>
) {
  // we go the "optimistic way" of updating the fields here
  // we just update the ui immediately (no loaders) and assume success, and we dont say anything
  // we ONLY notify if there was an error in the server update
  try {
    yield call(patchAlertStatusApi, action.payload);
    yield put(fetchAlertById(action.payload.alertId));
  } catch (e) {
    yield put(setAlertStatusUpdateError());
  }
}

function* downloadAlertAttachmentSaga(
  action: PayloadAction<{
    alertId: string;
    attachmentId: string;
    filename: string;
  }>
) {
  try {
    const response: AxiosResponse<Blob> = yield call(
      fetchAlertAttachmentApi,
      action.payload.alertId,
      action.payload.attachmentId
    );

    const blob = response.data;
    downloadBlobForUser(blob, action.payload.filename);
  } catch (e) {
    yield put(setDownloadAlertAttachmentError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchAlertById.toString(), fetchAlertByIdSaga);
  yield takeLatest(updateAlertStatus.toString(), updateAlertStatusSaga);
  yield takeLatest(
    downloadAlertAttachment.toString(),
    downloadAlertAttachmentSaga
  );
}

export function* alertSagas() {
  yield all([actionWatcher()]);
}
