import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { fetchRemoteForensicsDownloadsApi } from "./api/api";
import { RemoteForensicsDownloadResponse } from "./api/types";
import { RemoteForensicsDownload } from "./types";

interface StateSlice {
  downloads: RemoteForensicsDownload[] | null;
  isDownloadsPending: boolean;
  fetchDownloadsError: string | null;
}
const initialState: StateSlice = {
  downloads: null,
  isDownloadsPending: false,
  fetchDownloadsError: null,
};

const downloadsSlice = createSlice({
  name: "remoteForensicsDownloads",
  initialState: initialState,
  reducers: {
    fetchDownloads(_state) {
      //empty handled by saga
    },
    setDownloadsPending(state) {
      state.isDownloadsPending = true;
      state.fetchDownloadsError = null;
    },
    setFetchDownloadsError(state, action: PayloadAction<string>) {
      state.isDownloadsPending = false;
      state.fetchDownloadsError = action.payload;
      state.downloads = null;
    },
    fetchDownloadsSuccess(
      state,
      action: PayloadAction<RemoteForensicsDownload[]>
    ) {
      state.isDownloadsPending = false;
      state.downloads = action.payload;
      state.fetchDownloadsError = null;
    },
  },
});

export default downloadsSlice.reducer;

export const {
  fetchDownloads,
  setDownloadsPending,
  setFetchDownloadsError,
  fetchDownloadsSuccess,
} = downloadsSlice.actions;

// Async stuff - sagas
function* fetchDownloadsSaga() {
  yield put(setDownloadsPending());
  try {
    const response: AxiosResponse<RemoteForensicsDownloadResponse> = yield call(
      fetchRemoteForensicsDownloadsApi
    );
    yield put(fetchDownloadsSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchDownloadsError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchDownloads.toString(), fetchDownloadsSaga);
}

export function* forensicsDownloadsSagas() {
  yield all([actionWatcher()]);
}
