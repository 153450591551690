import * as tableTypes from "@csis.com/components/src/atoms/Table/types";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { auditLogKeys } from "../constants";
import { AuditLogKey } from "../types";

export type AuditLogColumns = tableTypes.ColumnInterface<
  AuditLogKey,
  TranslationKey
>[];

export const columns: AuditLogColumns = [
  { key: auditLogKeys.TIMESTAMP, name: "timestamp" },
  { key: auditLogKeys.PLATFORM, name: "platform" },
  { key: auditLogKeys.ACTOR_IP, name: "actor_ip" },
  { key: auditLogKeys.ACTOR, name: "actor" },
  { key: auditLogKeys.ACTIVITY, name: "activity" },
  { key: auditLogKeys.EVENT, name: "event" },
];
