import classNames from "classnames";
import React, { useRef } from "react";

export interface DropAreaInterface {
  isInline?: boolean;
  onDrop: (ev: React.DragEvent<HTMLDivElement>) => void;
  // for now the dragOver returns the Y position of the mouse relative to the container
  // and the height of the container, can be extended if needed
  onDragOver: (value: { posY: number; containerHeight: number }) => void;
  isDragging?: boolean;
}

const DropArea: React.FC<DropAreaInterface> = ({
  children,
  isInline,
  onDrop,
  onDragOver,
  isDragging,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    if (ref && ref.current) {
      const posY = ev.clientY - ref.current.getBoundingClientRect().top;
      const containerHeight = ref.current?.getBoundingClientRect().height;
      onDragOver({ posY, containerHeight });
    }
  };

  const handleDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    onDrop(ev);
  };

  const classes = classNames("droparea", {
    "droparea--inline": isInline,
    "droparea--active-dragging": isDragging,
  });

  return (
    <div
      ref={ref}
      className={classes}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {children}
    </div>
  );
};

export default DropArea;
