import React, { useState } from "react";
import { IconButton } from "../../..";
import { Stack } from "../../layout";

export type AmountOfStars = 0 | 1 | 2 | 3 | 4 | 5;

const allRatings: AmountOfStars[] = [1, 2, 3, 4, 5];

// Default for now 5 stars rating scale
// can be easily extended to 10
export interface RatingInterface {
  rating?: AmountOfStars;
  maxRating?: 3 | 4 | 5;
  onClick: (rating: AmountOfStars) => void;
  color?: "csis" | "grey" | "yellow";
  dataTestId?: string;
}

const Rating = ({
  rating = 0,
  color,
  maxRating,
  onClick,
  dataTestId,
}: RatingInterface) => {
  const [hovered, setHovered] = useState<AmountOfStars>(0);

  return (
    <div
      className="rating"
      onMouseLeave={() => setHovered(0)}
      data-test-id={dataTestId}
    >
      <Stack gutterSize="none">
        {allRatings.map((value) => {
          if (maxRating && value > maxRating) {
            return null;
          }
          return (
            <IconButton
              key={value}
              size="large"
              type="text"
              spacing="tiny"
              tooltipText={value.toString() + " stars Rating"}
              color={color}
              onHover={() => setHovered(value)}
              onButtonClick={() => onClick(value)}
              dataTestId={
                dataTestId
                  ? dataTestId + "_rating-star-btn-value-" + value
                  : "rating-star-btn-value-" + value
              }
              icon={
                (rating >= value && hovered === 0) || hovered >= value
                  ? "star"
                  : "star_outline"
              }
            />
          );
        })}
      </Stack>
    </div>
  );
};

export default React.memo(Rating);
