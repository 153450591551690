import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  addGroupByParamBasedOnRange,
  mapForensicsStartAndEndDateToCorrectType,
} from "@csis.com/tip/src/pages/Products/RemoteForensics/RemoteForensicsSearch/api/utils";
import { QueryParams } from "../../types";

export async function fetchPlatformStatisticsApi(
  queryParams: Partial<QueryParams>
) {
  const params = mapForensicsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getStatisticsPlatformApi10RemoteForensicsStatisticsPlatformGet(
    params
  );
}

export async function fetchPlatformStatisticsHistogramApi(
  queryParams: Partial<QueryParams>
) {
  const params = addGroupByParamBasedOnRange(
    mapForensicsStartAndEndDateToCorrectType(queryParams)
  );

  return axiosCsisApi.getStatisticsPlatformHistogramApi10RemoteForensicsStatisticsPlatformHistogramGet(
    params
  );
}
