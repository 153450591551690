import axios from "axios";
import * as qs from "query-string";
import { API_ENDPOINTS } from "@csis.com/tip/src/api/apiEndpoints";
import { getEndpointWithParams } from "@csis.com/tip/src/api/utils";
import { EntitiesResponse } from "./types";

export async function fetchEntityTypesApi(
  onlyExternallyVisible: boolean = true
) {
  const paramsAsString = qs.stringify({
    only_externally_visible: onlyExternallyVisible,
  });

  const url = getEndpointWithParams(API_ENDPOINTS.entitytype, paramsAsString);

  return await axios.get<EntitiesResponse>(url);
}
