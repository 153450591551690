import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";
import { getNewColumnsBasedOnSort } from "../../components/shared/DataTableContainer/utils";
import { ReportsColumns } from "./ReportsList/columns";

export const getReports = (state: RootState) => state.reportsReducer.reports;

export const getIsReportsPending = (state: RootState) =>
  state.reportsReducer.isPending;

export const getReportsFetchError = (state: RootState) =>
  state.reportsReducer.fetchError;

export const getReportsResult = createSelector(
  [getReports, getIsReportsPending, getReportsFetchError],
  (reports, isPending, fetchError) => {
    return { reports, isPending, fetchError };
  }
);

export const getHasNextPage = (state: RootState) =>
  state.reportsReducer.hasNextPage;

export const getDownloadReportFetchError = (state: RootState) =>
  state.reportsReducer.reportDownloadError;

export const getDownloadReportPendingStatus = (state: RootState) =>
  state.reportsReducer.reportDownloadStatus;

export const getGenerateReportSuccess = (state: RootState) =>
  state.reportsReducer.generateReportSuccess;

export const getIsGenerateReportPending = (state: RootState) =>
  state.reportsReducer.isGenerateReportPending;

export const getGenerateReportError = (state: RootState) =>
  state.reportsReducer.generateReportError;

export const getGenerateReportsResult = createSelector(
  [
    getGenerateReportSuccess,
    getIsGenerateReportPending,
    getGenerateReportError,
  ],
  (generateReportSuccess, isGenerateReportPending, generateReportError) => {
    return {
      generateReportSuccess,
      isGenerateReportPending,
      generateReportError,
    };
  }
);

export const getReportsColumns = (state: RootState) =>
  state.reportsReducer.columns;

export const getCalculatedColumns = createSelector(
  [getReportsColumns, (_: RootState, sort?: string) => sort],
  (columns, sort) => {
    return getNewColumnsBasedOnSort(columns, sort);
  }
  // we need to explicitly type it here so typescript doesnt show false warning
) as (state: RootState, sort?: string) => ReportsColumns;
