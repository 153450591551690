import { Link } from "react-router-dom";
import { Stack } from "@csis.com/components";
import urls from "@csis.com/tip/src/routes/urls";
import Pacman from "./Pacman/Pacman";

const NoMatchUrl = () => {
  return (
    <div className="nomatchurl">
      <Stack isVertical align="center" gutterSize="huge">
        <Pacman />
        <Stack isVertical align="center">
          <div className="f_semibold">404. That’s an error.</div>
          <div>The requested URL was not found.</div>
          <Link to={urls.products}>
            <div className={"f_csis f_semibold"}>Go back home</div>
          </Link>
        </Stack>
      </Stack>
    </div>
  );
};

export default NoMatchUrl;
