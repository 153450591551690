import { FC } from "react";
import { CodeBlock, Stack } from "@csis.com/components";
import urls from "@csis.com/tip/src/routes/urls";
import { DocumentationCodeExample } from "../../DocumentationCodeExample/DocumentationCodeExample";
import {
  EndpointSection,
  EndpointSectionContent,
} from "../../EnpointSection/EndpointSection";
import { ecrimeApiUrl } from "../../constants";
import { Subject } from "../Subject";

export const TokenAuthenticationSubject: FC = () => {
  return (
    <Subject title="Gaining Access" id="gaining-access">
      <EndpointSection>
        <h4 id="token-authentication" className="f_semibold f_large">
          Token Authentication
        </h4>
        <EndpointSectionContent>
          <p>
            Access to the API is only possible from the IP ranges that are
            marked as trusted for your business unit. For each HTTP request to
            the Threat Intelligence Portal API you must provide your active
            access token in the HTTP header "Authorization":
          </p>
          <Stack>
            <CodeBlock
              color="grey"
              text="Authorization: Token <place-your-token-here>"
              isCopyable
            />
          </Stack>
          <p>
            The token can be generated in the{" "}
            <a className="f_csis" href={urls.settings_account_general}>
              Account Settings
            </a>{" "}
            under API Access Token{" "}
          </p>

          <TokenAuthenticationExample />
        </EndpointSectionContent>
      </EndpointSection>
    </Subject>
  );
};

const TokenAuthenticationExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h4 className="f_normal f_semibold">curl</h4>
      <CodeBlock
        color="grey"
        text={`curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}alerts/subscribed-alerters`}
        isCopyable
      />
      <h4 className="f_normal f_semibold">Python</h4>
      <CodeBlock
        color="grey"
        text={`url = '${ecrimeApiUrl}alerts/subscribed-alerters'
headers = {
'Accept': 'application/json',
'Authorization': 'Token <place-your-token-here>',
}
r = requests.get(url, headers=headers)
r.raise_for_status()
r.json() `}
        isCopyable
      />
    </DocumentationCodeExample>
  );
};
