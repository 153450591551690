import axios from "axios";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { API_ENDPOINTS } from "@csis.com/tip/src/api/apiEndpoints";
import { RemoteForensicsSubPageKey } from "../SubPageContainer/types";
import { remoteForensicsSubPageKeys } from "../constants";
import { CaseResponse } from "./types";

export async function fetchCaseApi(
  id: string,
  subpage: RemoteForensicsSubPageKey
) {
  // only "analysis" page doesnt need a "sufix" in the endpoint request
  if (subpage === remoteForensicsSubPageKeys.ANALYSIS_OVERVIEW) {
    return await axios.get<CaseResponse>(
      API_ENDPOINTS.remoteforensics_id_subpage(id)
    );
  } else {
    return await axios.get<CaseResponse>(
      API_ENDPOINTS.remoteforensics_id_subpage(id, subpage)
    );
  }
}

export async function fetchCaseSummaryApi(id: string) {
  return axiosCsisApi.getAnalysisCountersApi10RemoteForensicsAnalysisExternalIdSummaryGet(
    id
  );
}
